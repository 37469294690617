<template>
    <div :style="boxStyle">
        <div class="start-box" v-for="(key, index) in Object.keys(attribute)" :key="index" style="margin-top: 8px;width: 100%;">
            <!-- 标签 -->
            <div v-if="!noShowKeys.includes(key)"
                style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explian(key) }}
            </div>
            <!-- 内容 -->
            <div style="width: 100%;">
                <InputEditer :pKey="pKey + '.' + key" :updateAttributeData="updateAttributeData"
                    v-if="!noShowKeys.includes(key)" :data="{ value: attribute[key] }" :boxStyle="{}"></InputEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import InputEditer from './InputEditer';

export default {
    name: 'ObjectEditer',
    props: { data: Object, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
        InputEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {},
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        explian(key) {
            var str = attributeMap[key]
            if(str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

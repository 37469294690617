<template>
    <div>
        <el-dialog title="调试数据" :visible.sync="varsVisible" width="70%">
            <el-tabs v-model="activeAttributeName">
                <el-tab-pane label="全局变量" name="全局变量">
                    <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 450px)"
                        v-model="varsData">
                    </editor>
                </el-tab-pane>
                <el-tab-pane label="数据池" name="数据池">
                    <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 450px)"
                        v-model="loaderData">
                    </editor>
                </el-tab-pane>
            </el-tabs>

        </el-dialog>
        <el-button v-if="isPreview" type="text" @click="onShowVars" size="mini"
            style="height: 30px; position: absolute; right: 10px; top: 10px; z-index: 999999;">调试数据</el-button>
        <EasyFlow id="easyApp" ref="easyApp" :data="data" :onComponentSelect="onComponentSelect"
            :onComponentMouseDown="onComponentMouseDown"></EasyFlow>
    </div>
</template>

<script>
import * as EasyUiUlits from './ulits'
import { execSql } from '@/utils/apis/dev/sourceApi'
import axios from 'axios'

export default {
    name: 'EasyRendering',
    props: { data: Object, onComponentSelect: Function, loader: Object, onComponentMouseDown: Function },
    components: { editor: require("vue2-ace-editor") },
    data() {
        return {
            varsVisible: false,
            isPreview: true,
            pageId: null,
            varsData: {},
            loaderData: '',
            dataTasks: [],
            projectId: '',
            activeAttributeName: '全局变量',
            request: null,
            editorOptions: {
                tabSize: 4, // tab默认大小
                showPrintMargin: false, // 去除编辑器里的竖线
                fontSize: 14, // 字体大小
                highlightActiveLine: true, // 高亮配置
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            }
        }
    },
    watch: {
        "loader": {
            handler: function (sources) {
                this.loaderData = JSON.stringify(sources, null, 4)
                this.killAllDataTasks()
                this.startUpdateDataTasks(sources)
            },
            deep: true
        }
    },
    beforeMount() {
        this.loaderData = JSON.stringify(this.loader, null, 4)
        this.isPreview = EasyUiUlits.isPreview()
        this.pageId = EasyUiUlits.getEasyPageId()
        this.projectId = EasyUiUlits.getProjectId()
        // 初始化API
        this.request = axios.create({
            baseURL: process.env.BASE_API,
            timeout: 60 * 1000
        })
    },
    beforeDestroy() {
        // 组件销毁
        this.killAllDataTasks()
    },
    mounted() {
        this.startUpdateDataTasks(this.loader)
    },
    methods: {
        startUpdateDataTasks(sources) {
            console.log("开启数据同步任务")
            Object.keys(sources).forEach(key => {
                if (!key) {
                    return
                }
                const source = sources[key]
                let that = this
                if (source && source.enable) {
                    let api = source.api
                    let database = source.database
                    let expression = source.expression
                    if (api && api.enable) {
                        that.getApiDate(api, key)
                        var apiTaskId = setInterval(function () {
                            that.getApiDate(api, key)
                        }, source.refreshTime * 1000);
                        this.dataTasks.push(apiTaskId)
                    }
                    if (database && database.enable) {
                        that.getDatabaseData(database, key)
                        var databaseTaskId = setInterval(function () {
                            that.getDatabaseData(database, key)
                        }, source.refreshTime * 1000);
                        this.dataTasks.push(databaseTaskId)
                    }
                    if (expression && expression.enable) {
                        that.expression(expression, key)
                        var expressionTaskId = setInterval(function () {
                            that.expression(expression, key)
                        }, source.refreshTime * 1000);
                        this.dataTasks.push(expressionTaskId)
                    }
                }
            });
        },
        getDatabaseData(database, key) {
            let params = { projectId: this.projectId, ...database }
            execSql(params).then(res => {
                if (res.data.code === 200) {
                    this.$store.commit('setLoaderData', { id: this.pageId, key: key, value: this.dataFilter(res.data.data, params.filtercodeJs) });
                    // console.log('执行SQL结果', res.data)
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getApiDate(api, key) {
            // console.log('getApiDate', api, key)
            this.apiClient(key, api)
        },
        killAllDataTasks() {
            console.log("关闭数据同步任务")
            this.dataTasks.forEach(id => {
                clearInterval(id);
            });
            this.dataTasks = []
        },
        onShowVars() {
            this.varsData = JSON.stringify(this.$store.state.easyLoaderData[this.pageId], null, 4)
            this.varsVisible = !this.varsVisible
        },
        editorInit: function (editor) {
            require('brace/ext/language_tools') //language extension prerequsite...           
            require('brace/mode/json')    //language
            require('brace/theme/eclipse')
            require('brace/snippets/json') //snippet
            require('brace/snippets/text')
            this.editor = editor
            editor.setOptions(this.editorOptions)
        },
        handleKeydown(event) {
            if (event.altKey && event.key === '=') {
                this.editorOptions.fontSize = this.editorOptions.fontSize + 1
            }
            if (event.altKey && event.key === '-') {
                this.editorOptions.fontSize = this.editorOptions.fontSize - 1
            }
            this.editor.setOptions(this.editorOptions)
        },
        apiClient(key, api) {
            var axiosRequest = {}
            if (!('Authorization' in Object.keys(api.headers))) {
                api.headers.Authorization = localStorage.getItem('token')
            }
            if (api.body) {
                axiosRequest = { method: api.method, url: api.url, headers: api.headers, data: api.body }
            } else {
                axiosRequest = { method: api.method, url: api.url, headers: api.headers, params: api.params }
            }
            this.request(axiosRequest).then(res => {
                this.$store.commit('setLoaderData', { id: this.pageId, key: key, value: this.dataFilter(res.data.data, api.filtercodeJs) });
            }).catch(err => {
                console.log(err)
            })
        },
        expression(exp, key) {
            var data = EasyUiUlits.valueExplian(this.$store.state.easyLoaderData[this.pageId], exp.value)
            this.$store.commit('setLoaderData', { id: this.pageId, key: key, value: this.dataFilter(data, exp.filtercodeJs) });
        },
        dataFilter(data, code) {
            // console.log("code:\n", code)
            eval(code)
            return data
        }
    }
}
</script>

<style scoped></style>

<template>
    <div style="width: 100%; max-height: 100vh; overflow: auto;">
        <EasyRendering v-if="data && loader" :data="data" :onComponentSelect="onComponentSelect"
            :onComponentMouseDown="onComponentMouseDown" :loader="loader"></EasyRendering>
    </div>
</template>

<script>
import EasyRendering from "./EasyRendering";
import { getAPI } from "@/utils/apis/dev/easyPageApi"
import { createEasyPageId as createId, getEasyPageId, isProduce } from "./ulits";

export default {
    name: 'EasyWindow',
    components: {
        EasyRendering
    },
    data() {
        return {
            easyPage: {},
            data: {
                id: createId(),
                name: "app",
                attribute: {
                    key: createId(),
                    data: { value: null },
                    cStyle: {
                        height: "100%",
                    },
                },
                children: [],
            },
            loader: {

            },
        }
    },
    '$store.state.easyWindowData': {
        handler: function (data) {
            if (this.data !== data[getEasyPageId()]) {
                this.data = data[getEasyPageId()]
            }
        },
        deep: true
    },
    beforeMount() {
        this.loadPageData()
    },
    methods: {
        loadPageData() {
            getAPI({ id: getEasyPageId() }).then(res => {
                if (res.data.code === 200) {
                    this.easyPage = res.data.data
                    let tmpData = res.data.data.data.data
                    let tmpLoader = res.data.data.data.loader
                    if (isProduce()) {
                        tmpData = res.data.data.produce.data
                        tmpLoader = res.data.data.produce.loader
                    }
                    this.removeDesignStyle(tmpData)
                    this.data = tmpData
                    this.loader = tmpLoader
                    this.$store.commit('setEasyWindowData', { id: getEasyPageId(), value: this.data });
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                this.$message.error("获取页面失败！");
                console.log(err)
            })
        },
        removeDesignStyle(data) {
            if ('designStyle' in data) {
                data.designStyle = {}
            }
            if ('children' in data && data.children) {
                for (let index = 0; index < data.children.length; index++) {
                    this.removeDesignStyle(data.children[index])
                }
            }
        },
        onComponentSelect() { },
        onComponentMouseDown() { }
    }
}
</script>

<style scoped></style>

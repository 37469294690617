import ApiPage from "@/pages/dev/apiPage.vue";
import ProjectPage from "@/pages/dev/projectPage.vue";
import ComponentPage from "@/pages/dev/componentPage.vue";
import FunctionPage from "@/pages/dev/functionPage.vue";
import logsPage from "@/pages/dev/logsPage.vue";

import aopView from "@/pages/dev/components/aopView.vue";
import apiView from "@/pages/dev/components/apiView.vue";
import componentView from "@/pages/dev/components/componentView.vue";
import configView from "@/pages/dev/components/configView.vue";
import flowView from "@/pages/dev/components/flowView.vue";
import functionView from "@/pages/dev/components/functionView.vue";
import policyView from "@/pages/dev/components/policyView.vue";
import modelView from "@/pages/dev/components/modelView.vue";
import responseView from "@/pages/dev/components/responseView.vue";
import sourceView from "@/pages/dev/components/sourceView.vue";
import schedulerView from "@/pages/dev/components/schedulerView.vue";
import easyPageView from "@/pages/dev/components/easyPageView.vue";
import mapView from "@/pages/dev/components/mapView.vue";
import fileView from "@/pages/dev/components/fileView.vue";

export default [
  {
    path: "api",
    name: "api",
    component: ApiPage,
    children: [
      {
        path: "client",
        name: "client",
        component: apiView,
      },
      {
        path: "aop",
        name: "aop",
        component: aopView,
      },
      {
        path: "comp",
        name: "comp",
        component: componentView,
      },
      {
        path: "config",
        name: "config",
        component: configView,
      },
      {
        path: "flow",
        name: "flow",
        component: flowView,
      },
      {
        path: "func",
        name: "func",
        component: functionView,
      },
      {
        path: "policy",
        name: "policy",
        component: policyView,
      },
      {
        path: "model",
        name: "model",
        component: modelView,
      },
      {
        path: "response",
        name: "response",
        component: responseView,
      },
      {
        path: "source",
        name: "source",
        component: sourceView,
      },
      {
        path: "scheduler",
        name: "scheduler",
        component: schedulerView,
      },
      {
        path: "easypage",
        name: "easypage",
        component: easyPageView,
      },
      {
        path: "map",
        name: "map",
        component: mapView,
      },
      {
        path: "file",
        name: "file",
        component: fileView,
      },
    ],
  },
  {
    path: "project",
    name: "project",
    component: ProjectPage,
  },
  {
    path: "component",
    name: "component",
    component: ComponentPage,
  },
  {
    path: "function",
    name: "function",
    component: FunctionPage,
  },
  {
    path: "applogs",
    name: "applogs",
    component: logsPage,
  },
];

<template>
  <div class="base-box start-box">
    <el-dialog title="字段设计" :visible.sync="dialogVisible" width="70%">
      <el-tabs v-model="activeName" @tab-click="dataCodeEditTabClick()">
        <el-tab-pane label="字段管理" name="system">
          <el-table size="mini" :data="model.data" style="width: 100%;" height="calc(100vh - 350px)">
            <el-table-column type="index" width="80">
              <template slot="header" slot-scope="scope">
                <el-button type="primary" size="mini"
                  @click="model.data.push({ type: 'string', isSearch: true, width: 150, operator: '=', logical: 'AND', isRequired: false })">新增</el-button>
                {{ scope.row }}
              </template>
            </el-table-column>
            <el-table-column label="字段" width="140">
              <template slot-scope="scope">
                <el-input size="mini" placeholder="请输入内容" v-model="scope.row.column" clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="别名" width="140">
              <template slot-scope="scope">
                <el-input size="mini" placeholder="请输入内容" v-model="scope.row.alias" clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="标签" width="140">
              <template slot-scope="scope">
                <el-input size="mini" placeholder="请输入内容" v-model="scope.row.label" clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="字典" width="140">
              <template slot-scope="scope">
                <el-select size="mini" style="width: 100%;" v-model="scope.row.map" clearable placeholder="请选择字典">
                  <el-option-group v-for="group in mapList" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="下拉" width="140">
              <template slot-scope="scope">
                <el-select size="mini" style="width: 100%;" v-model="scope.row.selectList" clearable
                  placeholder="请选择下拉API">
                  <el-option-group v-for="group in selectList" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="130">
              <template slot-scope="scope">
                <el-select size="mini" v-model="scope.row.type" placeholder="请选择">
                  <el-option v-for="item in modelTypes" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="必填" width="80">
              <template slot-scope="scope">
                <el-select size="mini" v-model="scope.row.isRequired" placeholder="请选择">
                  <el-option v-for="item in [{ value: true, label: '是' }, { value: false, label: '否' }]"
                    :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="查询" width="80">
              <template slot-scope="scope">
                <el-select size="mini" v-model="scope.row.isSearch" placeholder="请选择">
                  <el-option v-for="item in [{ value: true, label: '是' }, { value: false, label: '否' }]"
                    :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="列宽" width="150">
              <template slot-scope="scope">
                <el-input-number size="mini" v-model="scope.row.width"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="默认值" width="140">
              <template slot-scope="scope">
                <el-input size="mini" placeholder="请输入内容" v-model="scope.row.value" clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="查询判断" width="150">
              <template slot-scope="scope">
                <el-select size="mini" v-model="scope.row.operator" placeholder="请选择">
                  <el-option v-for="item in operatorTypes" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="查询逻辑" width="100">
              <template slot-scope="scope">
                <el-select size="mini" v-model="scope.row.logical" placeholder="请选择">
                  <el-option v-for="item in logicalTypes" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="说明" width="300">
              <template slot-scope="scope">
                <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click="model.data.splice(scope.$index, 1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table></el-tab-pane>
        <el-tab-pane label="源码编辑" name="code" @keydown="handleKeydown">
          <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 400px)"
            v-model="code">
          </editor>
          <div class="center-box" style="margin-top: 10px;">
            <el-button type="primary" size="mini" @click="model.data = JSON.parse(code)" style="width: 110px;">保
              存</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" node-key="id" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
              }}</i>
            <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
              @change="data.isEdit = false; onDirectoryRename()"></el-input>
          </span>
          <span>
            <i class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box ">
      <el-table v-show="isShowEdit" size="mini"
        :data="models.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        @row-click="onClickObj" style="width: 100%">
        <el-table-column type="index" width="80">
          <template slot="header" slot-scope="scope">
            <el-button type="primary" size="mini" @click="addRow">添加</el-button>
            {{ scope.row }}
          </template>
        </el-table-column>
        <el-table-column label="模型名(表名)">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="字段" width="100">
          <template slot-scope="scope">
            <el-button v-if="scope.row.id" type="text" style="margin-left: 4px;" size="mini"
              @click="editModel(scope.row.data)">设计字段</el-button>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
            {{ scope.row }}
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-check" @click="add">提交</el-button>
            <el-button v-if="scope.row.id" size="mini" type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI } from "@/utils/apis/dev/modelApi"
import * as viewApi from "@/utils/apis/dev/viewApi"
import * as mapApi from "@/utils/apis/dev/mapApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'modelView',
  components: { editor: require("vue2-ace-editor") },
  data() {
    return {
      treedatas: [],
      directory: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      model: {
        id: null,
        name: '',
        data: [],
        description: ''
      },
      models: [],
      search: '',
      projectId: null,
      projectName: null,
      isShowEdit: false,
      dialogVisible: false,
      activeName: "system",
      code: '',
      mapList: [],
      selectList: [],
      logicalTypes: [
        {
          value: "AND",
          label: "AND"
        },
        {
          value: "OR",
          label: "OR"
        },
      ],
      operatorTypes: [
        {
          value: "=",
          label: "等于"
        },
        {
          value: "!=",
          label: "不等于"
        },
        {
          value: "<",
          label: "小于"
        },
        {
          value: "<=",
          label: "小于等于"
        },
        {
          value: ">",
          label: "大于"
        },
        {
          value: ">=",
          label: "大于等于"
        },
        {
          value: "IS",
          label: "是"
        },
        {
          value: "IS NOT",
          label: "不是"
        },
        {
          value: "LIKE",
          label: "LIKE"
        },
        {
          value: "IN",
          label: "IN"
        },
      ],
      editorOptions: {
        tabSize: 4, // tab默认大小
        showPrintMargin: false, // 去除编辑器里的竖线
        fontSize: 14, // 字体大小
        highlightActiveLine: true, // 高亮配置
        enableSnippets: true,
        enableLiveAutocompletion: true,
        enableBasicAutocompletion: true
      },
      modelTypes: [
        {
          value: "key",
          label: "主键"
        },
        {
          value: "string",
          label: "字符串"
        },
        {
          value: "percentage",
          label: "百分比(%)"
        },
        {
          value: "datetime",
          label: "日期时间"
        },
        {
          value: "number",
          label: "整数"
        },
        {
          value: "json",
          label: "JSON"
        },
        {
          value: "bool",
          label: "布尔型"
        },
        {
          value: "float",
          label: "浮点型"
        },
      ]
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
    this.getSelectList()
    this.getMapList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList() {
      getListAPI(this.directory.id).then(res => {
        if (res.data.code === 200) {
          this.models = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'model').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.model).then(res => {
        if (res.data.code === 200) {
          this.model = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data) {
      this.directory = data
      this.getList()
      this.isShowEdit = true
    },
    onClickObj(data) {
      this.model = data
      this.code = JSON.stringify(this.model.data, null, 4)
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(index, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addRow() {
      this.models.push({
        pid: this.directory.id,
        projectId: this.projectId, data: [],
      })
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'model',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },

    editModel() {
      this.dialogVisible = true
    },
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...           
      require('brace/mode/json')    //language
      require('brace/theme/eclipse')
      require('brace/snippets/python') //snippet
      require('brace/snippets/text')
      this.editor = editor
      editor.setOptions(this.editorOptions)
    },
    handleKeydown(event) {
      if (event.altKey && event.key === '=') {
        this.editorOptions.fontSize = this.editorOptions.fontSize + 1
      }
      if (event.altKey && event.key === '-') {
        this.editorOptions.fontSize = this.editorOptions.fontSize - 1
      }
      this.editor.setOptions(this.editorOptions)
    },
    dataCodeEditTabClick() {
      if (this.activeName === "code") {
        this.code = JSON.stringify(this.model.data, null, 4)
      }
    },
    getMapList() {
      mapApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.mapList = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getSelectList() {
      viewApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.selectList = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>



</style>
import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/log";
let list_url = "/api/logs";

let app_list_url = "/api/logs/";

// get请求
export function getListAPI(params) {
  return http.get(list_url, params);
}

// get请求
export function getAppLogListAPI(p_id, params) {
  return http.get(app_list_url + p_id, params);
}

// get请求
export function getAPI(params) {
  return http.get(url, params);
}

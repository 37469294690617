<template>
    <div :style="data.designStyle" @mouseout.stop="evt => onComponentSelect(data.id, evt)"
        @mouseover.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)"
            :ref="data.attribute.key" :id="data.id" class="start-box">
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
        <div v-if="isDesign" style="height: 60px;" class="center-box"
            @mouseover.stop="evt => onComponentSelect(data.id, evt)">
            <div style="color: rgb(106, 115, 122); font-size: 14px; margin-top: 18px;">拖动组件到 [横向部局]</div>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'

export default {
    name: 'EasyHorizontal',
    components: {
    },
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: '',
            pageId: null,
            isDesign: false,
        }
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.isDesign = EasyUiUlits.isDesign()
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        }
    }
}
</script>

<style scoped></style>

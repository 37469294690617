<template>
  <div style="background: rgb(16, 20, 27); padding: 15px; min-height: 100vh;">
    <div class="between-box">
      <!-- <div style="width: 300px;">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div> -->
      <div style="width: 100%; color: #FFFFFF;">
        <h1>项目: {{ project.name }}</h1>
        <el-input placeholder="请输入内容" v-model="keyword">
          <template slot="append">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </template>
        </el-input>
        <ul v-infinite-scroll="load" style="overflow:auto; padding-left: 0px; min-height: 90vh;">
          <li v-for="(api, index) in apis" :key="index">
            <div style="color: #FFFFFF; width: 100%;">
              <h1>{{ api.directory }}</h1>
              <h2>{{ index + 1 }}、{{ api.name }}</h2>
              <span>{{ api.description }}</span>
              <h3>基本信息</h3>
              <div>
                <span>API: {{ api.path }}</span>
              </div>
              <div>
                <span>Method: {{ api.method }}</span>
              </div>
              <h3>头部说明</h3>
              <el-table :data="api.headers"  size="mini"
                style="width: 100%">
                <el-table-column prop="key" label="字段名" width="180">
                </el-table-column>
                <el-table-column prop="value" label="示例" width="180">
                </el-table-column>
                <el-table-column prop="description" label="说明">
                </el-table-column>
                <el-table-column prop="need" label="是否必须" width="180">
                </el-table-column>
              </el-table>
              <h3>字段说明</h3>
              <el-table :data="api.body"  size="mini"
                style="width: 100%">
                <el-table-column prop="key" label="字段名" width="180">
                </el-table-column>
                <el-table-column prop="value" label="示例" width="180">
                </el-table-column>
                <el-table-column prop="description" label="说明">
                </el-table-column>
                <el-table-column prop="need" label="是否必须" width="180">
                </el-table-column>
              </el-table>
              <h3>请求示例</h3>
              <h4>{{ api.demo.method }} {{ api.demo.path }} <el-button type="primary"
                  @click="test(api.demo.path, api.demo.method)" size="mini">调试</el-button></h4>
              <el-input size="mini" type="textarea" :rows="5" disabled placeholder="无" v-model="api.demo.body">
              </el-input>
              <h3>返回结果</h3>
              <el-input size="mini" type="textarea" :rows="5" disabled placeholder="无" v-model="api.demoRes">
              </el-input>
              <el-divider></el-divider>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getApiDocAPI,
  getAPI
} from "@/utils/apis/dev/projectApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'apiDocPage',
  data() {
    return {
      apis: [],
      count: 0,
      keyword: '',
      projectId: '',
      project: {},
    };
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.getProject()
    }
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    },
    
    getProject() {
      getAPI({ id: this.projectId }).then(res => {
        if (res.data.code === 200) {
          this.project = res.data.data
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async load() {
      console.log(this.count)
      await getApiDocAPI({ index: this.count, projectId: this.projectId, keyword: this.keyword }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data) {
            this.apis.push(JSON.parse(res.data.data.data))
            this.count += 1
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    search() {
      this.apis = []
      this.count = 0
    },
    test(path, method) {
      let url = '/api/app/' + this.projectId + '/' + path
      window.open('/#/tool/urlTest?url=' + url + '&method=' + method)
    },
  }
}
</script>

<style></style>
import Vue from "vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

// Prism
import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

import App from "./App.vue";
import router from "@/routes/index.js";
import store from "./store/store";
import "./directive/directive";

import EasyUi from "@/pages/easy_ui/EasyComponentsRegister";

Vue.use(ElementUI);
Vue.use(EasyUi);
Vue.use(VueMarkdownEditor);

Vue.config.productionTip = false;



new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

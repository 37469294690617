<template>
    <div @mousemove="nodeTempMouseMove" @mouseup="onRenderingMouseUp" style=" width: 100vw; height: 100vh;">
        <div style="position: relative; width: 0px; height: 0px;" v-show="isMoveTempShow">
            <div class="move-temp-box" :style="moveTempStyle" unselectable="on">
                <el-avatar :src="currentMoveTemp.img" :size="30" shape="square"></el-avatar>
            </div>
        </div>
        <div class="start-wrap-box easy-component-list border-bottom-box" style="margin-bottom: 0px;">
            <!-- Easy设计名称 -->
            <div style="width: 16%;">
                <div class="between-box" style="width: 100%; margin-top: 14px; margin-left: 14px;">
                    <div style=" font-size: 20px;" @click="onSelectComponent(data)">
                        {{ easyPage.name }}
                    </div>
                </div>
            </div>
            <!-- 组件栏 -->
            <div class="center-box" style="width: 64%;">
                <!-- 组件分类 -->
                <div class="between-box" style="width: 40%;  margin-top: 6px;">
                    <div v-for="(item, index) in temps" :key="index" @mouseenter="onComponentTempListShow(item)">
                        <el-avatar :src="item.img" :size="32" shape="square"></el-avatar>
                        <div class="center-box" style="width: 32px;">
                            <div style="font-size: 12px; ">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 工具栏 -->
            <div style="width: 20%;">
                <div class="start-box" style="width: 100%; justify-content: flex-end;  margin-top: 14px;">
                    <div style="margin-right: 14px;">
                        <el-button type="text" @click="openPage" size="mini" style="height: 30px;">预览</el-button>
                        <el-button type="text" size="mini" style="height: 30px;">导出</el-button>
                        <el-button type="text" size="mini" style="height: 30px;">导入</el-button>
                        <el-button type="text" @click="editSourceCode()" size="mini"
                            style="height: 30px;">源码</el-button>
                        <el-button type="text" @click="reback()" size="mini" style="height: 30px;">后退</el-button>
                        <el-button type="text" @click="next()" size="mini" style="height: 30px;">前进</el-button>
                        <el-button type="text" @click="savePage" size="mini" style="height: 30px;">保存</el-button>
                    </div>
                </div>
                <el-dialog title="编辑源码" :visible.sync="sourceCodeShow" width="70%">
                    <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 450px)"
                        v-model="sourceCode">
                    </editor>
                    <div class="center-box" style="margin-top: 16px;">
                        <el-button @click="saveSourceCode()" type="primary" size="mini"
                            style="width: 110px;">保存</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <div class="start-box">
            <div class="easy-component-box">
                <el-input @input="onFilterTextChange" size="mini" style="margin-bottom: 8px; margin-top: 8px;"
                    placeholder="输入关键字进行过滤" v-model="filterText" clearable>
                </el-input>
                <el-tree size="mini" draggable ref="componentTree" node-key="id" :data="data.children"
                    :props="defaultProps" :filter-node-method="filterNode" @node-click="onSelectComponent"
                    default-expand-all>
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>
                            <i class="el-icon-document ellipsis easy-tree-max-width">&nbsp;{{ data.name }}</i>
                        </span>
                        <span>
                            <i v-if="data.attribute.component === 'EasyTabs'" class="el-icon-circle-plus-outline"
                                @click.stop="addEasyTab(data, node)" style="margin-right: 8px;"></i>
                            <i class="el-icon-document-copy" @click.stop="copyTreeComponent(data, node)"
                                style="margin-right: 8px;"></i>
                            <i class="el-icon-delete" @click.stop="removeTreeComponent(data, node)"
                                style="margin-right: 8px;"></i>
                            <i class="el-icon-paperclip" @click.stop="showCollectComponentInfo(data)"></i>
                        </span>
                    </span>
                </el-tree>
            </div>
            <div class="easy-edit-box">
                <!-- 子组件列表 -->
                <div class="start-box easy-component-temp-box" v-show="componentTempListShow">
                    <div v-for="(item, index) in tempList" :key="index"
                        style="margin-left: 12px; margin-top: 8px; margin-bottom: 8px;"
                        @mousedown="evt => onTempMouseDown(evt, item)">
                        <el-avatar :src="item.img" :size="70" shape="square"></el-avatar>
                        <div class="center-box" style="width: 70px;">
                            <div style="font-size: 12px; ">{{ item.name }}</div>
                        </div>
                    </div>
                    <div v-if="tempList.length === 0">
                        <div>暂无数据</div>
                    </div>
                </div>
                <div :style="renderStyle">
                    <EasyRendering :data="data" :onComponentSelect="onComponentSelect" :loader="loader"
                        :onComponentMouseDown="onComponentMouseDown"></EasyRendering>
                </div>
            </div>
            <div class="easy-attribute-box">
                <div style="font-size: 14px; width: 94%; height: calc(100vh - 116px); margin: 8px; overflow: auto;">
                    <div class="border-bottom-box" style="font-weight: bold;">当前组件</div>
                    <div class="start-box" style="margin-top: 8px;">
                        <div style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                            ID
                        </div>
                        <el-input readonly="" v-model="currentComponent.id" size="mini" type="text"></el-input>
                    </div>
                    <div class="start-box" style="margin-top: 8px;">
                        <div style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                            名称
                        </div>
                        <el-input v-model="currentComponent.name" size="mini" type="text"
                            placeholder="请输入内容"></el-input>
                    </div>
                    <el-tabs v-model="activeAttributeName">
                        <el-tab-pane label="基础" name="基础">
                            <AttributeEditer :pKey="'attribute'" :updateAttributeData="updateAttributeData"
                                :data="currentComponent.attribute" :noShowKeys="BaseNoShowKeys">
                            </AttributeEditer>
                        </el-tab-pane>
                        <el-tab-pane label="数据" name="数据">
                            <SourceEditer :pKey="currentComponent.attribute.key"
                                :data="loader[currentComponent.attribute.key]" :noShowKeys="DataNoShowKeys"
                                :updateAttributeData="updateDatabaseData"></SourceEditer>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import temp from "./components/config"
import attributeMap from './attributeMap'
import EasyRendering from "./EasyRendering";
import AttributeEditer from "./editer/AttributeEditer"
import SourceEditer from "./editer/SourceEditer"
import { createEasyPageId as createId, getEasyPageId, OperationLog, distance, getProjectId } from "./ulits";
import { getAPI, addAPI } from "@/utils/apis/dev/easyPageApi";
import * as easyPageComponentApi from "@/utils/apis/dev/easyPageComponentApi";

export default {
    name: "EasyEdit",
    components: {
        EasyRendering,
        AttributeEditer,
        SourceEditer,
        editor: require("vue2-ace-editor")
    },
    data() {
        return {
            BaseNoShowKeys: [
                'cStyle', 'data', 'options',
                'label', 'events', 'pageParams',
                'model', 'api', 'component',
                'actions', 'echart'],
            DataNoShowKeys: ['database', 'api', 'expression'],
            filterText: '',
            easyPage: {},
            activeAttributeName: '基础',
            isMoveTempShow: false,
            moveTempStyle: { top: 0, left: 0, margin: 0, userSelect: "none" },
            currentMoveTemp: {
                img: '',
                name: ''
            },
            selectComponentId: null,
            temps: [],
            tempList: [],
            sourceCode: "",
            sourceCodeShow: false,
            componentTempListShow: false,
            currentComponent: {
                name: "",
                component: "",
                children: [],
            },
            copyData: {},
            data: {
                id: createId(),
                name: "app",
                attribute: {
                    key: createId(),
                    data: { value: '' },
                    cStyle: {
                        height: "100%",
                        background: "#FFFFFF"
                    },
                },
                children: [],
                // 设计器中的样式
                designStyle: {
                    height: "999999px",
                },
            },
            loader: {

            },
            loderTemp: {
                enable: false,
                refreshTime: 300,
                database: {
                    enable: false,
                    sourceId: '',
                    sql: 'select * from test',
                    filtercodeJs: '// data变量值为目标数据\n// console.log(data)\n'
                },
                api: {
                    enable: false,
                    url: 'https://test.com',
                    method: 'GET',
                    body: {},
                    params: {},
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    filtercodeJs: '// data变量值为目标数据\n// console.log(data)\n'
                },
                expression: {
                    enable: false,
                    value: '',
                    filtercodeJs: '// data变量值为目标数据\n// console.log(data)\n'
                }
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            renderStyle: {
                padding: "8px",
                height: "97%",
                overflow: "auto"
            },
            isDown: false,
            operationLog: null,
            startXY: [],
            endXY: [],
            isMoveComponent: false,
            editorOptions: {
                tabSize: 4, // tab默认大小
                showPrintMargin: false, // 去除编辑器里的竖线
                fontSize: 14, // 字体大小
                highlightActiveLine: true, // 高亮配置
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            }
        };
    },

    watch: {
        // 监控组件值的变化后提交到变量池
        "loader": {
            handler: function (loader) {
                this.$store.commit('setEasyLoaderSetting', { id: getEasyPageId(), value: loader });
            },
            deep: true
        }
    },
    beforeMount() {
        this.currentComponent = this.data
        this.selectComponentId = this.data.id
        this.$set(this.loader, this.currentComponent.attribute.key, JSON.parse(JSON.stringify(this.loderTemp)))
        this.temps = temp
        this.loadPageData()
        this.getCollectComponents()
        this.onSelectComponent(this.currentComponent)
    },
    mounted() {
        // 确保在div挂载之后添加事件监听
        window.addEventListener('keyup', this.handleKeyEvent);
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听
        window.removeEventListener('keyup', this.handleKeyEvent);
    },
    methods: {
        loadPageData() {
            getAPI({ id: getEasyPageId() }).then(res => {
                if (res.data.code === 200) {
                    this.easyPage = res.data.data
                    this.data = res.data.data.data.data
                    this.loader = res.data.data.data.loader
                    this.operationLog = new OperationLog(30, this.data)
                    this.currentComponent = this.data
                    this.selectComponentId = this.data.id
                    this.operationLog.log({ data: this.data, loader: this.loader })
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                this.$message.error("网络出错！");
                console.log(err)
            })
        },
        onSelectComponent(component) {
            if (this.currentComponent.id !== component.id) {
                this.$refs.componentTree.setCurrentKey(component.id)
                this.setSelectDesignStyle(this.currentComponent.id, false, false)
                this.currentComponent = component
                this.setSelectDesignStyle(component.id, true, false)
            }
        },
        explian(key) {
            var str = attributeMap[key]
            if (str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
        removeTreeComponent(data) {
            this.removeTreeLoader(data.id)
            this.$refs.componentTree.remove(data)
            this.onSelectComponent(this.data)
            this.operationLog.log({ data: this.data, loader: this.loader })
        },
        removeTreeLoader(id) {
            let node = this.$refs.componentTree.getNode(id)
            if (node) {
                this.removeTreeLoaderDeep(node.data)
            }
        },
        removeTreeLoaderDeep(data) {
            if ('attribute' in data && data.attribute.key) {
                this.$delete(this.loader, data.attribute.key)
            }
            if ('children' in data && data.children) {
                for (let index = 0; index < data.children.length; index++) {
                    if ('attribute' in data.children[index] && data.children[index].attribute.key) {
                        this.$delete(this.loader, data.children[index].attribute.key)
                    }
                    this.removeTreeLoaderDeep(data.children[index])
                }
            }
        },
        copyTreeComponent(data, node) {
            let dataTemp = JSON.parse(JSON.stringify(data))
            this.addComponent(dataTemp, node.parent.data.id)
        },
        editorInit: function (editor) {
            require('brace/ext/language_tools') //language extension prerequsite...           
            require('brace/mode/json')    //language
            require('brace/theme/eclipse')
            require('brace/snippets/json') //snippet
            require('brace/snippets/text')
            this.editor = editor
            editor.setOptions(this.editorOptions)
        },
        handleKeydown(event) {
            if (event.altKey && event.key === '=') {
                this.editorOptions.fontSize = this.editorOptions.fontSize + 1
            }
            if (event.altKey && event.key === '-') {
                this.editorOptions.fontSize = this.editorOptions.fontSize - 1
            }
            this.editor.setOptions(this.editorOptions)
        },
        editSourceCode() {
            this.sourceCode = JSON.stringify({ data: this.data, loader: this.loader }, null, 4)
            this.sourceCodeShow = !this.sourceCodeShow
        },
        saveSourceCode() {
            try {
                let page = JSON.parse(this.sourceCode)
                this.data = page.data
                this.loader = page.loader
                this.sourceCodeShow = !this.sourceCodeShow
            } catch (error) {
                this.$message.error("格式错误: " + error);
            }
        },
        onComponentTempListShow(temp) {
            if (temp) {
                this.tempList = temp.components
            }
            this.componentTempListShow = !this.componentTempListShow
        },
        addComponent(component, parentID) {
            try {
                if (component.id === parentID) { return }
                let loader = this.loader[component.attribute.key]
                if (!loader) {
                    loader = JSON.parse(JSON.stringify(this.loderTemp))
                }
                let item = JSON.parse(JSON.stringify(component))
                item.id = createId()
                item.attribute.key = createId()
                this.deepCopyComponent(item)
                if (parent) {
                    this.$refs.componentTree.append(item, parentID)
                } else {
                    this.$refs.componentTree.append(item)
                }
                this.$set(this.loader, item.attribute.key, loader)
                let node = this.$refs.componentTree.getNode(item.id)
                this.onSelectComponent(node.data)
                this.operationLog.log({ data: this.data, loader: this.loader })
            } catch (error) {
                console.log(error)
                this.$message.error("添加错误:" + error);
            }
        },
        deepCopyComponent(component) {
            if (component.children) {
                for (let index = 0; index < component.children.length; index++) {
                    let loader = this.loader[component.children[index].attribute.key]
                    if (!loader) {
                        loader = JSON.parse(JSON.stringify(this.loderTemp))
                    }
                    component.children[index].id = createId()
                    component.children[index].attribute.key = createId()
                    this.$set(this.loader, component.children[index].attribute.key, loader)
                    this.deepCopyComponent(component.children[index])
                }
            }
        },
        onTempMouseDown(evt, component) {
            // 解决拖动会选中文字的问题
            document.onselectstart = function () { return false; };
            const {
                clientX,
                clientY,
            } = evt
            this.startXY = [clientX, clientY]
            this.currentMoveTemp = JSON.parse(JSON.stringify(component.temp))
            this.currentMoveTemp.id = createId()
            this.moveTempStyle = {
                top: clientY,
                left: clientX,
            }
            this.isDown = true
            this.isMoveComponent = false
        },
        onComponentMouseDown(evt, component) {
            // 解决拖动会选中文字的问题
            document.onselectstart = function () { return false; };
            // 排除主布局
            if (component.id === this.data.id) {
                return
            }
            // 设计面板的组件拖动
            const {
                clientX,
                clientY,
            } = evt
            this.startXY = [clientX, clientY]
            this.currentMoveTemp = JSON.parse(JSON.stringify(component))
            this.moveTempStyle = {
                top: clientY,
                left: clientX,
            }
            this.isDown = true
            this.isMoveComponent = true
        },
        nodeTempMouseMove(evt) {
            const { clientX, clientY } = evt
            if (this.isDown) {
                this.moveTempStyle.left = clientX + "px"
                this.moveTempStyle.top = clientY + "px"
                this.isMoveTempShow = true
                let et = evt || window.event
                et.preventDefault()
            }
        },
        onRenderingMouseUp(evt) {
            // 解决拖动会选中文字的问题
            document.onselectstart = null;
            this.isMoveTempShow = false
            const { clientX, clientY } = evt
            this.endXY = [clientX, clientY]
            if (this.isDown) {
                var moveDistance = distance(this.startXY[0], this.startXY[1], this.endXY[0], this.endXY[1])
                if (moveDistance < 20) {
                    this.isDown = false
                    return
                }
                if (this.isMoveComponent) {
                    let node = this.$refs.componentTree.getNode(this.currentMoveTemp.id)
                    this.$refs.componentTree.remove(node)
                }
                if (this.selectComponentId === this.data.id) {
                    this.addComponent(this.currentMoveTemp)
                } else {
                    this.addComponent(this.currentMoveTemp, this.selectComponentId)
                }
                this.componentTempListShow = false
            }
            this.isDown = false
        },
        onComponentSelect(id, event) {
            if (event.type === "click") {
                let node = this.$refs.componentTree.getNode(id)
                if (node && node.data) {
                    this.onSelectComponent(node.data)
                }
            }
            if (event.type === "mouseover") {
                this.selectComponentId = id
                this.setSelectDesignStyle(id, true, true)
            }
            if (event.type === "mouseout") {
                this.setSelectDesignStyle(id, false, true)
            }
        },
        setSelectDesignStyle(id, isSelect, isMouseover) {
            let node = this.$refs.componentTree.getNode(id)
            if (node) {
                if (isMouseover) {
                    if (isSelect) {
                        node.data.designStyle.border = '3px solid green'
                    } else {
                        if (this.currentComponent.id === id) {
                            node.data.designStyle.border = '3px solid red'
                        } else {
                            node.data.designStyle.border = '1px solid black'
                        }
                    }
                } else {
                    if (isSelect) {
                        node.data.designStyle.border = '3px solid red'
                    } else {
                        node.data.designStyle.border = '1px solid black'
                    }
                }
            }
        },
        updateAttributeData(key, value, isDelete) {
            // console.log(key, value, isDelete)
            if (key === 'attribute.key') {
                let old = JSON.parse(JSON.stringify(this.loader[this.currentComponent.attribute.key]))
                this.removeTreeLoader(this.currentComponent.id)
                this.$set(this.loader, value, old)
            }
            const keys = key.split('.')
            this.assignTreeIndex(this.currentComponent, keys, value, isDelete)
            // this.operationLog.log({ data: this.data, loader: this.loader })
        },
        updateDatabaseData(key, value, isDelete) {
            // console.log(key, value, isDelete)
            const keys = key.split('.')
            this.assignTreeIndex(this.loader, keys, value, isDelete)
            // this.operationLog.log({ data: this.data, loader: this.loader })
        },
        assignTreeIndex(obj, treeIndex, value, isDelete) {
            if (!obj || !treeIndex || treeIndex.length === 0) {
                return;
            }
            const key = treeIndex.shift();
            if (treeIndex.length === 0) {
                if (isDelete) {
                    this.$delete(obj, key)
                } else {
                    this.$set(obj, key, value)
                }
            } else {
                if (!obj[key]) {
                    if (isDelete) {
                        if (key in Object.keys(obj)) {
                            this.$delete(obj, key)
                        }
                    } else {
                        this.$set(obj, key, {})
                    }
                }
                this.assignTreeIndex(obj[key], treeIndex, value, isDelete);
            }
        },
        savePage() {
            this.easyPage.data = {
                data: this.data,
                loader: this.loader,
            }
            addAPI(this.easyPage).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },
        openPage() {
            let url = "/#/easyui/window?status=preview&easyPageId=" + getEasyPageId() + "&projectId=" + getProjectId()
            window.open(url)
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        onFilterTextChange() {
            this.$refs.componentTree.filter(this.filterText);
        },
        addEasyTab(data) {
            var tab = createId()
            var tagTemp = {
                id: createId(),
                name: "未命名",
                attribute: {
                    tab: tab,
                    key: createId(),
                    data: {},
                    // 标签页样式
                    cStyle: {
                        width: "100%",
                        minHeight: "400px"
                    },
                },
                children: [],
                // 设计器中的样式
                designStyle: {
                    border: "1px solid black",
                    padding: "8px",
                },
            }
            this.addComponent(tagTemp, data.id)
        },
        handleKeyEvent(event) {
            if (event.key === 'd' && event.ctrlKey) {
                let node = this.$refs.componentTree.getNode(this.currentComponent.id)
                this.addComponent(this.currentComponent, node.parent.data.id)
            } else if (event.key === 'b' && event.ctrlKey) {
                this.reback()
            } else if (event.key === 'n' && event.ctrlKey) {
                this.next()
            } else if (event.key === 'Delete') {
                this.removeTreeComponent(this.currentComponent)
            }
        },
        reback() {
            let log = this.operationLog.back()
            this.data = log.data
            this.loader = log.loader
            this.onSelectComponent(this.data)
            this.currentComponent = this.data
        },
        next() {
            let log = this.operationLog.next()
            this.data = log.data
            this.loader = log.loader
            this.onSelectComponent(this.data)
            this.currentComponent = this.data
        },
        getCollectComponents() {
            const projectId = getProjectId()
            easyPageComponentApi.getListAPI(projectId).then(res => {
                if (res.data.code === 200) {
                    var components = []
                    res.data.data.forEach(element => {
                        components.push(element.data)
                    });
                    // console.log(components, "components")
                    if (components) {
                        var res_data = {
                            name: "收藏",
                            components: components,
                            img: require('@/pages/easy_ui/images/options.png')
                        }
                        var is_have = false
                        for (let index = 0; index < this.temps.length; index++) {
                            if (this.temps[index].name == '收藏') {
                                is_have = true
                                this.temps[index] = res_data
                                break
                            }
                        }
                        if (!is_have) {
                            this.temps.push(res_data)
                        }
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },
        showCollectComponentInfo(component) {
            this.$prompt('请输入收藏的组件名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.[^n]*$/,
                inputErrorMessage: '不能为空'
            }).then(({ value }) => {
                this.collectComponent(component, value)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消收藏'
                });
            });
        },
        collectComponent(component, name) {
            var data = {
                pid: this.easyPage.pid,
                name: name,
                data: {
                    img: '',
                    name: name,
                    temp: component
                },
                projectId: getProjectId(),
                description: ''
            }
            easyPageComponentApi.addAPI(data).then(res => {
                if (res.data.code === 200) {
                    this.getCollectComponents()
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style>
.easy-component-list {
    width: 100%;
    height: 60px;
    padding-bottom: 10px;
}

.easy-component-box {
    width: 100%;
    max-width: 360px;
    height: calc(100vh - 80px);
    overflow: auto;
    border-right: 1px solid black;
}

.easy-edit-box {
    width: 100%;
    max-width: calc(100vw - 820px);
    margin-left: 10px;
    margin-right: 10px;
    height: calc(100vh - 80px);
}

.easy-attribute-box {
    width: 100%;
    max-width: 460px;
    height: calc(100vh - 80px);
    border-left: 1px solid black;
}

.easy-component-temp-box {
    padding: 0px;
    height: 120px;
    overflow-x: auto;
    overflow-y: hidden;
    
}

.move-temp-box {
    position: fixed;
}
</style>

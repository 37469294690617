<template>
  <div id="app" class="base-box">
    <router-view></router-view>
  </div>
</template>

<script>
import './styles/global.css';

export default {
  name: 'App',
  components: {

  }
}
</script>

<style></style>

<template>
  <div class="base-box ">
    <el-table size="mini"
      :data="files.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
      @row-click="onClickObj" style="width: 100%">
      <el-table-column type="index" width="80">
        <template slot="header" slot-scope="scope">
          <el-upload :action="uploadUrl" :show-file-list="false" :on-success="onUploadSuccess"
            :before-upload="beforeAvatarUpload">
            <el-button size="mini" type="primary">上传</el-button>
          </el-upload>
          {{ scope.row }}
        </template>
      </el-table-column>
      <el-table-column label="文件名">
        <template slot-scope="scope">
          <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="minio URL">
        <template slot-scope="scope">
          <el-input size="mini" placeholder="请输入内容" v-model="scope.row.url" clearable>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="下载路径">
        <template slot-scope="scope">
          <el-input size="mini" placeholder="请输入内容" v-model="scope.row.path" clearable>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="上传时间">
        <template slot-scope="scope">
          {{ scope.row.updateTime }}
        </template>
      </el-table-column>
      <el-table-column label="数据级别">
        <template slot-scope="scope">
          <el-select size="mini" style="width: 100%;" v-model="scope.row.dataLevel">
            <el-option v-for="item in dataLevelOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column align="right" width="260px">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
          {{ scope.row }}
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="success" icon="el-icon-check" @click="add(scope.row)">提交</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          <el-button size="mini" type="info" icon="el-icon-download" @click="download(scope.row.url)">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as fileApi from "@/utils/apis/dev/fileApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'projectFileView',
  components: {
  },
  data() {
    return {
      file: {
        id: null,
      },
      files: [],
      search: '',
      projectId: null,
      projectName: null,
      uploadUrl: '',
      downloadUrl: '',
      dataLevelOptions: [
        {
          value: 'open',
          label: '开放'
        }, {
          value: 'normal',
          label: '保护'
        }
      ]
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.uploadUrl = '/api/upload/' + this.projectId + '?token=' + localStorage.getItem('token')
    this.downloadUrl = '/api/download/' + this.projectId + "/"
    this.getList()
  },
  watch: {
  },
  methods: {
    getList() {
      fileApi.getListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.files = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add(file) {
      fileApi.addAPI(file).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      fileApi.deleteAPI({ 'id': data.id }).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    onClickObj(data) {
      this.file = data
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(index, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    onUploadSuccess(res) {
      if (res.code === 200) {
        this.$message.success('上传成功!');
        this.getList()
      } else {
        this.$message.error('上传失败');
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传文件不能大于 50MB!');
      }
      return isLt2M;
    },
    getDownloadUrl(url) {
      return this.downloadUrl + url + '?token=' + localStorage.getItem('token')
    },
    download(url) {
      window.open(this.getDownloadUrl(url))
    }
  },
}
</script>

<style scoped>



</style>
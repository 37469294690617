<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <div :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle"
                    :type="data.attribute.type"></div>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits';
import * as echarts from 'echarts';

export default {
    name: "EasyClockChart",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
            myChart: null,
            taskId: null,
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.myChart.resize()
                this.setOption(val);
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.option = JSON.parse(JSON.stringify(this.data.attribute.data.echart))
                this.value = data.attribute.data.value
                this.myChart.resize()
                this.setOption(this.value);
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    mounted() {
        this.myChart = echarts.init(document.getElementById(this.data.id));
        // 绘制图表
        this.setOption(this.value)

    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        setOption(option) {
            let that = this
            if (this.taskId) {
                clearInterval(this.taskId)
            }
            this.taskId = setInterval(() => {
                var date = new Date();
                var second = date.getSeconds();
                var minute = date.getMinutes() + second / 60;
                var hour = (date.getHours() % 12) + minute / 60;
                option.animationDurationUpdate = 300;
                that.myChart.setOption({
                    series: [
                        {
                            name: 'hour',
                            animation: hour !== 0,
                            data: [{ value: hour }]
                        },
                        {
                            name: 'minute',
                            animation: minute !== 0,
                            data: [{ value: minute }]
                        },
                        {
                            animation: second !== 0,
                            name: 'second',
                            data: [{ value: second }]
                        }
                    ]
                });
            }, 1000);
            this.myChart.setOption(option);
        }
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

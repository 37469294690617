import { getUrlArgs } from "@/tools/url";
import { v4 as uuidv4 } from "uuid";

export function valueExplian(data, value) {
  var jsonValue = value;
  if (typeof value === "object") {
    jsonValue = JSON.stringify(value);
  }
  // 正则表达式用于匹配 ${...}
  const regex = /\$\{([^}]+)\}/g;
  // 使用 match() 方法获取所有匹配项
  let match;
  while ((match = regex.exec(jsonValue)) !== null) {
    var key = match[1];
    var keys = key.split(".");
    var resValue = null;
    for (let index = 0; index < keys.length; index++) {
      const k = keys[index];
      resValue = data[k];
    }
    if (resValue === "object") {
      resValue = JSON.stringify(value);
    }
    jsonValue = jsonValue.replaceAll("${" + key + "}", resValue);
  }
  if (typeof value === "object") {
    return JSON.parse(jsonValue);
  }
  return jsonValue;
}

export function isDesign() {
  let urlParams = getUrlArgs();
  if (urlParams.status && urlParams.status === "design") {
    return true;
  } else {
    return false;
  }
}

export function isProduce() {
  let urlParams = getUrlArgs();
  if (urlParams.status && urlParams.status === "produce") {
    return true;
  } else {
    return false;
  }
}

export function isPreview() {
  let urlParams = getUrlArgs();
  if (urlParams.status && urlParams.status === "preview") {
    return true;
  } else {
    return false;
  }
}

export function getEasyPageId() {
  let urlParams = getUrlArgs();
  return urlParams.easyPageId;
}

export function createEasyPageId() {
  let id = uuidv4();
  id = id.replaceAll("-", "");
  return id;
}

export function getProjectId() {
  let urlParams = getUrlArgs();
  return urlParams.projectId;
}

export function getComponentKey(pKey) {
  var keys = pKey.split(".");
  return keys[0];
}

export function distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

function extractTemplateVariables(templateString) {
  // 使用正则表达式匹配 ${...} 中的变量名
  const variablePattern = /\$\{([^}]*)\}/g;
  let match;
  const variables = [];

  // 执行全局匹配以找到所有变量
  while ((match = variablePattern.exec(templateString)) !== null) {
    // match[1] 存储的是匹配到的变量名（不包括${和}）
    variables.push(match[1].trim());
  }
  return variables;
}

export function explian(that, value, pageId) {
  // 找出${XXX}并从store中获取值替换value中的值
  if (typeof value === "string") {
    const extractedVars = extractTemplateVariables(value);
    extractedVars.forEach((key) => {
      let gValue = that.$store.state.easyLoaderData[pageId][key];
      value = value.replaceAll("${" + key + "}", gValue);
    });
  }
  return value;
}

export class OperationLog {
  constructor(size, data) {
    this.size = size;
    this.logs = [JSON.parse(JSON.stringify(data))];
    this.index = 0;
  }

  log(data) {
    let log = JSON.parse(JSON.stringify(data));
    this.index++;
    this.logs.splice(this.index, this.logs.length - this.index, log);
    // 不准超量
    if (this.logs.length > this.size) {
      this.logs.splice(0, 1);
      this.index--;
    }
  }

  next() {
    if (this.logs) {
      this.index++;
      if (this.index >= this.logs.length) {
        this.index = this.logs.length - 1;
      }
      return this.logs[this.index];
    }
  }

  back() {
    if (this.logs) {
      this.index--;
      if (this.index < 0) {
        this.index = 0;
      }
      if (this.index >= this.logs.length) {
        this.index = this.logs.length - 1;
      }
      return this.logs[this.index];
    }
  }

  getIndex() {
    return this.index;
  }

  getLength() {
    return this.logs.length;
  }
}

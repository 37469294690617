import layout from "./layout/config";
import info from "./info/config";
import form from "./form/config";
import model from "./model/config";
import echarts from "./echarts/config";

const configs = [
    {
        name: "布局",
        components: layout,
        img: require('@/pages/easy_ui/images/box.png')
    },
    {
        name: "表单",
        components: form,
        img: require('@/pages/easy_ui/images/form.png')
    },
    {
        name: "信息",
        components: info,
        img: require('@/pages/easy_ui/images/info.png')
    },
    {
        name: "图表",
        components: echarts,
        img: require('@/pages/easy_ui/images/options.png')
    },
    {
        name: "高级",
        components: model,
        img: require('@/pages/easy_ui/images/options.png')
    },
];

export default configs;

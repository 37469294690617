<template>
  <div class="base-box start-box">
    <ai-drawer :isShowCode='isShowCode' @insert-code="ImplantData" @close-code="closecode"></ai-drawer>
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" node-key="id" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i v-show="data.code" class="el-icon-document ellipsis dev-tree-max-width"
              @click.stop="onDocumentClick(data)">&nbsp;{{ node.label
              }}</i>
            <div v-show="!data.code">
              <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
                }}</i>
              <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
                @change="data.isEdit = false; onDirectoryRename()"></el-input>
            </div>
          </span>
          <span>
            <i v-show="!data.code" class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i v-show="!data.code" class="el-icon-document-add" @click.stop="addRow(data.id)">&nbsp;</i>
            <i v-show="data.code" class="el-icon-delete" @click.stop="remove(node, data)"></i>
            <i v-show="!data.code" class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box " v-show="isShowEdit">
      <el-row :gutter="14">
        <el-col :span="6">
          <el-input size="mini" @blur="onBlurNameInput" placeholder="请输入名称" v-model="func.name">
            <template slot="prepend"><span class="form_tag_style">名称</span></template>
          </el-input>
        </el-col>
        <el-col :span="12">
          <el-input size="mini" placeholder="请输入描述" v-model="func.description">
            <template slot="prepend"><span class="form_tag_style">描述</span></template>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="mini" type="primary" @click="add" style="width: 100%;">保存</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" style="width: 100%;" @click="showai" size="mini">AI写代码</el-button>
        </el-col>
      </el-row>
      <div class="base-box margin-box-top border-box" style="height: 100%;" @keydown="handleKeydown">
        <editor ref="editor" v-model="func.code" @init="editorInit" lang="python" theme="dracula" width="100%"></editor>
      </div>
      <div>
        <div style="margin-top: 8px; margin-bottom: 8px;">
          <el-button size="mini" type="primary" @click="runTest" style="width: 100%;">运行</el-button>
        </div>
        <el-input size="mini" type="textarea" :rows="7" disabled v-model="runText">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI, getTestAPI } from "@/utils/apis/dev/viewFunction"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import { getUrlArgs } from "@/tools/url"
import aiDrawer from '@/pages/components/aiDrawer.vue';
import { getTemplate } from "@/utils/apis/dev/templateApi"



export default {
  name: 'functionView',
  components: {
    aiDrawer,
    editor: require('vue2-ace-editor')
  },
  data() {
    return {
      treedatas: [],
      directory: {},
      func: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      projectId: null,
      projectName: null,
      isShowEdit: false,
      isShowCode: false,
      runText: '',
      editorOptions: {
        tabSize: 4, // tab默认大小
        showPrintMargin: false, // 去除编辑器里的竖线
        fontSize: 14, // 字体大小
        highlightActiveLine: true, // 高亮配置
        enableSnippets: true,
        enableLiveAutocompletion: true,
        enableBasicAutocompletion: true
      }
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...           
      require('brace/mode/python')    //language
      require('brace/theme/eclipse')
      require('brace/snippets/python') //snippet
      require('brace/snippets/text')
      this.editor = editor
      editor.setOptions(this.editorOptions)
    },
    handleKeydown(event) {
      if (event.altKey && event.key === '=') {
        this.editorOptions.fontSize = this.editorOptions.fontSize + 1
      }
      if (event.altKey && event.key === '-') {
        this.editorOptions.fontSize = this.editorOptions.fontSize - 1
      }
      this.editor.setOptions(this.editorOptions)
    },
    // 弹窗
    showai() {
      this.isShowCode = true
    },
    ImplantData(result) {
      const editor = this.$refs.editor.editor;
      // 使用 Ace Editor 的 API 插入文本
      editor.insert('# AI生成代码块\n' + result);
      // this.func.code = this.func.code + '# AI生成代码块\n' +  result
      this.isShowCode = false
    },
    closecode() {
      this.isShowCode = false
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList(directory, parentNode) {
      getListAPI(directory.id).then(res => {
        if (res.data.code === 200) {
          let childrens = res.data.data
          childrens.forEach(element => {
            this.$refs.tree.remove(element)
            this.$refs.tree.append(element, parentNode)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'func').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.func).then(res => {
        if (res.data.code === 200) {
          this.func = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        // this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data, node) {
      // 如果是目录
      if (!data.code) {
        this.isShowEdit = false
        this.directory = data
        this.getList(data, node)
      }
    },
    onDocumentClick(data) {
      this.$refs.tree.setCurrentKey(data.id)
      this.func = data
      this.tempCode = this.func.code
      this.isShowEdit = true
    },
    addRow(pid) {
      this.func = {
        id: null,
        code: '',
        name: '',
        description: '',
        pid: pid,
        projectId: this.projectId,
      }
      this.isShowEdit = true
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'func',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },
    onBlurNameInput() {
      if (this.func.code) {
        return
      }
      const params = {
        type: 'func',
        key: 'demo'
      }
      getTemplate(params).then(res => {
        if (res.data.code === 200) {
          this.func.code = res.data.data.replaceAll('{name}', this.func.name)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    runTest() {
      getTestAPI(this.func.id).then(res => {
        this.runText = res.data.data
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>



</style>
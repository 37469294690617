<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <el-input-number size="mini" :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle"
                    v-model="value" @change="change" @blur="blur" @focus="focus"></el-input-number>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'
import * as Global from '../../EasyGlobal'

export default {
    name: "EasyNumber",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        change() {
            if (this.data.attribute.data.events.changeEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.changeEvent.codeJs))
            }
        },
        blur() {
            if (this.data.attribute.data.events.blurEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.blurEvent.codeJs))
            }
        },
        focus() {
            if (this.data.attribute.data.events.focusEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.focusEvent.codeJs))
            }
        },
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

export function formatDate(date) {
  function padZero(num) {
    return num < 10 ? "0" + num : num;
  }

  var year = date.getFullYear();
  var month = padZero(date.getMonth() + 1);
  var day = padZero(date.getDate());
  var hours = padZero(date.getHours());
  var minutes = padZero(date.getMinutes());
  var seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function moveToFront(array, index) {
  const element = array.splice(index, 1)[0];
  array.unshift(element);
  return array;
}

<template>
    <div :style="boxStyle">
        <el-select size="mini" style="width: 100%;" v-model="value" @change="update" placeholder="请选择接口" allow-create
            filterable default-first-option clearable>
            <el-option-group v-for="(group, index) in apis" :key="index" :label="group.label">
                <el-option v-for="(item, key) in group.options" :key="key" :label="item.label" :value="'/api/app/' + projectId + '/' +item.value">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                </el-option>
            </el-option-group>
        </el-select>
        <el-button v-show="showRun" size="mini" @click="run" type="text">发送请求</el-button>
        <el-input v-show="showRun" size="mini" type="textarea" :rows="6" placeholder="请输入内容" v-model="apiData"
            :readonly="true">
        </el-input>
    </div>
</template>

<script>
import { getSelectListAPI } from "@/utils/apis/dev/viewApi"
import * as EasyUiUlits from '../ulits'
import axios from 'axios'

export default {
    name: 'ApiSelectEditer',
    props: { data: {}, boxStyle: Object, updateAttributeData: Function, pKey: String, showRun: Boolean },
    components: {},
    watch: {
        "data": {
            handler: function (data) {
                if (typeof data === 'object') {
                    this.value = data.value
                } else {
                    this.value = data
                }
            },
            deep: true
        }
    },
    data() {
        return {
            value: '',
            apis: [],
            projectId: '',
            apiData: '',
            request: null
        }
    },
    beforeMount() {
        this.value = this.data.value
        this.projectId = EasyUiUlits.getProjectId()
    },
    mounted() {
        this.getApisSelect()
        // 初始化API
        this.request = axios.create({
            baseURL: process.env.BASE_API,
            timeout: 60 * 1000
        })
    },
    methods: {
        update() {
            this.updateAttributeData(this.pKey, this.value)
        },
        getApisSelect() {
            getSelectListAPI(this.projectId).then(res => {
                if (res.data.code === 200) {
                    this.apis = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getApi() {
            var key = this.pKey.replaceAll(".url", '')
            var keys = key.split(".")
            var data = this.$store.state.easyLoaderSetting[EasyUiUlits.getEasyPageId()]
            for (let index = 0; index < keys.length; index++) {
                const element = keys[index];
                data = data[element]
            }
            return data
        },
        run() {
            var api = this.getApi()
            var axiosRequest = {}
            if (!('Authorization' in Object.keys(api.headers))) {
                api.headers.Authorization = localStorage.getItem('token')
            }
            if (api.body) {
                axiosRequest = { method: api.method, url: api.url, headers: api.headers, data: api.body }
            } else {
                axiosRequest = { method: api.method, url: api.url, headers: api.headers, params: api.params }
            }
            this.request(axiosRequest).then(res => {
                // this.$store.commit('setLoaderData', { id: this.pageId, key: key, value: this.dataFilter(res.data.data, api.filtercodeJs) });
                this.apiData = JSON.stringify(res.data.data, null, 4)
            }).catch(err => {
                this.apiData = err
            })
        }
    }
}
</script>

<style scoped></style>

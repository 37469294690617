import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/template";
let model_url = "/api/models/code/";

// get请求
export function getTemplate(params) {
  const tempUrl = url + "/" + params.type + "/" + params.key;
  return http.get(tempUrl, {});
}

// get请求
export function getPageApiTemplate(params) {
  const tempUrl = model_url + "page";
  return http.get(tempUrl, params);
}

// get请求
export function getAddApiTemplate(params) {
  const tempUrl = model_url + "add";
  return http.get(tempUrl, params);
}

// get请求
export function getDeleteApiTemplate(params) {
  const tempUrl = model_url + "delete";
  return http.get(tempUrl, params);
}

// get请求
export function getUpdateApiTemplate(params) {
  const tempUrl = model_url + "update";
  return http.get(tempUrl, params);
}

<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <div :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle"
                    :type="data.attribute.type"></div>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits';
import * as echarts from 'echarts';

export default {
    name: "EasyLineChart",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
            myChart: null,
            option: null,
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.createOption(this.value)
                this.myChart.resize()
                this.myChart.setOption(this.option);
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.option = JSON.parse(JSON.stringify(this.data.attribute.data.echart))
                this.value = data.attribute.data.value
                this.createOption(this.value)
                this.myChart.resize()
                this.myChart.setOption(this.option);
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                    this.createOption(this.value)
                    this.myChart.resize()
                    this.myChart.setOption(this.option);
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
        this.option = JSON.parse(JSON.stringify(this.data.attribute.data.echart))
        this.createOption(this.value)
    },
    mounted() {
        this.myChart = echarts.init(document.getElementById(this.data.id));
        // 绘制图表
        this.myChart.setOption(this.option);
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        createOption(val) {
            this.$set(this.option.xAxis, "data", val.type)
            for (let key in val.data) {
                for (let index = 0; index < this.option.series.length; index++) {
                    if (this.option.series[index].name == key) {
                        this.$set(this.option.series[index], "data", val.data[key])
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

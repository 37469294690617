<template>
	<div>
		<el-drawer size='35%' :visible.sync="showdrawer" :with-header="false" @close="closedrawer">
			<!-- 		<div class="center-container" style="position: relative; padding: 30px; height: 70%;">
		  <img style="height: 25px; position: absolute; top: 4.5%; left: 8%;" src="@/assets/images/system/assistant.png">
		  <div style="margin-left: 40px;">
			<span>：{{ result }}</span>
		  </div>
		</div>
		<div class="message">
			<el-input  type="textarea" size='mini' :rows="10"
			placeholder="输入消息..."  @keydown.native.enter.prevent="CodeInsert" v-model="keywords"></el-input>
		</div>
		<div style="margin-top: 24px;text-align:center">
		  <el-button size="mini" type="primary" @click="CodeInsert">发送</el-button>
		</div> -->
			<div class="draggable-resizable-window" style="padding:15px">
				<div class="between-box">
					<div style="margin-bottom: 8px; font-size: 18px;color:white;">AI助手</div>
					<i class="el-icon-close"></i>
				</div>
				<div style="max-height: 70vh; overflow: auto;">
					<div>
						<div style="height: calc(100vh - 50%); overflow-y: auto; color: aliceblue;">
							<div v-for="(message, index) in messages" :key="index"
								style="border: solid #a4a5a7 1px; padding: 8px; margin-bottom: 10px;">
								<div class="between-box"
									style="width: 100%; margin-bottom: 8px; padding-bottom: 4px; border-bottom: solid #a4a5a7 1px;">
									<el-avatar size="small" v-if="message.role === 'ai'"
										:src="message.avatar"></el-avatar>
									<el-avatar size="small" v-if="message.role === 'user'">我</el-avatar>
									{{ message.time.toLocaleString('zh-CN') }}
									<span v-if="message.ai === 'no'" style="font-size: 12px;"></span>
									<span v-if="message.ai === 'yes'" style="font-size: 12px;"
										@click="ImplantData(index)">植入代码</span>
								</div>
								<div v-if="message.ai === 'no'" v-html="message.html"
									style="overflow: none;font-size: 14px;"></div>
								<editor v-else style="overflow: none" v-model="message.html" @init="editorInit"
									lang="python" theme="dracula" width="100%" height="10%"></editor>

							</div>
							<div v-show="loading" v-loading="loading" element-loading-text="拼命加载中"
								element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)"
								style="height: 40px; margin-bottom: 10px;"></div>
						</div>

						<el-input size="mini" type="textarea" :rows="5" @keyup.enter.native="sendMessageToAI"
							v-model="keywords">
						</el-input>
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { getCodeAPI } from "@/utils/apis/dev/viewFunction"
// import MarkdownIt from 'markdown-it';  //只能转html  无法去除markdown格式

const aiAvatar = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
const userAvatar = "@/assets/images/loginPage/header.jpeg"

export default {
	name: 'aiDrawer',
	components: {
		editor: require('vue2-ace-editor')
	},
	props: {
		isShowCode: {
			type: Boolean,
		},
	},
	data() {
		return {
			projectId: '',
			messages: [{ role: "ai", html: "有什么可以帮到您？", avatar: aiAvatar, time: new Date(), ai: "no" }],
			socket: null,
			loading: false,
			showdrawer: false,
			msg: '',
			aiBoxStyle: {
				position: 'absolute',
				left: '35vw',
				top: '5vh',
			},
			flag: true,
			editorOptions: {
				tabSize: 4, // tab默认大小
				showPrintMargin: false, // 去除编辑器里的竖线
				fontSize: 14, // 字体大小
				enableSnippets: true,
				maxLines: Infinity,
				enableLiveAutocompletion: true,
				enableBasicAutocompletion: true,
				highlightActiveLine: false, // 禁用当前行的高亮
				showGutter: false, // 隐藏行号边距
				//readOnly: true,              // 设置为只读模式
			},
			keywords: '',
			result: '',
			runText: '',
		}
	},
	watch: {
		isShowCode(newVal) {
			this.showdrawer = newVal; // 当 prop 变化时更新本地状态
		},
	},
	mounted() {

	},
	methods: {
		editorInit: function (editor) {
			require('brace/ext/language_tools') //language extension prerequsite...           
			require('brace/mode/python')    //language
			require('brace/theme/eclipse')
			require('brace/snippets/python') //snippet
			require('brace/snippets/text')
			this.editor = editor
			editor.setOptions(this.editorOptions)
		},
		// 请求并展示结果
		CodeInsert() {
			let _this = this;
			// 参数 keyword  结果 result
			getCodeAPI(_this.msg).then(res => {
				_this.messages.push({ role: "ai", html: res.data.data, avatar: aiAvatar, time: new Date(), ai: "yes" })
				_this.result = res.data.data;
				_this.loading = false
			}).catch(err => {
				console.log(err)
				_this.loading = false
			})
		},
		ImplantData(index) {
			this.$emit('insert-code', this.messages[index].html);
		},
		closedrawer() {
			this.$emit('close-code');
		},
		sendMessageToAI() {
			this.msg = this.keywords
			this.loading = true
			this.messages.push({ role: "user", html: this.keywords, avatar: userAvatar, time: new Date(), ai: "no" });
			this.CodeInsert();
			this.keywords = ""
		},
	},
}
</script>

<style scoped>
/deep/ .el-drawer {
	border-radius: 15px 0 0 0;
	box-shadow: -15px 0 10px rgb(0 0 0 / 20%);
	background-color: #242b38;
}

.center-container {
	padding: 0 30px;
	height: 65%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.message {
	padding: 0 20px;
}

.message>>>.el-textarea__inner {
	border-radius: 15px;
	color: #1a1818;
	background-color: #f7f7f7;
}
</style>
<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">角色管理</div>
        <el-dialog title="详情" :visible.sync="infoDialogVisible" width="50%" center>
            <el-descriptions :title="role.name" size="mini" :column="2" style="color: white;">
                <el-descriptions-item label="状态">
                    <el-select size="mini" v-model="role.status" placeholder="状态" style="width: 100%;" disabled>
                        <el-option v-for="item in statusType" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="有效期">{{ role.validityDate }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ role.createTime }}</el-descriptions-item>
                <el-descriptions-item label="修改时间">{{ role.updateTime }}</el-descriptions-item>
                <el-descriptions-item label="创建人">{{ role.createUser }}</el-descriptions-item>
                <el-descriptions-item label="修改人">{{ role.updateUser }}</el-descriptions-item>
            </el-descriptions>
        </el-dialog>
        <el-dialog title="成员管理" :visible.sync="userDialogVisible" width="80%" center>
            <div class="center-box">
                <el-transfer v-model="selectUsers" :data="users" :props="{ key: 'id', label: 'username' }"
                    :titles="['未选中', '选中']">
                    <span class="between-box" slot-scope="{ option }">
                        <span>
                            {{ option.username }}
                        </span>
                        <el-date-picker v-model="option.validityDate" type="datetime" size="mini" style="width: 124px;"
                            format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="有效期"
                            align="right" :picker-options="pickerOptions">
                        </el-date-picker>
                        <el-select size="mini" style="width: 80px;" v-model="option.status" placeholder="状态">
                            <el-option v-for="item in statusType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </span>
                </el-transfer>
            </div>
            <div class="center-box">
                <el-button type="primary" @click="updateRoleUser" style="width: 140px; margin-top: 24px;">保
                    存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="菜单管理" :visible.sync="menuDialogVisible" width="70%" center>
            <div class="center-box">
                <div class="auth-box">
                    <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText">
                    </el-input>
                    <el-tree class="filter-tree" size="mini" show-checkbox node-key="id" :data="menus"
                        :props="{ children: 'children', label: 'name' }" default-expand-all
                        :filter-node-method="filterNode" :default-checked-keys="selectMenus" ref="menuTree">
                    </el-tree>
                </div>
            </div>
            <div class="center-box">
                <el-button type="primary" @click="updateRoleMenu" style="width: 140px; margin-top: 24px;">保
                    存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="权限管理" :visible.sync="authDialogVisible" width="70%" center>
            <div class="center-box">
                <div class="auth-box">
                    <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText">
                    </el-input>

                    <el-tree class="filter-tree" size="mini" show-checkbox node-key="id" :data="auths" :height="100"
                        :props="{ children: 'children', label: 'name' }" default-expand-all
                        :filter-node-method="filterNode" :default-checked-keys="selectAuths" ref="authTree">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span v-show="!data.children">
                                <span>有效期：</span>
                                <el-date-picker v-model="data.validityDate" type="datetime" size="mini"
                                    format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间"
                                    align="right" :picker-options="pickerOptions">
                                </el-date-picker>
                            </span>
                            <span v-show="!data.children">
                                <span>状态：</span>
                                <el-select size="mini" v-model="data.status" placeholder="状态">
                                    <el-option v-for="item in statusType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div class="center-box">
                <el-button type="primary" @click="updateRoleAuth" style="width: 140px; margin-top: 24px;">更新</el-button>
            </div>
        </el-dialog>
        <div class="base-box content-box">
            <el-table
                :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                size="mini" :tree-props="{ children: 'children' }" row-key="index">

                <el-table-column type="index" label="序号" width="80">
                    <template slot="header" slot-scope="scope">
                        <el-button type="primary" size="mini" @click="addRow">添加</el-button>
                        {{ scope.row }}
                    </template>
                </el-table-column>

                <el-table-column min-width="150" label="角色">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" label="状态">
                    <template slot-scope="scope">
                        <el-select size="mini" v-model="scope.row.status" placeholder="状态" style="width: 100%;">
                            <el-option v-for="item in statusType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column width="250" label="有效期">
                    <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.validityDate" type="datetime" size="mini"
                            format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间"
                            align="right" :picker-options="pickerOptions">
                        </el-date-picker>
                    </template>
                </el-table-column>

                <el-table-column min-width="200" label="说明">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" prop="createTime" label="创建时间">
                </el-table-column>

                <el-table-column width="150" prop="updateTime" label="更新时间">
                </el-table-column>

                <el-table-column width="410" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="add(scope.row)">提交
                        </el-button>
                        <el-button v-if="scope.row.id" size="mini" type="primary" @click="getUserList(scope.row)">成员
                        </el-button>
                        <el-button v-if="scope.row.id" size="mini" type="warning" @click="getMenuList(scope.row)">菜单
                        </el-button>
                        <el-button v-if="scope.row.id" size="mini" type="warning" @click="getAuthList(scope.row)">权限
                        </el-button>
                        <el-button v-if="scope.row.id" v-show="scope.row.dataLevel !== 'system'" size="mini"
                            type="danger" @click="handleDelete(scope.row)">删除
                        </el-button>
                        <el-button v-if="scope.row.id" @click="handleClick(scope.row)" type="info" size="mini">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI } from "@/utils/apis/system/roleApi"
import * as roleUserApi from "@/utils/apis/system/roleUserApi"
import * as roleMenuApi from "@/utils/apis/system/roleMenuApi"
import * as roleAuthApi from "@/utils/apis/system/roleAuthApi"
import * as userApi from "@/utils/apis/user/userApi"
import * as menuApi from "@/utils/apis/system/menuApi"
import * as authApi from "@/utils/apis/system/authApi"

export default {
    name: 'rolePage',
    data() {
        return {
            tableData: [],
            search: '',
            role: {},
            selectUsers: [],
            users: [],
            selectMenus: [],
            menus: [],
            selectAuths: [],
            auths: [],
            filterText: '',
            infoDialogVisible: false,
            userDialogVisible: false,
            menuDialogVisible: false,
            authDialogVisible: false,
            statusType: [
                {
                    value: 'enable',
                    label: '启用'
                }, {
                    value: 'disable',
                    label: '禁用'
                },
            ],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '一周',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + (3600 * 1000 * 24 * 7));
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一月',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + (3600 * 1000 * 24 * 30));
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一年',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + (3600 * 1000 * 24 * 30 * 12));
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一百年',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + (3600 * 1000 * 24 * 30 * 12 * 100));
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(row) {
            this.role = row
            this.infoDialogVisible = true
            this.getSelectList(row.id)
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteAPI(row).then(res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.getList()
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        addRow() {
            this.tableData.push({
                id: null,
                name: null,
                description: null,
                status: 'disabled',
                validityDate: null,
            })
        },
        add(row) {
            addAPI(row).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
                this.getList()
            }).catch(err => {
                console.log(err)
            })
        },
        getList() {
            getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getUserList(role) {
            userApi.getTransferListAPI(role.id).then(res => {
                if (res.data.code === 200) {
                    this.users = res.data.data
                    this.openUserRole(role)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getMenuList(role) {
            menuApi.getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.menus = this.formatMenus(res.data.data)
                    this.openMenuRole(role)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getAuthList(role) {
            authApi.treeAPI(role.id).then(res => {
                if (res.data.code === 200) {
                    this.auths = res.data.data
                    this.openAuthRole(role)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        formatMenus(menus) {
            const res = []
            menus.forEach(element => {
                if (element.pindex === '/') {
                    res.push(element)
                }
            });

            for (let index = 0; index < res.length; index++) {
                res[index].children = []
                menus.forEach(element => {
                    if (element.pindex === res[index].index && element.index !== res[index].pindex) {
                        res[index].children.push(element)
                    }
                });
            }
            return res
        },
        openUserRole(role) {
            this.role = role
            this.selectUsers = []
            this.userDialogVisible = true
            roleUserApi.getListAPI({ id: role.id }).then(res => {
                if (res.data.code === 200) {
                    res.data.data.forEach(element => {
                        this.selectUsers.push(element.id)
                    });
                }
            }).catch(err => {
                console.log(err)
            })
        },
        openMenuRole(role) {
            this.role = role
            this.selectMenus = []
            this.menuDialogVisible = true
            roleMenuApi.getListAPI({ id: role.id }).then(res => {
                if (res.data.code === 200) {
                    res.data.data.forEach(element => {
                        var node = this.$refs.menuTree.getNode(element.menuId)
                        if (node && node.childNodes.length === 0) {
                            this.selectMenus.push(element.menuId)
                        }
                    });
                    this.$refs.menuTree.setCheckedKeys(this.selectMenus)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        openAuthRole(role) {
            this.role = role
            this.selectAuths = []
            this.authDialogVisible = true
            roleAuthApi.getListAPI({ id: role.id }).then(res => {
                if (res.data.code === 200) {
                    res.data.data.forEach(element => {
                        this.selectAuths.push(element.authId)
                    });
                    this.$refs.authTree.setCheckedKeys(this.selectAuths)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        updateRoleUser() {
            let roleUsers = []
            this.selectUsers.forEach(userId => {
                this.users.forEach(user => {
                    if (user.id === userId) {
                        var u = {
                            userId: user.id,
                            roleId: this.role.id,
                            status: user.status,
                            validityDate: user.validityDate
                        }
                        roleUsers.push(u)
                    }
                });
            });
            roleUserApi.addAPI({ users: roleUsers, role: this.role }).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                    this.userDialogVisible = false
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },
        updateRoleMenu() {
            var parentIds = []
            let roleMenus = []
            this.selectMenus = this.$refs.menuTree.getCheckedKeys(true)
            this.selectMenus.forEach(menuId => {
                var pid = this.$refs.menuTree.getNode(menuId).parent.data.id
                if (!parentIds.includes(pid)) {
                    var prm = {
                        menuId: pid,
                        roleId: this.role.id,
                    }
                    roleMenus.push(prm)
                    parentIds.push(pid)
                }
                var rm = {
                    menuId: menuId,
                    roleId: this.role.id,
                }
                roleMenus.push(rm)
            });
            roleMenuApi.addAPI({ menus: roleMenus, role: this.role }).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                    this.menuDialogVisible = false
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },
        updateRoleAuth() {
            let roleAuths = []
            this.selectAuths = this.$refs.authTree.getCheckedNodes(true)
            this.selectAuths.forEach(auth => {
                var ra = {
                    authId: auth.id,
                    roleId: this.role.id,
                    status: auth.status,
                    validityDate: auth.validityDate
                }
                roleAuths.push(ra)
            });
            roleAuthApi.addAPI({ auths: roleAuths, role: this.role }).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                    this.authDialogVisible = false
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        }
    },
    watch: {
        filterText(val) {
            this.$refs.authTree.filter(val);
            this.$refs.menuTree.filter(val);
        }
    },
}
</script>

<style scoped>
.content-box {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.auth-box {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    height: calc(100vh - 400px);
    display: block;
    overflow-y: scroll;
}

::v-deep .el-transfer-panel {
    width: 400px;
    padding: 4px;
}

::v-deep .el-checkbox {
    margin-right: 0px;
}
</style>
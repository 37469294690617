<template>
    <div :style="boxStyle">
        <el-select size="mini" style="width: 100%;" v-model="select_model" clearable placeholder="请选择模型"
            @change="onSelectModel">
            <el-option-group v-for="group in models" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                    <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                </el-option>
            </el-option-group>
        </el-select>

        <div style="margin-top: 8px;">
            <el-descriptions :column="1" size="mini" border>
                <el-descriptions-item v-for="(item, index) in value" :key="index"
                    :label="item.label"><span>{{ item.column }}</span></el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../ulits'
import * as ModelApi from "@/utils/apis/dev/modelApi"

export default {
    name: 'InputEditer',
    props: { data: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {},
    watch: {
    },
    data() {
        return {
            value: '',
            select_model: '',
            models: [],
            projectId: ''
        }
    },
    beforeMount() {
        this.projectId = EasyUiUlits.getProjectId()
        this.value = this.data
        this.getModelsSelectList()
    },
    methods: {
        getModelsSelectList() {
            ModelApi.getSelectListAPI(this.projectId).then(res => {
                if (res.data.code === 200) {
                    this.models = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        onSelectModel() {
            ModelApi.getAPI({ id: this.select_model }).then(res => {
                if (res.data.code === 200) {
                    this.value = res.data.data.data
                    this.update()
                }
            }).catch(err => {
                console.log(err)
            })
        },
        update() {
            this.updateAttributeData(this.pKey, this.value)
        },
    }
}
</script>

<style scoped></style>

<template>
  <div class="base-box margin-box page-right">
    <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">JS解释器</div>
    <div style="border:1px solid black; border-radius: 5px;">
      <codemirror v-model="code" :options="cmOptions" @blur="onCodeChangeMethod">
      </codemirror>
    </div>
    <span>运行结果:</span>
    <el-input size="mini" type="textarea" :rows="7" :readonly="true" v-model="res">
    </el-input>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'

import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/idea.css'

export default {
  name: 'jsPage',
  components: {
    codemirror
  },
  data() {
    return {
      code: '',
      res: '',
      cmOptions: {
        indentUnit: 4,
        indentWithTabs: false,
        styleActiveLine: true,
        theme: 'idea',
        lineNumbers: true,
        line: true,
        mode: 'text/javascript', // 选择代码语言，我这里选的sql
      }
    }
  },
  methods: {
    onCodeChangeMethod() {
      this.res = eval(this.code)

    }
  },
}
</script>

<style scoped>
::v-deep .CodeMirror {
  height: calc(100vh - 260px);
}
</style>
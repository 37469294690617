import Flow from "./Flow/config";
import Grid from "./Grid/config";
import Tabs from "./Tabs/config";
import Flex from "./Flex/config";
import Center from "./Center/config";
import Absolute from "./Absolute/config";
import Horizontal from "./Horizontal/config";

const components = [Flow, Grid, Tabs, Flex, Center, Horizontal, Absolute];

export default components;

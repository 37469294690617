import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

import basePage from "@/pages/basePage.vue";
import loginPage from "@/pages/loginPage.vue";
import apiDocPage from "@/pages/apiDocPage.vue";
import EasyEdit from "@/pages/easy_ui/EasyEdit";
import EasyWindow from "@/pages/easy_ui/EasyWindow";
import testPage from "@/pages/testPage.vue";

import toolRoutes from "./tool/toolRouter";
import systemRouter from "./system/systemRouter";
import apiRouter from "./dev/apiRouter";

const routes = new VueRouter({
  routes: [
    {
      path: "/",
      name: "index",
      redirect: "/dev/project",
      component: basePage,
    },
    {
      path: "/login",
      name: "login",
      component: loginPage,
    },
    {
      path: "/test",
      name: "test",
      component: testPage,
    },
    {
      path: "/dev/doc",
      name: "apiDoc",
      component: apiDocPage,
    },
    {
      path: "/tool",
      name: "tool",
      redirect: "/tool/ai",
      component: basePage,
      children: toolRoutes,
    },
    {
      path: "/system",
      name: "system",
      redirect: "/system/menu",
      component: basePage,
      children: systemRouter,
    },
    {
      path: "/dev",
      name: "dev",
      redirect: "/dev/project",
      component: basePage,
      children: apiRouter,
    },
    {
      path: "/easyui/edit",
      name: "easyuiEdit",
      component: EasyEdit,
    },
    {
      path: "/easyui/app",
      name: "EasyApp",
      component: EasyWindow,
    },
    {
      path: "/easyui",
      name: "easyui",
      component: basePage,
      children: [
        {
          path: "window",
          name: "easyWindow",
          component: EasyWindow,
        },
      ],
    },
  ],
});

routes.beforeEach((to, _, next) => {
  if (to.path === "/login") {
    next();
  } else {
    const token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next("/login");
    }
  }
});

export default routes;

<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <!-- JSON编辑器 -->
        <el-dialog title="JSON编辑器" :visible.sync="visible" width="70%">
            <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 350px)"
                v-model="value">
            </editor>
            <div class="center-box" style="margin-top: 16px;">
                <el-button @click="saveJsonData()" type="primary" size="mini" style="width: 110px;">保存</el-button>
            </div>
        </el-dialog>
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <el-button :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle" size="mini"
                    @click="onJsonClick" type="text">编辑
                </el-button>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'
import * as Global from '../../EasyGlobal'

export default {
    name: "EasyJson",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    components: { editor: require("vue2-ace-editor") },
    data() {
        return {
            value: "",
            visible: false,
            pageId: null,
            editorOptions: {
                tabSize: 4, // tab默认大小
                showPrintMargin: false, // 去除编辑器里的竖线
                fontSize: 14, // 字体大小
                highlightActiveLine: true, // 高亮配置
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            }
        };
    },
    watch: {
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        onJsonClick() {
            if (typeof this.value !== 'string') {
                this.value = JSON.stringify(this.value, null, 4)
            }
            this.visible = !this.visible
            this.edit()
        },
        saveJsonData() {
            this.visible = !this.visible
            this.comnitValue(this.value)
            this.change()
        },
        editorInit: function (editor) {
            require('brace/ext/language_tools') //language extension prerequsite...           
            require('brace/mode/json')    //language
            require('brace/theme/eclipse')
            require('brace/snippets/json') //snippet
            require('brace/snippets/text')
            this.editor = editor
            editor.setOptions(this.editorOptions)
        },
        handleKeydown(event) {
            if (event.altKey && event.key === '=') {
                this.editorOptions.fontSize = this.editorOptions.fontSize + 1
            }
            if (event.altKey && event.key === '-') {
                this.editorOptions.fontSize = this.editorOptions.fontSize - 1
            }
            this.editor.setOptions(this.editorOptions)
        },
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        change() {
            if (this.data.attribute.data.events.changeEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.changeEvent.codeJs))
            }
        },
        edit() {
            if (this.data.attribute.data.events.editEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.editEvent.codeJs))
            }
        },
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

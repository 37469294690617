<template>
    <div :style="boxStyle">
        <div class="start-box" v-for="(key, index) in Object.keys(attribute)" :key="index"
            style="margin-top: 8px;width: 100%;">
            <!-- 标签 -->
            <div v-if="!noShowKeys.includes(key)"
                style="color: aliceblue;font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explian(key) }}
            </div>
            <!-- 类型 -->
            <el-select v-if="key === 'type'" v-isEmpty size="mini" style="width: 100%;" v-model="type"
                @change="updateAttributeData(pKey + '.' + key, type)" clearable placeholder="请选择请求类型">
                <el-option v-for="item in apis" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                </el-option>
            </el-select>
            <!-- URL -->
            <div v-else-if="key == 'url'" style="width: 100%;">
                <InputEditer :pKey="pKey + '.' + key" :updateAttributeData="updateAttributeData"
                    v-if="!noShowKeys.includes(key)" :data="{ value: attribute[key] }" :boxStyle="{}"></InputEditer>
            </div>
            <!-- 头部和内容处理 -->
            <div v-else-if="key == 'ajaxcodeJs'" style="width: 100%;">
                <InputEditer :pKey="pKey + '.' + key" :updateAttributeData="updateAttributeData"
                    v-if="!noShowKeys.includes(key)" :data="{ value: attribute[key] }" :boxStyle="{}"></InputEditer>
            </div>
            <!-- 头部和内容 -->
            <div v-else style="width: 100%;">
                <DictEditer :pKey="pKey + '.' + key" :updateAttributeData="updateAttributeData"
                    v-if="!noShowKeys.includes(key)" :data="{ value: attribute[key] }" :boxStyle="{}"></DictEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../../../attributeMap';
import InputEditer from '../../../editer/InputEditer';
import DictEditer from './DictEditer';

export default {
    name: 'ObjectEditer',
    props: { data: Object, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
        InputEditer, DictEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {},
            type: 'post',
            apis: [
                { label: 'POST', value: 'post' },
                { label: 'GET', value: 'get' },
                { label: 'DELETE', value: 'delete' },
                { label: 'PUT', value: 'put' },
            ]
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
        this.type = this.attribute.type
    },
    methods: {
        explian(key) {
            var str = attributeMap[key]
            if(str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    color: aliceblue;
    background: #06518c;
    width: 100%;
}
</style>

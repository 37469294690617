import { render, staticRenderFns } from "./componentPage.vue?vue&type=template&id=a18ff906&scoped=true"
import script from "./componentPage.vue?vue&type=script&lang=js"
export * from "./componentPage.vue?vue&type=script&lang=js"
import style0 from "./componentPage.vue?vue&type=style&index=0&id=a18ff906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a18ff906",
  null
  
)

export default component.exports
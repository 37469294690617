import { createEasyPageId as createId } from "../../../ulits";
import { getEnvCodeTemp } from "../../EasyGlobal";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "信息",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "信息",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyInfo",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: "请输入文本内容",
        type: "info",
        // 组件标签
        label: {
          isShow: false,
          value: "标签",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "9px",
            left: "0px",
            position: "relative",
          },
        },
        // 组件样式
        cStyle: {
          color: "rgba(0, 0, 0, 1)",
          fontSize: "14px",
        },
        events: {
          clickEvent: {
            enable: false,
            codeJs: getEnvCodeTemp(),
          },
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(0, 0, 0, 1)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <div>
                    <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
                    </el-input>
                    <el-tree :ref="data.attribute.key" size="mini" node-key="id" :style="data.attribute.data.cStyle"
                        :data="value" :props="data.attribute.data.defaultProps" default-expand-all @check-change="onCheckChange"
                        :show-checkbox="data.attribute.data.showCheckbox" :filter-node-method="filterNode">
                        <div slot-scope="{ node, nodeData }" :style="data.attribute.rowStyle" class="between-box">
                            <div :style="data.attribute.titleStyle" @click.stop="onTitle(node, nodeData)">
                                {{ node.label }}
                            </div>
                            <div class="end-box">
                                <i v-for="(icon, index) in data.attribute.data.treeIcons" :key="index" v-show="icon.isShow"
                                    :style="icon.iconStyle" :class="icon.icon"
                                    @click.stop="onIcon(icon, node, nodeData)">
                                    <span :style="icon.titleStyle">{{ icon.title }}</span>
                                </i>
                            </div>
                        </div>
                    </el-tree>
                </div>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'
import * as Global from '../../EasyGlobal'

export default {
    name: "EasyTree",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: [],
            pageId: null,
            filterText: "",
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        }, 
        filterText(val) {
            this.$refs[this.data.attribute.key].filter(val);
        }
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        onDelete(node, data) {
            console.log("树组件点击了删除", node, data)
            if (this.data.attribute.data.events.deleteEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.deleteEvent.codeJs))
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data[this.data.attribute.data.defaultProps.label].indexOf(value) !== -1;
        },
        onTitle(node) {
            console.log("树组件点击了标题", node)
            if (this.data.attribute.data.events.titleEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.titleEvent.codeJs))
            }
        },
        onIcon(icon, node) {
            console.log("树组件点击了icon", icon, node)
            if (icon.event.enable) {
                eval(Global.getFunCode(icon.event.codeJs))
            }
        },
        onCheckChange(node) {
            console.log("树组件点击了多选框", node)
            if (this.data.attribute.data.events.checkChangeEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.checkChangeEvent.codeJs))
            }
        }
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

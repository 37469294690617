<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">菜单管理</div>
        <el-dialog title="提示" :visible.sync="infoDialogVisible" width="50%" center>
            <el-descriptions :title="menu.name" size="mini" :column="2" style="color: white;">
                <el-descriptions-item label="父菜单">
                    <el-select size="mini" v-model="menu.pindex" placeholder="父菜单" style="width: 80%;" disabled>
                        <el-option v-for="item in tableData" :key="item.index" :label="item.name" :value="item.index">
                        </el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="Path">{{ menu.index }}</el-descriptions-item>
                <el-descriptions-item label="图标">{{ menu.icon }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ menu.createTime }}</el-descriptions-item>
                <el-descriptions-item label="修改时间">{{ menu.updateTime }}</el-descriptions-item>
                <el-descriptions-item label="创建人">{{ menu.createmenu }}</el-descriptions-item>
                <el-descriptions-item label="修改人">{{ menu.updatemenu }}</el-descriptions-item>
            </el-descriptions>
        </el-dialog>
        <div class="base-box content-box">
            <el-table
                :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                size="mini" :tree-props="{ children: 'children' }" row-key="index">

                <el-table-column width="50" label="展开">
                </el-table-column>

                <el-table-column min-width="300" label="菜单名">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column min-width="300" label="父菜单">
                    <template slot-scope="scope">
                        <el-select size="mini" v-model="scope.row.pindex" placeholder="父菜单" style="width: 100%;">
                            <el-option v-for="item in tableData" :key="item.index" :label="item.name"
                                :value="item.index">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column width="150" label="Path">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.index" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" label="图标">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.icon" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" prop="createTime" label="创建时间">
                </el-table-column>

                <el-table-column width="150" prop="updateTime" label="更新时间">
                </el-table-column>

                <el-table-column width="220" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="add(scope.row)">提交
                        </el-button>
                        <el-button v-if="scope.row.id" v-show="scope.row.dataLevel !== 'system'" size="mini"
                            type="danger" @click="handleDelete(scope.row)">删除
                        </el-button>
                        <el-button v-if="scope.row.id" @click="handleClick(scope.row)" type="mini" size="mini">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI } from "@/utils/apis/system/menuApi"

export default {
    name: 'menuPage',
    data() {
        return {
            tableData: [],
            search: '',
            menu: {},
            infoDialogVisible: false,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(row) {
            this.menu = row
            this.infoDialogVisible = true
            this.getSelectList(row.id)
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteAPI(row).then(res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.getList()
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        addRow() {
            this.tableData.push({
                id: null,
                index: null,
                pindex: null,
                name: null,
                icon: null,
                createTime: null,
                updateTime: null,
                createmenu: null,
                updatemenu: null
            })
        },
        add(row) {
            addAPI(row).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
                this.getList()
            }).catch(err => {
                console.log(err)
            })
        },
        getList() {
            getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.tableData = this.formatMenus(res.data.data)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        formatMenus(menus) {
            const res = []
            menus.forEach(element => {
                if (element.pindex === '/') {
                    res.push(element)
                }
            });

            for (let index = 0; index < res.length; index++) {
                res[index].children = []
                menus.forEach(element => {
                    if (element.pindex === res[index].index && element.index !== res[index].pindex) {
                        res[index].children.push(element)
                    }
                });
            }
            return res
        }
    }
}
</script>

<style scoped>
.content-box {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}
</style>
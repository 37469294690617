<template>
    <div :style="data.designStyle" @mouseout.stop="evt => onComponentSelect(data.id, evt)"
        @mouseover.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)"
            :ref="data.attribute.id" :id="data.id">
            <!-- JSON编辑器 -->
            <el-dialog title="JSON编辑器" :visible.sync="jsonEdit.visible" width="70%">
                <editor @init="editorInit" lang="json" theme="dracula" width="100%" height="calc(100vh - 350px)"
                    v-model="jsonEdit.data">
                </editor>
                <div class="center-box" style="margin-top: 16px;">
                    <el-button @click="saveJsonData()" type="primary" size="mini" style="width: 110px;">保存</el-button>
                </div>
            </el-dialog>
            <!-- 详情页 -->
            <el-dialog title="详情" :visible.sync="dealvisible" width="70%">
                <el-descriptions size="mini" :column="3" border>
                    <el-descriptions-item v-for="(item, index) in data.attribute.options.model" :key="index"
                        :label="item.label">
                        <div v-if="item.alias">
                            <el-select v-if="item.selectList" size="mini" v-model="deal[item.alias]" filterable
                                placeholder="请选择" disabled>
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <el-select v-else-if="item.map" size="mini" v-model="deal[item.alias]" filterable
                                placeholder="请选择" disabled>
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <div v-else>
                                {{ deal[item.alias] }}
                            </div>
                        </div>
                        <div v-else>
                            <el-select v-if="item.selectList" size="mini" v-model="deal[item.column]" filterable
                                placeholder="请选择" disabled>
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <el-select v-else-if="item.map" size="mini" v-model="deal[item.column]" filterable
                                placeholder="请选择" disabled>
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <div v-else>
                                {{ deal[item.column] }}
                            </div>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </el-dialog>
            <!-- 查询条件 -->
            <el-divider content-position="left" v-if="params.display.showSearch">查询条件</el-divider>
            <div>
                <el-descriptions v-if="params.display.showSearch" size="mini" :column="4" border>
                    <el-descriptions-item v-for="(item, index) in params.pageParams.search" :key="index"
                        :label="item.label">
                        <div style="margin-right: 10px">
                            <!-- 根据字段类型选用表单组件 -->
                            <el-select v-if="item.selectList" size="mini" v-model="item.value" filterable
                                placeholder="请选择">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <el-select v-else-if="item.map" size="mini" v-model="item.value" filterable
                                placeholder="请选择">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <div v-else>
                                <el-input size="mini" v-if="['key', 'text', 'string', 'json'].includes(item.type)"
                                    clearable v-model="item.value"></el-input>
                                <el-input-number size="mini" v-if="item.type === 'number'"
                                    v-model="item.value"></el-input-number>
                                <el-date-picker size="mini" v-if="item.type === 'datetime'" v-model="item.value"
                                    type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <div v-if="item.type === 'percentage'" class="start-box" style="margin-left: 10px">
                                    <el-slider input-size="mini" v-model="item.value" style="width: 100px"></el-slider>
                                    <div style="margin-left: 16px; margin-top: 10px">
                                        {{ item.value }}%
                                    </div>
                                </div>
                                <el-switch size="mini" v-if="item.type === 'bool'" v-model="item.value"
                                    active-color="#13ce66" inactive-color="#ff4949">
                                </el-switch>
                                <el-input-number size="mini" v-if="item.type === 'float'" v-model="item.value"
                                    :precision="2" :step="0.1"></el-input-number>
                            </div>

                        </div>
                    </el-descriptions-item>
                </el-descriptions>
                <div class="between-box" style="width: 100%; margin-top: 10px;">
                    <div class="between-box" style="width: 240px">
                        <el-button v-if="params.display.showOperate.allDelete" type="danger" @click="deleteSelection()"
                            size="mini" style="height: 30px; width: 110px" icon="el-icon-delete">批量删除</el-button>
                        <el-button v-if="params.display.showOperate.add" @click="addRowClick()" size="mini"
                            style="height: 30px; width: 110px" icon="el-icon-circle-plus-outline"
                            type="success">添加</el-button>
                    </div>
                    <div v-if="params.display.showSearch" class="between-box" style="width: 240px">
                        <el-button type="warning" @click="resetSearch()" size="mini"
                            style="height: 30px; width: 110px">重
                            置</el-button>
                        <el-button icon="el-icon-search" @click="getData()" size="mini"
                            style="height: 30px; width: 110px">查
                            询</el-button>
                    </div>
                </div>
            </div>
            <!-- 询查结果 -->
            <el-divider content-position="left">询查结果</el-divider>
            <el-table :data="tableData" style="width: 100%" size="mini" height="calc(100vh - 310px)"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50"> </el-table-column>
                <el-table-column v-for="(item, index) in data.attribute.options.model" :key="index" :label="item.label"
                    :width="item.width">
                    <template slot-scope="scope">
                        <!-- 根据字段类型选用表单组件 -->
                        <!-- 有别名 -->
                        <div v-if="item.alias">
                            <el-select v-if="item.selectList" size="mini" v-model="scope.row[item.alias]" filterable
                                placeholder="请选择" :disabled="params.display.readonly">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <el-select v-else-if="item.map" size="mini" v-model="scope.row[item.alias]" filterable
                                placeholder="请选择" :disabled="params.display.readonly">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <div v-else>
                                <p v-if="item.type === 'key'">{{ scope.row[item.alias] }}</p>
                                <el-input size="mini" v-if="item.type === 'string'" v-model="scope.row[item.alias]"
                                    clearable :readonly="params.display.readonly"></el-input>
                                <el-input-number size="mini" v-if="item.type === 'number'"
                                    v-model="scope.row[item.alias]"
                                    :disabled="params.display.readonly"></el-input-number>
                                <el-date-picker size="mini" v-if="item.type === 'datetime'"
                                    v-model="scope.row[item.alias]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择日期时间" :readonly="params.display.readonly">
                                </el-date-picker>
                                <el-input size="mini" v-if="item.type === 'text'" type="textarea" :rows="2"
                                    v-model="scope.row[item.alias]" :readonly="params.display.readonly">
                                </el-input>
                                <div v-if="item.type === 'percentage'" class="start-box" style="margin-left: 8px">
                                    <el-slider size="mini" v-model="scope.row[item.alias]"
                                        style="width: 100px"></el-slider>
                                    <div style="margin-left: 16px; width: 100px; margin-top: 8px;">
                                        {{ scope.row[item.alias] }}%
                                    </div>
                                </div>
                                <el-switch size="mini" v-if="item.type === 'bool'" v-model="scope.row[item.alias]"
                                    active-color="#13ce66" inactive-color="#ff4949" :disabled="params.display.readonly">
                                </el-switch>
                                <el-input-number size="mini" v-if="item.type === 'float'"
                                    v-model="scope.row[item.alias]" :precision="2" :step="0.1"
                                    :disabled="params.display.readonly"></el-input-number>
                                <el-button v-if="item.type === 'json'" @click="onJsonClick(scope.$index, item.alias)"
                                    size="mini" type="text" :disabled="params.display.readonly">编辑
                                </el-button>
                            </div>
                        </div>
                        <!-- 没别名 -->
                        <div v-else>
                            <el-select v-if="item.selectList" size="mini" v-model="scope.row[item.column]" filterable
                                placeholder="请选择" :disabled="params.display.readonly">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <el-select v-else-if="item.map" size="mini" v-model="scope.row[item.column]" filterable
                                placeholder="请选择" :disabled="params.display.readonly">
                                <el-option v-for="obj in selectOptions[item.column]" :key="obj.value" :label="obj.label"
                                    :value="obj.value">
                                </el-option>
                            </el-select>
                            <div v-else>
                                <p v-if="item.type === 'key'">{{ scope.row[item.column] }}</p>
                                <el-input size="mini" v-if="item.type === 'string'" v-model="scope.row[item.column]"
                                    clearable :readonly="params.display.readonly"></el-input>
                                <el-input-number size="mini" v-if="item.type === 'number'"
                                    v-model="scope.row[item.column]"
                                    :disabled="params.display.readonly"></el-input-number>
                                <el-date-picker size="mini" v-if="item.type === 'datetime'"
                                    v-model="scope.row[item.column]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择日期时间" :readonly="params.display.readonly">
                                </el-date-picker>
                                <el-input size="mini" v-if="item.type === 'text'" type="textarea" :rows="2"
                                    v-model="scope.row[item.column]" :readonly="params.display.readonly">
                                </el-input>
                                <div v-if="item.type === 'percentage'" class="start-box" style="margin-left: 8px">
                                    <el-slider size="mini" v-model="scope.row[item.column]"
                                        style="width: 100px"></el-slider>
                                    <div style="margin-left: 16px; width: 100px; margin-top: 8px;">
                                        {{ scope.row[item.column] }}%
                                    </div>
                                </div>
                                <el-switch size="mini" v-if="item.type === 'bool'" v-model="scope.row[item.column]"
                                    active-color="#13ce66" inactive-color="#ff4949" :disabled="params.display.readonly">
                                </el-switch>
                                <el-input-number size="mini" v-if="item.type === 'float'"
                                    v-model="scope.row[item.column]" :precision="2" :step="0.1"
                                    :disabled="params.display.readonly"></el-input-number>
                                <el-button v-if="item.type === 'json'" @click="onJsonClick(scope.$index, item.column)"
                                    size="mini" type="text" :disabled="params.display.readonly">编辑
                                </el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="params.display.showOperate.update || params.display.showOperate.info || params.display.showOperate.delete"
                    width="120" fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="params.display.showOperate.update" @click="saveClick(scope.row)" size="mini"
                            type="text">保存
                        </el-button>
                        <el-button v-if="params.display.showOperate.info" @click="editClick(scope.row)" size="mini"
                            type="text">详情
                        </el-button>
                        <el-button v-if="params.display.showOperate.delete" @click="deleteClick(scope.row)" size="mini"
                            type="text">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="center-box" style="margin-top: 16px">
                <el-pagination :current-page="params.pageParams.page" :page-sizes="[10, 20, 50, 100]"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :page-size="params.pageParams.count" layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as easyUiAPI from "@/utils/apis/easy_ui/api";
import { v4 as uuidv4 } from "uuid";
import * as EasyUiUlits from '../../../ulits';
import * as mapAPi from "@/utils/apis/dev/mapApi";
import http from "@/utils/http";

export default {
    name: "EasyPage",
    components: { editor: require("vue2-ace-editor") },
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    watch: {
        "data": {
            handler: function (data) {
                // 初始化组件参数
                data = JSON.parse(JSON.stringify(data));
                this.params = data.attribute.options
                this.params.pageParams.search = []
                // 初始化查询数据
                data.attribute.options.model.forEach((model) => {
                    // 过滤掉不用查询的字段
                    if (model.isSearch) {
                        this.params.pageParams.search.push(model);
                    }
                    if (model.type === "key") {
                        this.key = model.column;
                    }
                });
            },
            deep: true
        }
    },
    data() {
        return {
            isDesign: true,
            key: "id",
            total: 0,
            tableData: [],
            params: {
                pageParams: {
                    page: 1,
                    count: 10,
                    order_by: [],
                    search: [],
                },
            },
            jsonEdit: {
                data: "{}",
                visible: false,
                index: 0,
                column: "id",
            },
            dealvisible: false,
            deal: {},
            newId: null,
            selection: [],
            projectUrl: '',
            selectOptions: {},
            editorOptions: {
                tabSize: 4, // tab默认大小
                showPrintMargin: false, // 去除编辑器里的竖线
                fontSize: 14, // 字体大小
                highlightActiveLine: true, // 高亮配置
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            }
        };
    },
    beforeMount() {
        this.projectUrl = "/api/app/" + EasyUiUlits.getProjectId() + "/"
        this.isDesign = EasyUiUlits.isDesign()
        // 初始化组件参数
        this.params = JSON.parse(JSON.stringify(this.data.attribute.options));
        this.params.pageParams.search = []
        // 初始化查询数据
        this.data.attribute.options.model.forEach((model) => {
            // 过滤掉不用查询的字段
            if (model.isSearch) {
                this.params.pageParams.search.push(model);
            }
            if (model.type === "key") {
                this.key = model.column;
            }
        });
    },
    mounted() {
        this.getMapList()
        this.getSelectList()
        // 获取始化数据
        if (!EasyUiUlits.isDesign()) {
            this.getData();
        }
    },
    methods: {
        getData() {
            // 清除空值
            let params = { ...this.params.pageParams };
            let search = [];
            for (let index = 0; index < params.search.length; index++) {
                if (params.search[index].value) {
                    search.push(params.search[index]);
                }
            }
            //  json化search
            params.search = JSON.stringify(search);
            // TODO 清除search为空的key
            easyUiAPI
                .pageAPI(this.params.api.pageUrl, params)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.tableData = res.data.data.data;
                        this.total = res.data.data.total;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {
                    this.$message.error("网络出错！");
                });
        },
        editClick(row) {
            this.deal = row
            this.dealvisible = !this.dealvisible
        },
        deleteClick(row) {
            row = this.toTrueModel(row)
            let search = {};
            search[this.key] = row[this.key];
            easyUiAPI
                .deleteAPI(this.params.api.deleteUrl, {
                    search: JSON.stringify(search),
                })
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                        this.getData();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {
                    this.$message.error("网络出错！");
                });
        },
        saveClick(row) {
            row = this.toTrueModel(row)
            if (row[this.key] !== this.newId) {
                let search = {};
                search[this.key] = row[this.key];
                let data = {
                    data: row,
                    search: search,
                };
                easyUiAPI
                    .updateAPI(this.params.api.updateUrl, data)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success(res.data.msg);
                            this.getData();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.$message.error("网络出错！");
                    });
            } else {
                let data = {
                    data: [row]
                }
                easyUiAPI
                    .addAPI(this.params.api.addUrl, data)
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.newId = null
                            this.$message.success(res.data.msg);
                            this.getData();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.$message.error("网络出错！");
                    });
            }
        },
        addRowClick() {
            this.newId = uuidv4()
            var newRow = {}
            newRow[this.key] = this.newId
            this.tableData.push(newRow)
        },
        handleSizeChange(count) {
            this.params.pageParams.count = count;
            this.getData();
        },
        handleCurrentChange(page) {
            this.params.pageParams.page = page;
            this.getData();
        },
        cellStyle() {
            return { background: "#242b38", borderBottom: "1px solid #EBEEF534" };
        },
        headerCellStyle() {
            return { background: "#242b38" };
        },
        editorInit: function (editor) {
            require('brace/ext/language_tools') //language extension prerequsite...           
            require('brace/mode/json')    //language
            require('brace/theme/eclipse')
            require('brace/snippets/json') //snippet
            require('brace/snippets/text')
            this.editor = editor
            editor.setOptions(this.editorOptions)
        },
        handleKeydown(event) {
            if (event.altKey && event.key === '=') {
                this.editorOptions.fontSize = this.editorOptions.fontSize + 1
            }
            if (event.altKey && event.key === '-') {
                this.editorOptions.fontSize = this.editorOptions.fontSize - 1
            }
            this.editor.setOptions(this.editorOptions)
        },
        onJsonClick(index, column) {
            this.jsonEdit.visible = !this.jsonEdit.visible;
            this.jsonEdit.index = index;
            this.jsonEdit.column = column;
            if (this.tableData[index][column]) {
                this.jsonEdit.data = this.tableData[index][column];
            } else {
                this.tableData[index][column] = {};
                this.jsonEdit.data = "{}";
            }
        },
        saveJsonData() {
            this.jsonEdit.visible = !this.jsonEdit.visible;
            this.tableData[this.jsonEdit.index][this.jsonEdit.column] = JSON.parse(
                this.jsonEdit.data
            );
            this.jsonEdit.data = "{}";
        },
        resetSearch() {
            for (
                let index = 0;
                index < this.params.pageParams.search.length;
                index++
            ) {
                this.params.pageParams.search[index].value = null;
            }
        },
        handleSelectionChange(selection) {
            this.selection = selection
        },
        deleteSelection() {
            let search = []
            for (let index = 0; index < this.selection.length; index++) {
                var item = this.selection[index];
                item = this.toTrueModel(item)
                var data = {
                    column: this.key,
                    logical: "OR",
                    operator: "=",
                    value: item[this.key],
                }
                if (index === 0) {
                    data.logical = 'AND'
                }
                search.push(data)
            }
            if (search) {
                easyUiAPI
                    .deleteAPI(this.params.api.deleteUrl, {
                        search: JSON.stringify(search),
                    })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success(res.data.msg);
                            this.getData();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                    .catch(() => {
                        this.$message.error("网络出错！");
                    });
            }
        },
        getMapList() {
            this.data.attribute.options.model.forEach((model) => {
                if (model.map) {
                    mapAPi.getKeyAPI({ projectId: EasyUiUlits.getProjectId(), key: model.map }).then((res) => {
                        if (res.data.code === 200) {
                            this.selectOptions[model.column] = res.data.data
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                        .catch(() => {
                            this.$message.error("网络出错！");
                        });
                }
            })
        },
        getSelectList() {
            this.data.attribute.options.model.forEach((model) => {
                if (model.selectList) {
                    var url = this.projectUrl + model.selectList
                    var params = {
                        count: 99999,
                        page: 1,
                    }
                    http.get(url, params).then((res) => {
                        if (res.data.code === 200) {
                            this.selectOptions[model.column] = res.data.data.data
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                        .catch(() => {
                            this.$message.error("网络出错！");
                        });
                }
            })
        },
        toTrueModel(obj) {
            var trueModel = {}
            var keys = Object.keys(obj)
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                trueModel[key] = obj[key]
                for (let j = 0; j < this.data.attribute.options.model.length; j++) {
                    const model = this.data.attribute.options.model[j];
                    if (key === model.alias) {
                        trueModel[model.column] = obj[key]
                        break
                    }
                }
            }
            keys = Object.keys(trueModel)
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                for (let j = 0; j < this.data.attribute.options.model.length; j++) {
                    const model = this.data.attribute.options.model[j];
                    if (key === model.alias) {
                        delete trueModel[key]
                    }
                }
            }
            return trueModel
        }
    },
};
</script>

<style scoped></style>

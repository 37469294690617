<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">系统日志</div>
        <div class="base-box between-box" style="margin-bottom: 14px;">
            <!-- topic -->
            <el-input size="mini" type="text" placeholder="" v-model="select_topic" style="width: 30%;">
                <template slot="prepend"><span class="form_tag_style">请求ID</span></template>
            </el-input>

            <!-- 方法 -->
            <el-select size="mini" style="width: 30%; margin-left: 14px;" v-model="select_method" clearable
                placeholder="方法">
                <el-option v-for="item in method_list" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                </el-option>
            </el-select>

            <!-- 客户端IP -->
            <el-input size="mini" type="text" placeholder="" v-model="select_remote_addr"
                style="width: 30%;margin-left: 14px;">
                <template slot="prepend"><span class="form_tag_style">客户端IP</span></template>
            </el-input>

            <el-button type="primary" @click="getList" style="width: 10%; margin-left: 14px;" size="mini">查询</el-button>
        </div>
        <div class="base-box between-box" style="margin-bottom: 14px;">
            <!-- username -->
            <el-input size="mini" type="text" placeholder="" v-model="select_username" style="width: 30%;">
                <template slot="prepend"><span class="form_tag_style">用户名称</span></template>
            </el-input>

            <!-- stage -->
            <el-input size="mini" type="text" placeholder="" v-model="select_stage"
                style="width: 30%;margin-left: 14px;">
                <template slot="prepend"><span class="form_tag_style">日志标识</span></template>
            </el-input>


            <!-- 日志时间 -->
            <el-date-picker size="mini" v-model="tmp_datatime" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" @change="setDatatime" end-placeholder="结束日期"
                style="width: 30%; margin-left: 14px;">
            </el-date-picker>

            <!-- 最大条数 -->
            <el-input-number size="mini" v-model="select_limit" :min="50" :max="10000" label="最大条数"
                style="width: 10%;margin-left: 14px;"></el-input-number>

        </div>
        <div class="base-box between-box" style="margin-bottom: 14px;">
            <!-- 日志内容 -->
            <el-input size="mini" type="text" placeholder="" v-model="select_content" style="width: 100%;">
                <template slot="prepend"><span class="form_tag_style">日志内容</span></template>
            </el-input>
        </div>
        <div class="base-box content-box">
            <el-table
                :data="tableData.filter(data => !search || data.log_content.toLowerCase().includes(search.toLowerCase()))"
                size="mini" style="width: 100%;">
                <el-table-column type="index" label="序号" width="80">
                </el-table-column>

                <el-table-column width="150" prop="topic" label="请求ID">
                </el-table-column>

                <el-table-column width="150" prop="stage" label="日志标识">
                </el-table-column>

                <el-table-column width="150" prop="log_time" label="发生时间">
                </el-table-column>

                <el-table-column min-width="300" prop="log_content" label=" 日志内容">
                </el-table-column>

                <el-table-column min-width="200" prop="request_url" label="API">
                </el-table-column>

                <el-table-column width="120" prop="username" label="触发用户">
                </el-table-column>

                <el-table-column width="150" prop="request_remote_addr" label="用户IP">
                </el-table-column>

                <el-table-column width="200" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="mini">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog title="日志详情" :visible.sync="infoDialogVisible" width="50%" center>
            <el-descriptions size="mini" :column="1" border>
                <el-descriptions-item label="日志ID">
                    <div class="log_text">{{ log.id }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="日志时间">
                    <div class="log_text">{{ log.log_time }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="请求ID">
                    <div class="log_text">{{ log.topic }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="API">
                    <div class="log_text">{{ log.request_url }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="日志标识">
                    <div class="log_text">{{ log.stage }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="请求方法">
                    <div class="log_text">{{ log.request_method }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="请求参数">
                    <div class="log_text">{{ log.request_args }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="请求体">
                    <div class="log_text">{{ log.request_data }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="用户ID">
                    <div class="log_text">{{ log.user_id }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="触发用户">
                    <div class="log_text">{{ log.username }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="触发用户IP">
                    <div class="log_text">{{ log.request_remote_addr }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="日志内容">
                    <div class="log_text">{{ log.log_content }}</div>
                </el-descriptions-item>

            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import { getAppLogListAPI } from "@/utils/apis/system/logApi";

import { formatDate } from "@/utils/utils"

export default {
    name: 'sysLogsPage',
    data() {
        return {
            tmp_datatime: null,
            tableData: [],
            search: '',
            log: {},
            infoDialogVisible: false,
            select_project: 'global',
            select_remote_addr: null,
            select_method: null,
            select_username: null,
            select_topic: null,
            select_stage: null,
            select_content: null,
            select_start_time: null,
            select_end_time: null,
            select_offset: 'earliest',
            select_limit: 1000,
            method_list: [
                { label: 'POST', value: 'POST' },
                { label: 'GET', value: 'GET' },
                { label: 'PUT', value: 'PUT' },
                { label: 'DELETE', value: 'DELETE' }]
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(row) {
            this.log = row
            this.infoDialogVisible = true
        },
        setDatatime() {
            if (this.tmp_datatime) {
                this.select_start_time = formatDate(this.tmp_datatime[0])
                this.select_end_time = formatDate(this.tmp_datatime[1])
            }
        },
        getList() {
            if (this.select_project) {
                var data = {
                    request_remote_addr: this.select_remote_addr,
                    request_method: this.select_method,
                    username: this.select_username,
                    topic: this.select_topic,
                    stage: this.select_stage,
                    log_content: this.select_content,
                    start_time: this.select_start_time,
                    end_time: this.select_end_time,
                    limit: this.select_limit,
                }
                getAppLogListAPI(this.select_project, data).then(res => {
                    if (res.data.code === 200) {
                        this.tableData = res.data.data
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.$message.error("项目不能为空！")
            }
        },

    }
}
</script>

<style scoped>
.content-box {
    height: calc(100vh - 320px);
    overflow: auto;
}

.log_text {
    font-size: 14px;
}

.custom-table {
    max-width: 100%;
}
</style>
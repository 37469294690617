<template>
  <div class="base-box">
    <!-- 头部 -->
    <div class="between-box page-top">
      <!-- 平台标题 -->
      <div style="font-size: 22px; font-weight: bold; color: white; width: 500px;">
        <!-- 缩进 -->
        <div class="start-box" style="margin-top: 12px;">
          <div style="margin-top: 2px; margin-right: 8px">
            <i :class="closeIcon" style="font-size: 26px; color: white;" @click="closeMenu"></i>
          </div>
          <div style="">智能低代码能力开发平台</div>
        </div>
      </div>
      <div class="start-box" style="flex-direction: row-reverse;">
        <!-- 个人设置 -->
        <el-dropdown @command="onCommand" style="margin-top: 16px; margin-right: 20px;">
          <span style="font-size: 18px; color: white;">
            {{ user.username }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="info">修改信息</el-dropdown-item>
            <el-dropdown-item command="token">获取Token</el-dropdown-item>
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 头像 -->
        <img src="@/assets/images/loginPage/header.jpeg" alt=""
          style="height: 40px; width: 40px; border-radius: 50%; padding: 10px;">
      </div>
    </div>
    <!-- 中间区域 -->
    <div class="base-box start-box">
      <!-- 菜单区域 -->
      <div class="base-box page-left">
        <!-- 菜单 -->
        <div class="base-box menu-box">
          <el-menu :collapse="isClose" @select="handleMenuSelect">
            <el-submenu v-for="(menu, index) in menus" :key="index" :index="menu.index">
              <template slot="title">
                <div class="menu-name">
                  <i :class="menu.icon"></i>
                  <span>{{ menu.name }}</span>
                </div>
              </template>
              <el-menu-item v-for="(item, key) in menu.items" :key="key" :index="item.index">
                <div class="menu-name">
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <!-- 内容区域 -->
      <router-view :key="$route.fullPath"></router-view>
    </div>

    <!-- info -->
    <el-dialog title="提示" :visible.sync="infoDialogVisible" width="30%" center>
      <el-form ref="form" :model="user" label-width="60px">
        <el-form-item label="用户">
          <el-input v-model="user.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="user.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮件">
          <el-input v-model="user.email"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="user.password" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUser">更 新</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Token" :visible.sync="tokenDialogVisible" width="50%" center>
      <span>{{ token }}</span>
    </el-dialog>

    <div style="position: absolute; right: 50px; bottom: 50px; z-index: 9999;" @click="isShowAiBox = !isShowAiBox">
      <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
    </div>

    <div v-show="isShowAiBox" class="draggable-resizable-window" v-boxDragResize :style="aiBoxStyle">
      <div class="between-box" id="move">
        <div style="margin-bottom: 8px; font-size: 18px;">AI助手</div>
        <i class="el-icon-close" @click="isShowAiBox = !isShowAiBox"></i>
      </div>
      <div style="max-height: 70vh; overflow: auto;">
        <AIPage></AIPage>
      </div>
    </div>

    <!-- 脚部 -->
    <div class="page-footer">
      <p>
        版权所有 <a href="tools.xing-z.com">tools.xing-z.com</a> &copy; 2024. 当前时间：<span>{{ new Date() }}</span>
      </p>
    </div>

  </div>
</template>

<script>
import { updateAPI, userInfoAPI } from "@/utils/apis/user/loginApi"
import * as userApi from "@/utils/apis/user/userApi"
import { getMyListAPI } from "@/utils/apis/system/menuApi"
import dragResize from '@/tools/drag-resize';
import AIPage from '@/pages/components/aiBox.vue';

export default {
  components: {
    AIPage
  },
  directives: {
    boxDragResize: {
      bind: dragResize.mounted
    }
  },
  data() {
    return {
      token: '',
      user: {},
      isClose: false,
      closeIcon: 'el-icon-s-fold',
      menus: [],
      search: '',
      infoDialogVisible: false,
      aiBoxStyle: {
        position: 'absolute',
        left: '35vw',
        top: '5vh',
      },
      isShowAiBox: false,
      tokenDialogVisible: false,
    }
  },
  methods: {
    handleMenuSelect(value) {
      if (value.indexOf('http') === 0) {
        window.open(value + localStorage.getItem('token'))
        return
      }
      this.$router.push(value)
    },
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    },
    onCommand(command) {
      if (command === 'info') {
        this.infoDialogVisible = true
        return
      }
      if (command === 'logout') {
        this.logout()
        return
      }
      if (command === 'token') {
        this.getToken()
        return
      }
    },
    updateUser() {
      updateAPI(this.user).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('token', res.data.token)
          this.getUserInfo()
          this.$message.success(res.data.msg)
          this.infoDialogVisible = false
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUserInfo() {
      userInfoAPI({}).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
        } else {
          this.$message.error(res.data.msg)
          localStorage.clear()
          this.$router.push('/login')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    closeMenu() {
      this.isClose = !this.isClose
      if (this.isClose) {
        this.closeIcon = 'el-icon-s-unfold'
        document.documentElement.style.setProperty("--page-left-width", "64px")
      } else {
        this.closeIcon = 'el-icon-s-fold'
        document.documentElement.style.setProperty("--page-left-width", "300px")
      }
    },
    getMenuList() {
      getMyListAPI().then(res => {
        if (res.data.code === 200) {
          this.menus = this.formatMenus(res.data.data)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    formatMenus(menus) {
      const res = []
      menus.forEach(element => {
        if (element.pindex === '/') {
          res.push(element)
        }
      });

      for (let index = 0; index < res.length; index++) {
        res[index].items = []
        menus.forEach(element => {
          if (element.pindex === res[index].index && element.index !== res[index].pindex) {
            res[index].items.push(element)
          }
        });
      }
      return res
    },
    getToken() {
      const that = this
      this.$prompt('请输入token有效期(小时)！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^-?\d+$/,
        inputErrorMessage: '请输入正确的整数！'
      }).then(({ value }) => {
        userApi.getToeknProjectAPI(value).then(res => {
          if (res.data.code === 200) {
            that.token = res.data.data
            that.tokenDialogVisible = !that.tokenDialogVisible
          }
        }).catch(() => {
          that.$message({
            type: 'error',
            message: "获取失败！"
          });
        })
      }).catch(() => { })
    }
  },
  mounted() {
    const user = localStorage.getItem('user')
    if (user) {
      this.user = JSON.parse(user)
    }
    this.getMenuList()
  },
}
</script>

<style scoped>
.menu-box {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
}

.draggable-resizable-window {
  border: solid #a4a5a7 1px;
  width: calc(100vw - 50%);
  padding: 14px;
  z-index: 99999;
}

.menu-name {
  min-width: auto;
  padding-right: 40px;
  font-size: 16px;
}
</style>
import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "图片",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "图片",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyImage",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value:
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        fit: "fill",
        // 组件标签
        label: {
          isShow: false,
          value: "文本输入框",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "9px",
            left: "0px",
            position: "relative",
          },
        },
        // 组件样式
        cStyle: {
          width: "100px",
          height: "100px",
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(0, 0, 0, 1)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

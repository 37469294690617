import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/component";
let list_url = "/api/components/";
let select_list_url = "/api/component/select/";
let run_test_url = "/api/component/test/";

// get请求
export function getListAPI(pid) {
  let tmpUrl = list_url + pid;
  return http.get(tmpUrl, {});
}

// get请求
export function getSelectListAPI(projectId) {
  return http.get(select_list_url + projectId, {});
}

// get请求
export function getTestAPI(id, params) {
  return http.post(run_test_url + id, params);
}

// get请求
export function getAPI(params) {
  return http.get(url, params);
}

// post请求
export function addAPI(params) {
  return http.post(url, params);
}

// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// delete 请求
export function deleteAPI(params) {
  return http.delete(url, params);
}

<template>
  <div class="base-box start-box">
    <ai-drawer :isShowCode='isShowCode' @insert-code="ImplantData" @close-code="closecode"></ai-drawer>

    <!-- 模板选择器 -->
    <el-dialog title="选择模板" :visible.sync="tempVisible" width="50%">
      <div>数据源：</div>
      <el-select size="mini" style="width: 100%;" v-model="select_source" clearable placeholder="请选择源据源">
        <el-option-group v-for="group in sources" :key="group.label" :label="group.label">
          <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-option-group>
      </el-select>

      <div style="margin-top: 8px;">模型：</div>
      <el-select size="mini" style="width: 100%;" v-model="select_model" clearable placeholder="请选择模型">
        <el-option-group v-for="group in models" :key="group.label" :label="group.label">
          <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <div class="center-box " style="margin-top: 16px;">
        <el-button type="primary" @click="setTemplate(select_type, select_source, select_model)" size="mini">确
          定</el-button>
      </div>
    </el-dialog>

    <!-- 编写文档 -->
    <el-dialog title="接口文档编写" :visible.sync="dialogVisible" width="70%">
      <div class="between-box" style="margin-bottom: 8px;">
        <el-select v-model="doc.method" placeholder="请选择方法" size="mini" style="margin-right: 8px;">
          <el-option v-for="item in methods" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input size="mini" placeholder="请输入内容" v-model="doc.path">
          <template slot="prepend">接口路径</template>
        </el-input>
      </div>
      <el-input size="mini" style="margin-bottom: 8px;" placeholder="请输入内容" v-model="doc.directory">
        <template slot="prepend">模块名称</template>
      </el-input>
      <el-input size="mini" style="margin-bottom: 8px;" placeholder="请输入内容" v-model="doc.name">
        <template slot="prepend">接口名称</template>
      </el-input>
      <div>接口说明:</div>
      <el-input size="mini" type="textarea" :rows="5" style="margin-bottom: 8px;" placeholder="请输入内容"
        v-model="doc.description">
      </el-input>
      <div>头部字段:</div>
      <el-table :data="doc.headers" size="mini" style="width: 100%; margin-bottom: 8px;">
        <el-table-column type="index" width="80">
          <template slot="header">
            <el-button type="primary" size="mini" @click=doc.headers.push({})>添加</el-button>
          </template>
        </el-table-column>
        <el-table-column label="字段名">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.key" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="示例">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.value" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否必须">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.need" clearable>
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <div>参数字段:</div>
      <el-table :data="doc.body" size="mini" style="width: 100%; margin-bottom: 8px;">
        <el-table-column type="index" width="80">
          <template slot="header">
            <el-button type="primary" size="mini" @click=doc.body.push({})>添加</el-button>
          </template>
        </el-table-column>
        <el-table-column label="字段名">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.key" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="示例">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.value" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否必须">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.need" clearable>
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <div>请求示例:</div>
      <div class="between-box" style="margin-bottom: 8px;">
        <el-select v-model="doc.demo.method" placeholder="请选择方法" size="mini" style="margin-right: 8px;">
          <el-option v-for="item in methods" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input size="mini" placeholder="请输入内容" v-model="doc.demo.path">
          <template slot="prepend">接口路径</template>
        </el-input>
      </div>
      <el-input size="mini" type="textarea" :rows="5" placeholder="请输入内容" style="margin-bottom: 8px;"
        v-model="doc.demo.body">
      </el-input>
      <div>示例结果:</div>
      <el-input size="mini" type="textarea" :rows="5" placeholder="请输入内容" v-model="doc.demoRes">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="saveDoc" size="mini">确 定</el-button>
      </span>
    </el-dialog>

    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" node-key="id" class="filter-tree" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i v-show="data.path"  class="el-icon-document ellipsis dev-tree-max-width"
              @click.stop="onDocumentClick(data)">&nbsp;{{ node.label
              }}</i>
            <div v-show="!data.path">
              <i  class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
                }}</i>
              <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
                @change="data.isEdit = false; onDirectoryRename()"></el-input>
            </div>
          </span>
          <span>
            <i v-show="!data.path" class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i v-show="!data.path" class="el-icon-document-add" @click.stop="addRow(data.id)">&nbsp;</i>
            <i v-show="data.path" class="el-icon-folder-checked" @click.stop="release(node, data)">&nbsp;</i>
            <i v-show="data.path" class="el-icon-delete" @click.stop="remove(node, data)"></i>
            <i v-show="!data.path" class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box " v-show="isShowEdit">
      <!-- 属性区 -->
      <div class="base-box">
        <el-row :gutter="14">
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入名称" v-model="api.name">
              <template slot="prepend"><span class="form_tag_style">名称</span></template>
            </el-input>
          </el-col>
          <el-col :span="15">
            <el-input size="mini" placeholder="请输入Path" v-model="api.path">
              <template slot="prepend"><span class="form_tag_style">路径</span></template>
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-select v-model="api.method" placeholder="请选择方法" size="mini">
              <el-option v-for="item in methods" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="14" class="margin-box-top">
          <el-col :span="18">
            <el-input size="mini" placeholder="请输入描述" v-model="api.description">
              <template slot="prepend"><span class="form_tag_style">描述</span></template>
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="test" style="width: 100%;" size="mini">调试</el-button>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="add" style="width: 100%;" size="mini">保存</el-button>
          </el-col>
        </el-row>
        <div class="margin-box-top">
          <el-row :gutter="14">
            <el-col :span="18">
              <el-select size="mini" style="width: 100%;" v-model="api.functions" clearable multiple
                placeholder="请选择要引入的全局函数">
                <el-option-group v-for="group in functions" :key="group.label" :label="group.label">
                  <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; font-size: 13px">{{ item.value }}</span>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" style="width: 100%;" @click="showai" size="mini">AI写代码</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="node-row margin-box-top">
          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="resetTemplate()">重置代码</div>
            </div>
          </div>

          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="onTemplate('page')">查询模板</div>
            </div>
          </div>

          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="onTemplate('add')">添加模板</div>
            </div>
          </div>

          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="onTemplate('delete')">删除模板</div>
            </div>
          </div>

          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="onTemplate('update')">修改模板</div>
            </div>
          </div>
          <div class="node-dome">
            <div class="node-dome-content">
              <el-select size="mini" style="width: 100%;" v-model="api.flow" clearable placeholder="选用业务模板"
                @change="selectFlow">
                <el-option-group v-for="group in flows" :key="group.label" :label="group.label">
                  <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
          </div>

          <div class="node-dome">
            <div class="node-dome-content">
              <div @click="setDoc">编写文档</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 代码区 -->
      <div class="base-box margin-box-top border-box" style="height: 100%;" @keydown="handleKeydown">
        <editor ref="editor" v-model="api.code" @init="editorInit" lang="python" theme="dracula" width="100%"></editor>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getListAPI,
  addAPI,
  releaseAPI,
  deleteAPI
} from "@/utils/apis/dev/viewApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import {
  getApiDocAPI,
  addDocAPI
} from "@/utils/apis/dev/projectApi"

import * as ModelApi from "@/utils/apis/dev/modelApi"
import * as SourceApi from "@/utils/apis/dev/sourceApi"
import * as FunctionApi from "@/utils/apis/dev/viewFunction"
import * as FlowApi from "@/utils/apis/dev/flowApi"
import aiDrawer from '@/pages/components/aiDrawer.vue';
import {
  getAddApiTemplate,
  getDeleteApiTemplate,
  getPageApiTemplate,
  getUpdateApiTemplate
} from "@/utils/apis/dev/templateApi"
import { getUrlArgs } from "@/tools/url"



export default {
  name: 'apiView',
  components: {
    aiDrawer,
    editor: require('vue2-ace-editor')
  },
  data() {
    return {
      treedatas: [],
      api: {},
      directory: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      methods: [{
        value: 'GET',
        label: 'GET'
      }, {
        value: 'POST',
        label: 'POST'
      }, {
        value: 'DELETE',
        label: 'DELETE'
      }, {
        value: 'PUT',
        label: 'PUT'
      }],
      template: '',
      tempCode: '',
      projectId: null,
      projectName: null,
      functions: [],
      models: [],
      select_model: '',
      sources: [],
      select_source: '',
      select_type: 'page',
      isShowCode: false,
      flows: [],
      isShowEdit: false,
      dialogVisible: false,
      tempVisible: false,
      doc: {
        name: '',
        path: '',
        method: 'GET',
        description: '',
        demoRes: '',
        demo: { path: '', method: 'GET', body: '' },
        headers: [],
        body: [],
        directory: '',
      },
      editor: null,
      editorOptions: {
        tabSize: 4, // tab默认大小
        showPrintMargin: false, // 去除编辑器里的竖线
        fontSize: 14, // 字体大小
        highlightActiveLine: true, // 高亮配置
        enableSnippets: true,
        enableLiveAutocompletion: true,
        enableBasicAutocompletion: true
      }
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId && urlParams.projectName) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
    this.getSelectList()
    this.getFlowSelectList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...           
      require('brace/mode/python')    //language
      require('brace/theme/eclipse')
      require('brace/snippets/python') //snippet
      require('brace/snippets/text')
      this.editor = editor
      editor.setOptions(this.editorOptions)
    },
    handleKeydown(event) {
      if (event.altKey && event.key === '=') {
        this.editorOptions.fontSize = this.editorOptions.fontSize + 1
      }
      if (event.altKey && event.key === '-') {
        this.editorOptions.fontSize = this.editorOptions.fontSize - 1
      }
      this.editor.setOptions(this.editorOptions)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getAPiList(directory, parentNode) {
      getListAPI(directory.id).then(res => {
        if (res.data.code === 200) {
          let childrens = res.data.data
          childrens.forEach(element => {
            this.$refs.tree.remove(element)
            this.$refs.tree.append(element, parentNode)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'api').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 弹窗
    showai() {
      this.isShowCode = true
    },
    ImplantData(result) {
      const editor = this.$refs.editor.editor;
      // 使用 Ace Editor 的 API 插入文本
      editor.insert('# AI生成代码块\n' + result);
      // this.func.code = this.func.code + '# AI生成代码块\n' +  result
      this.isShowCode = false
    },
    closecode() {
      this.isShowCode = false
    },
    getSelectList() {
      FunctionApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.functions = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
      ModelApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.models = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
      SourceApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.sources = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getFlowSelectList() {
      FlowApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.flows = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.api).then(res => {
        if (res.data.code === 200) {
          this.api = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        // this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data, node) {
      if (!data.path) {
        // 如果是目录
        this.isShowEdit = false
        this.directory = data
        this.getAPiList(data, node)
      }
    },
    onDocumentClick(data) {
      this.$refs.tree.setCurrentKey(data.id)
      this.api = data
      this.tempCode = this.api.code
      this.getSelectList()
      this.isShowEdit = true
      return false
    },
    addRow(pid) {
      this.api = {
        id: null,
        code: '# 可用环境变量 \n# request: 当前flask请求对象 \n# abort: 当前flask abort对象 \n# db: 可用数据库连接接池 \n# config: 全局配置 \n# current_user: 当前用户 \n# minio: minio对象存储 \n# project_id: 当前项目ID \nreturn 123',
        name: '',
        description: '',
        path: '',
        pid: pid,
        projectId: this.projectId,
        method: 'GET',
        functions: [],
        flow: null,
      }
      this.isShowEdit = true
      return false
    },
    onTemplate(type) {
      this.tempVisible = true
      this.select_type = type
    },
    setTemplate(type, source_id, model_id) {
      const params = {
        source_id: source_id,
        model_id: model_id
      }
      if (type === 'add') {
        getAddApiTemplate(params).then(res => {
          if (res.data.code === 200) {
            this.api.code = res.data.data
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (type === 'delete') {
        getDeleteApiTemplate(params).then(res => {
          if (res.data.code === 200) {
            this.api.code = res.data.data
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (type === 'update') {
        getUpdateApiTemplate(params).then(res => {
          if (res.data.code === 200) {
            this.api.code = res.data.data
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (type === 'page') {
        getPageApiTemplate(params).then(res => {
          if (res.data.code === 200) {
            this.api.code = res.data.data
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }
      this.tempVisible = false
    },
    resetTemplate() {
      this.api.code = this.tempCode
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'api',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },
    release(node, data) {
      releaseAPI(data.id).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    selectFlow() {
      if (this.api.flow) {
        FlowApi.getAPI({ id: this.api.flow }).then(res => {
          if (res.data.code === 200) {
            this.api.code = res.data.data.code + '\n\n' + 'return output[0]'
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.api.code = ''
      }
    },
    test() {
      let url = '/api/app/' + this.projectId + '/' + this.api.path
      window.open('/#/tool/urlTest?url=' + url + '&method=' + this.api.method)
    },
    setDoc() {
      getApiDocAPI({ apiId: this.api.id }).then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data)
          this.doc = res.data.data.data
        } else {
          this.doc.name = this.api.name
          this.doc.path = '/api/app/' + this.projectId + '/' + this.api.path
          this.doc.method = this.api.method
          this.doc.description = this.api.description
          this.doc.demoRes = ''
          this.doc.headers = [
            { 'key': 'Content-Type', 'value': 'application/json', 'need': '是' },
            { 'key': 'Authorization', 'value': 'XXX', 'need': '是' }]
          this.doc.body = []
          this.doc.demo.path = '/api/app/' + this.projectId + '/' + this.api.path
          this.doc.demo.method = this.api.method
          this.doc.demo.body = ''
          this.doc.directory = this.directory.name
        }
        this.dialogVisible = true
      }).catch(err => {
        console.log(err)
      })
    },
    saveDoc() {
      let apiDoc = {
        projectId: this.projectId,
        directoryId: this.directory.id,
        apiId: this.api.id,
        name: this.api.name,
        data: this.doc
      }
      addDocAPI(apiDoc).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>




.node-row {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.node-dome {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 30px;
  font-size: 14px;
  color: cornflowerblue;
}

.node-dome-content {
  margin: auto;
}
</style>
<template>
  <div class="base-box start-box">
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText" type="text">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" :data="treedatas" node-key="id" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i v-show="data.host" class="el-icon-document ellipsis dev-tree-max-width"
              @click.stop="onDocumentClick(data)">&nbsp;{{ node.label
              }}</i>
            <div v-show="!data.host">
              <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
                }}</i>
              <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
                @change="data.isEdit = false; onDirectoryRename()"></el-input>
            </div>
          </span>
          <span v-show="data.host" style="overflow: hidden; width: 80px;">
            <i class="el-icon-link">{{ data.host }}</i>
          </span>
          <span>
            <i v-show="!data.host" class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i v-show="!data.host" class="el-icon-document-add" @click.stop="addRow(data.id)">&nbsp;</i>
            <i v-show="data.host" class="el-icon-delete" @click.stop="remove(node, data)"></i>
            <i v-show="!data.host" class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box ">
      <div v-show="isShowEdit">
        <el-row :gutter="14">
          <el-col :span="6">
            <el-input disabled size="mini" placeholder="ID" v-model="source.id">
              <template slot="prepend"><span class="form_tag_style">ID</span></template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入名称" v-model="source.name">
              <template slot="prepend"><span class="form_tag_style">名称</span></template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入Host" v-model="source.host">
              <template slot="prepend"><span class="form_tag_style">Host</span></template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入Port" v-model="source.port">
              <template slot="prepend"><span class="form_tag_style">Port</span></template>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="14" class="margin-box-top">
          <el-col :span="6">
            <el-select size="mini" v-model="source.type" placeholder="据库类型" style="width: 100%;">
              <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入用户" v-model="source.user">
              <template slot="prepend"><span class="form_tag_style">用户</span></template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" show-password placeholder="请输入密码" v-model="source.password">
              <template slot="prepend"><span class="form_tag_style">密码</span></template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input size="mini" placeholder="请输入数据库" v-model="source.database">
              <template slot="prepend"><span class="form_tag_style">库</span></template>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="14" class="margin-box-top">
          <el-col :span="21">
            <el-input size="mini" placeholder="请输入描述" v-model="source.description">
              <template slot="prepend"><span class="form_tag_style">描述</span></template>
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-button size="mini" type="primary" @click="add" style="width: 100%;">保存</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI } from "@/utils/apis/dev/sourceApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'sourceView',
  components: {
  },
  data() {
    return {
      treedatas: [],
      directory: {},
      source: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      types: [{
        value: 'mysql',
        label: 'mysql'
      }, {
        value: 'postgresql',
        label: 'postgresql'
      }, {
        value: 'oracle',
        label: 'oracle'
      }, {
        value: 'mssql',
        label: 'mssql'
      }, {
        value: 'redis',
        label: 'redis'
      }],
      projectId: null,
      projectName: null,
      isShowEdit: false,
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList(directory, parentNode) {
      getListAPI(directory.id).then(res => {
        if (res.data.code === 200) {
          let childrens = res.data.data
          childrens.forEach(element => {
            this.$refs.tree.remove(element)
            this.$refs.tree.append(element, parentNode)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'source').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.source).then(res => {
        if (res.data.code === 200) {
          this.source = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        // this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data, node) {
      // 如果是目录
      if (!data.host) {
        this.isShowEdit = false
        this.directory = data
        this.getList(data, node)
      }
    },
    onDocumentClick(data) {
      this.$refs.tree.setCurrentKey(data.id)
      this.source = data
      this.tempCode = this.source.code
      this.isShowEdit = true
    },
    addRow(pid) {
      this.source = {
        id: null,
        type: 'mysql',
        name: '',
        host: 'localhost',
        port: 3306,
        password: '',
        database: '',
        pid: pid,
        projectId: this.projectId,
        description: ''
      }
      this.isShowEdit = true
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'source',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },
  },
}
</script>

<style scoped>



</style>
import { createEasyPageId as createId } from "../../../ulits";
import { getEnvCodeTemp } from "../../EasyGlobal";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "提交",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "提交",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasySubmit",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: "提交",
        // 动作
        actions: {
          type: "post",
          url: "",
          params: {},
          headers: {},
          // 自定义动作
          ajaxcodeJs: "// 获取当前页面配置\n// const app = this.$store.state.easyWindowData[this.pageId];\n// console.log('页面配置', app)\n\n// 通过“组件标识”获取组件配置\n// const keyCom = this.$store.getters.getEasyWindowDataByKey(this.pageId, '${组件标识}')\n// console.log('${组件标识}', keyCom)\n\n// 修改组件属性\n// keyCom.attribute.data.value = 'https://www.baidu.com/img/flexible/logo/pc/result@2.png'\n// this.$store.commit('setEasyWindowDataByKey', { pageId: this.pageId, key: '${组件标识}', value: keyCom });\n console.log('请求参数', headers, params)",
        },
        // 组件标签
        label: {
          isShow: false,
          value: "Label:",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "0px",
            left: "0px",
            position: "relative",
            width: "60px",
          },
        },
        // 事件
        events: {
          beforeSubmitEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          afterSubmitEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          successSubmitEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          failSubmitEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
        },
        // 组件样式
        cStyle: {
          height: "auto",
          width: "auto",
          background: "#409eff",
          "font-size": "14px",
          color: "#ffffff",
        },
      },
      // 容器样式
      cStyle: {
        fontSize: "14px",
        height: "auto",
        width: "auto",
        background: "rgba(255, 255, 255, 0)",
        color: "#ffffff",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

const explian = {
  id: "ID",
  name: "名称",
  component: "组件",
  data: "数据",
  tab: "标签",
  options: "高级",
  value: "默认值",
  width: "宽度",
  height: "高度",
  label: "标签",
  isShow: "显示",
  fit: "适应",
  isShowPercentage: "显示%",
  key: "标识",
  selectList: "选择项",
  type: "类型",
  database: "数据库",
  api: "API",
  sql: "SQL",
  disable: "不可用",
  url: "URL",
  method: "方法",
  body: "请求体",
  params: "参数",
  headers: "头部",
  refreshTime: "刷新时间",
  sourceId: "数据源ID",
  enable: "开启",
  changeEvent: "失焦点/回车后值变更事件",
  blurEvent: "失焦点事件",
  focusEvent: "获焦点事件",
  inputEvent: "值变更事件",
  clearEvent: "清空值事件",
  codeJs: "JS代码",
  pageUrl: "询查",
  addUrl: "新增",
  deleteUrl: "删除",
  updateUrl: "更新",
  model: "模型",
  page: "页",
  count: "行数",
  order_by: "排序",
  search: "查询",
  visibleChangeEvent: "下拉框出现/隐藏时触发事件",
  removeTagEvent: "多选模式下移除tag时触发事件",
  editEvent: "打开编辑器事件",
  filtercodeJs: "过滤器",
  title: "标题",
  columns: "列名",
  maxHeight: "最大高度",
  cellStyle: "单元格样式",
  headerCellStyle: "表头单元格样式",
  beforeSubmitEvent: "提交前事件",
  afterSubmitEvent: "提交后事件",
  successSubmitEvent: "提交成功事件",
  failSubmitEvent: "提交失败事件",
  actions: "动作",
  post: "POST请求",
  get: "GET请求",
  delete: "DELETE请求",
  put: "PUT请求",
  display: "显示",
  operate: "操作",
  echart: "图表",
  xAxis: "X轴",
  yAxis: "Y轴",
  legend: "图例",
  backgroundColor: "背景颜色",
  toolbox: "工具栏",
  color: "颜色",
  series: "系列",
  animation: "动画",
  text: "文本",
  subtext: "子文本",
  left: "左侧",
  top: "顶部",
  link: "链接",
  show: "显示",
  textStyle: "文本样式",
  subtextStyle: "子文本样式",
  right: "右侧",
  bottom: "底部",
  orient: "朝向",
  showTitle: "显示标题",
  iconStyle: "图标样式",
  padding: "内边距",
  icon: "图标",
  position: "定位",
  offset: "偏移",
  nameLocation: "标题位置",
  nameTextStyle: "标题样式",
  itemSize: "样式",
  radar: "雷达",
  ajaxcodeJs: "参数处理",
  clickEvent: "点击事件",
  isShowDeleteIcon: "显示图标",
  rowStyle: "行样式",
  labelStyle: "标签样式",
  defaultProps: "数据映射",
  deleteEvent: "删除事件",
  titleEvent: "标题事件",
  showCheckbox: "显示多选",
  titleStyle: "标题样式",
  treeIcons: "树图标",
  event: "事件",
  checkChangeEvent: "多选事件"
};

export default explian;

<template>
    <div style="width: 100%;">
        <div class="start-box"
            v-for="(item, index) in Object.entries(attribute).sort(function (a) { if (typeof a[1] === 'object') { return 1 } else { return -1 } })"
            :key="index" style="margin-top: 8px; width: 100%;">
            <!-- 标签 -->
            <div v-if="!noShowKeys.includes(item[0])"
                style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explian(item[0]) }}
            </div>
            <!-- 内容 -->
            <div style="width: 100%">
                <!-- 标题 -->
                <div style=" width: 100%;" v-if="item[0] === 'title'">
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ObjectEditer>
                </div>
                <!-- 系列 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'series'">
                    <InputEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                    :data="{ value: attribute[item[0]] }" :boxStyle="{}"></InputEditer>
                </div>
                <!-- X轴 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'xAxis'">
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ObjectEditer>
                </div>
                <!-- Y轴 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'yAxis'">
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ObjectEditer>
                </div>
                <!-- 图例 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'legend'">
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ObjectEditer>
                </div>
                <!-- 工具栏 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'toolbox'">
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ObjectEditer>
                </div>
                <!-- 其他 -->
                <InputEditer v-else :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                    :data="{ value: attribute[item[0]] }" :boxStyle="{}"></InputEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import InputEditer from './InputEditer';
import ObjectEditer from './ObjectEditer';

export default {
    name: 'EchartsEditer',
    props: { data: Object, noShowKeys: Array, updateAttributeData: Function, pKey: String },
    components: {
        InputEditer, ObjectEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {},
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        explian(key) {
            var str = attributeMap[key]
            if (str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

export function getEnvCodeTemp() {
  return 'console.log("当前组件的值", this.value)\nconsole.log("当前页面ID", this.pageId)\nconsole.log("当前组件配置", this.data)\n\n// 通过“组件标识”获取组件配置\nconsole.log("getComponent", getComponent("组件标识"));\n\n// 通过“组件标识”更新组件配置\nvar com = getComponent("组件标识");\ncom.attribute.data.value = "值";\nsetComponent("组件标识", com);\n\n// 获取环境变量\nconsole.log("getGlobalVar", getGlobalVar("变量名"));\n\n// 设置环境变量\nsetGlobalVar("变量名", "值");';
}

export function getFunCode(code) {
  const headers = "const that = this;\n";
  const getApp =
    "function getApp(){return that.$store.state.easyWindowData[that.pageId]}\n";
  const getGlobalVar =
    "function getGlobalVar(key){return that.$store.state.easyLoaderData[that.pageId][key]}\n";
  const setGlobalVar =
    "function setGlobalVar(key, value){that.$store.commit('setLoaderData', { id: that.pageId, key: key, value: value })}\n";
  const getComponent =
    "function getComponent(key){return that.$store.getters.getEasyWindowDataByKey(that.pageId, key)}\n";
  const setComponent =
    "function setComponent(key, component){that.$store.commit('setEasyWindowDataByKey', { pageId: that.pageId, key: key, value: component })}\n";
  return (
    headers +
    getApp +
    getGlobalVar +
    setGlobalVar +
    getComponent +
    setComponent +
    code
  );
}

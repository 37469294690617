<template>
    <div style="width: 100%;">
        <!-- 根据字段类型选用表单组件 -->
        <div v-if="pKey.endsWith('.sql')">
            <el-input size="mini" type="textarea" :rows="6" placeholder="请输入内容" v-model="value" @blur="update">
            </el-input>
            <el-button size="mini" @click="run" type="text">执行</el-button>
            <el-input size="mini" type="textarea" :rows="6" placeholder="请输入内容" v-model="sqlData" :readonly="true">
            </el-input>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import { execSql } from '@/utils/apis/dev/sourceApi';
import { getProjectId, getEasyPageId } from "../ulits";

export default {
    name: 'SqlEditer',
    props: { data: String, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
    },
    watch: {
        "data": {
            handler: function (val) {
                this.value = val
            },
            deep: true
        }
    },
    data() {
        return {
            sqlData: '',
            key: '',
            value: ''
        }
    },
    beforeMount() {
        this.value = this.data
    },
    methods: {
        explian(key) {
            var str = attributeMap[key]
            if (str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
        update() {
            this.updateAttributeData(this.pKey, this.value)
        },
        getSourceId() {
            var key = this.pKey.replaceAll(".sql", '')
            var keys = key.split(".")
            keys.push("sourceId")
            var data = this.$store.state.easyLoaderSetting[getEasyPageId()]
            for (let index = 0; index < keys.length; index++) {
                const element = keys[index];
                data = data[element]
            }
            return data
        },
        run() {
            let params = { projectId: getProjectId(), sourceId: this.getSourceId(), sql: this.value, }
            execSql(params).then(res => {
                if (res.data.code === 200) {
                    // this.$store.commit('setLoaderData', { id: this.pageId, key: this.key, value: res.data.data });
                    // console.log('执行SQL结果', res.data)
                    this.sqlData = JSON.stringify(res.data.data, null, 4)
                } else {
                    this.sqlData = res.data.msg
                }
            }).catch(err => {
                this.sqlData = err
            })
        }
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

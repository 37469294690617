<template>
  <div @keydown="handleKeydown">
    <editor ref='myEditor' v-model="content" @init="editorInit" lang="python" theme="dracula" width="100%" height="600">
    </editor>
  </div>
</template>
  
<script>

export default {
  components: {
    editor: require('vue2-ace-editor'),
  },
  data() {
    return {
      content: "",
      editorOptions: {
        tabSize: 4, // tab默认大小
        showPrintMargin: false, // 去除编辑器里的竖线
        fontSize: 14, // 字体大小
        highlightActiveLine: true, // 高亮配置
        enableSnippets: true,
        enableLiveAutocompletion: true,
        enableBasicAutocompletion: true
      }
    };
  },
  mounted() {

  },
  methods: {
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...           
      require('brace/mode/python')    //language
      require('brace/theme/eclipse')
      require('brace/snippets/python') //snippet
      require('brace/snippets/text')
      this.editor = editor
      editor.setOptions(this.editorOptions)
    },
    handleKeydown(event) {
      if (event.altKey && event.key === '=') {
        this.editorOptions.fontSize = this.editorOptions.fontSize + 1
      }
      if (event.altKey && event.key === '-') {
        this.editorOptions.fontSize = this.editorOptions.fontSize - 1
      }
      this.editor.setOptions(this.editorOptions)
    },
  },
};
</script>
<template>
    <!-- 设计器容器 -->
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <!-- 组件容器 -->
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <!-- 实际组件 -->
            <el-table size="mini" :data="value" :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle"
                :cell-style="data.attribute.data.cellStyle" :header-cell-style="data.attribute.data.headerCellStyle"
                :max-height="data.attribute.data.maxHeight">
                <el-table-column v-for="(item, index) in  data.attribute.data.columns" :key="index" :label="item.label"
                    :width="item.width">
                    <template slot-scope="scope">
                        <!-- 根据字段类型选用表单组件 -->
                        <p v-if="item.type === 'key'">{{ scope.row[item.column] }}</p>
                        <el-input size="mini" v-if="item.type === 'string'" v-model="scope.row[item.column]"
                            clearable></el-input>
                        <el-input-number size="mini" v-if="item.type === 'number'"
                            v-model="scope.row[item.column]"></el-input-number>
                        <el-date-picker size="mini" v-if="item.type === 'datetime'" v-model="scope.row[item.column]"
                            type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                        </el-date-picker>
                        <el-input size="mini" v-if="item.type === 'text'" type="textarea" :rows="2"
                            v-model="scope.row[item.column]">
                        </el-input>
                        <div v-if="item.type === 'percentage'" class="start-box" style="margin-left: 8px">
                            <el-slider size="mini" v-model="scope.row[item.column]" style="width: 100px"></el-slider>
                            <div style="color: aliceblue; margin-left: 16px; width: 100px; margin-top: 8px;">
                                {{ scope.row[item.column] }}%
                            </div>
                        </div>
                        <el-switch size="mini" v-if="item.type === 'bool'" v-model="scope.row[item.column]"
                            active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                        <el-input-number size="mini" v-if="item.type === 'float'" v-model="scope.row[item.column]"
                            :precision="2" :step="0.1"></el-input-number>
                    </template>
                </el-table-column>
            </el-table>
            <component v-for="( item, index ) in  data.children " :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'

export default {
    name: "EasyScrollTable",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
            intervalId: null,
            scrollPx: 1,
            lastPx: -1,
        };
    },
    watch: {
        // 监控组件值的变化后提交到变量池
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        // 监听设计器传过来的初始配置
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        // 监控变量池的变化并更新当前组件的值
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        // 获取当前页面的ID
        this.pageId = EasyUiUlits.getEasyPageId()
        // 初始化当前组件的值
        this.value = this.data.attribute.data.value
        // 初始化当前组件在变量池的值
    },
    mounted() {
        this.intervalId = setInterval(this.scrollTable, 100);
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    methods: {
        comnitValue(val) {
            // 监控组件值的变化后提交到变量池
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        scrollTable() {
            const table = this.$refs[this.data.attribute.key];
            if (table) {
                const scrollContainer = table.$el.querySelector('.el-table__body-wrapper');
                if (scrollContainer) {
                    if (this.lastPx === scrollContainer.scrollTop) {
                        this.lastPx = -1
                        scrollContainer.scrollTop = 0
                    } else {
                        this.lastPx = scrollContainer.scrollTop
                        scrollContainer.scrollTop += this.scrollPx
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.scrollbar-wrapper {
    overflow-x: hidden;
    height: 200px;
    /* 设置你想要的高度 */
}
</style>

import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "视频",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "视频",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyVideo",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: "https://www.runoob.com/try/demo_source/movie.mp4",
        operate: {
          width: 300,
          height: 240,
          controls: true,
          type: "video/mp4",
          autoplay: true,
          loop: true,
        },
        // 组件标签
        label: {
          isShow: false,
          value: "Label:",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "2px",
            left: "0px",
            position: "relative",
            "margin-right": "8px",
          },
        },
        // 组件样式
        cStyle: {
          color: "rgba(0, 0, 0, 1)",
          fontSize: "14px",
          height: "auto",
          width: "auto",
          background: "rgba(255, 255, 255, 0)",
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(0, 0, 0, 1)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">操作日志</div>
        <el-dialog title="日志详情" :visible.sync="infoDialogVisible" width="50%" center>
            <el-descriptions size="mini" :column="1" border>
                <el-descriptions-item label="LogID">
                    <div class="log_text">{{ log.id }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">
                    <div class="log_text">{{ log.createTime }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="用户">
                    <div class="log_text">{{ log.username }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="用户ID">
                    <div class="log_text">{{ log.userId }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="URL">
                    <div class="log_text">{{ log.url }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="IP">
                    <div class="log_text">{{ log.ip }}</div>
                </el-descriptions-item>
                <el-descriptions-item label="内容">
                    <div class="log_text">{{ log.body }}</div>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
        <div class="base-box content-box">
            <el-table :data="tableData.filter(data => !search || data.url.toLowerCase().includes(search.toLowerCase()))"
                size="mini">
                <el-table-column type="index" label="序号" width="80">
                </el-table-column>

                <el-table-column width="200" prop="createTime" label="发现时间">
                </el-table-column>

                <el-table-column width="120" prop="username" label="用户名">
                </el-table-column>

                <el-table-column width="120" prop="ip" label="IP">
                </el-table-column>

                <el-table-column prop="url" label="URL">
                </el-table-column>

                <el-table-column prop="body" label="数据">
                </el-table-column>

                <el-table-column width="200" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="mini">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getListAPI } from "@/utils/apis/system/logApi"

export default {
    name: 'logPage',
    data() {
        return {
            tableData: [],
            search: '',
            log: {},
            infoDialogVisible: false,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(row) {
            this.log = row
            this.infoDialogVisible = true
            this.getSelectList(row.id)
        },
        getList() {
            getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },

    }
}
</script>

<style scoped>
.content-box {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.log_text {
    font-size: 14px;
}
</style>
<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">权限管理</div>
        <div class="base-box content-box">
            <el-table
                :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                size="mini">
                <el-table-column type="index" label="序号" width="50">
                </el-table-column>

                <el-table-column min-width="150" label="权限名" prop="name">
                </el-table-column>

                <el-table-column width="60" label="级别" prop="dataLevel">
                </el-table-column>

                <el-table-column width="250" label="接口" prop="path">
                </el-table-column>

                <el-table-column width="80" label="类型" prop="type">
                </el-table-column>

                <el-table-column width="150" label="说明" prop="description">
                </el-table-column>

                <el-table-column width="150" prop="createTime" label="创建时间">
                </el-table-column>

                <el-table-column width="150" prop="updateTime" label="更新时间">
                </el-table-column>

                <el-table-column width="160" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.id" v-show="scope.row.dataLevel !== 'system'" size="mini"
                            type="danger" @click="handleDelete(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getListAPI, deleteAPI } from "@/utils/apis/system/authApi"

export default {
    name: 'authPage',
    data() {
        return {
            tableData: [],
            search: '',
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleDelete(row) {
            this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteAPI(row).then(res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.getList()
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getList() {
            getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>
.content-box {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}
</style>
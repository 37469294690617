<template>
    <div :style="boxStyle">
        <!-- 图片选择器 -->
        <el-dialog title="图片选择器" :visible.sync="imgSelectShow" width="50%">
            <div class="start-wrap-box">
                <div v-for="(item, index) in imges" :key="index">
                    <div @click="selectImg(item.path)" style="margin-right: 10px;"
                        v-if="item.path.toLowerCase().endsWith('png') || item.path.toLowerCase().endsWith('jpg') || item.path.toLowerCase().endsWith('jpeg')">
                        <el-image style="width: 100px; height: 100px" :src="item.path + '?token=' + token" fit="fill">
                        </el-image>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-input v-if="css.valueType === 'string'" v-isEmpty size="mini" v-model="value" clearable
            @input="update"></el-input>
        <el-select v-if="css.valueType === 'list'" size="mini" v-model="value" placeholder="请选择" @change="update">
            <el-option v-for="(item, index) in css.selectList" :key="index" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div v-if="css.valueType === 'number'" class="start-box">
            <el-input-number size="mini" v-model="numberValue" :precision="2" :step="4.0"
                @change="update"></el-input-number>
            <el-select size="mini" v-model="unit" placeholder="请选择单位" @change="update"
                style="width: 70px; margin-left: 4px;">
                <el-option v-for="(item, index) in css.unit" :key="index" :label="item" :value="item">
                </el-option>
            </el-select>
        </div>
        <el-color-picker size="mini" v-if="css.valueType === 'color'" v-model="color" :show-alpha="true"
            :predefine="['rgba(0, 0, 255, 1)', 'rgba(255, 0, 0, 1)', 'rgba(0, 255, 0, 1)']" @change="update"
            style="color: black;"></el-color-picker>
        <div v-if="css.valueType === 'image'">
            <div @click="showImgSelect">
                <el-image style="width: 80px; height: 80px" :src="imgUrl" fit="fill"></el-image>
            </div>
        </div>
    </div>
</template>

<script>
import * as fileApi from "@/utils/apis/dev/fileApi";
import { getProjectId } from "../ulits";

export default {
    name: 'CssEditer',
    props: { data: Object, boxStyle: Object, updateAttributeData: Function, pKey: String, css: Object },
    components: {},
    watch: {
        "data": {
            handler: function (data) {
                this.value = data.value
                if (this.css.valueType === 'number') {
                    this.numberValue = this.value.replaceAll(this.unit, '')
                }
                if (this.css.valueType === 'color') {
                    this.color = this.data.value
                }
            },
            deep: true
        },
    },
    data() {
        return {
            value: '',
            numberValue: 0,
            color: 'rgba(0, 0, 0, 1)',
            unit: 'px',
            imgUrl: "",
            imgSelectShow: false,
            imges: [],
            projectId: '',
            token: '',
        }
    },
    beforeMount() {
        this.token = localStorage.getItem('token')
        this.projectId = getProjectId()
        if (this.css.valueType === 'number') {
            this.checkUint()
            this.numberValue = this.data.value.replaceAll(this.unit, '')
        } else if (this.css.valueType === 'color') {
            this.color = this.data.value
        } else {
            this.value = this.data.value
        }
    },
    methods: {
        update() {
            if (this.css.valueType === 'number') {
                if (this.unit === 'auto') {
                    this.value = 'auto'
                } else {
                    this.value = this.numberValue + this.unit
                }
            }
            if (this.css.valueType === 'color') {
                this.value = this.color
            }
            this.updateAttributeData(this.pKey, this.value)
        },
        checkUint() {
            if (this.css.unit) {
                var that = this
                this.css.unit.forEach(element => {
                    if (that.data.value.endsWith(element)) {
                        that.unit = element
                    }
                });
            }
        },
        showImgSelect() {
            this.getImgList()
        },
        selectImg(url) {
            this.value = "url(" + url + ")"
            this.updateAttributeData(this.pKey, this.value)
            this.imgUrl = url + '?token=' + this.token
            this.imgSelectShow = !this.imgSelectShow
        },
        getImgList() {
            fileApi.getListAPI(this.projectId).then(res => {
                if (res.data.code === 200) {
                    this.imges = res.data.data
                    this.imgSelectShow = !this.imgSelectShow
                }
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>

<style scoped></style>

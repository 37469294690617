<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box" :ref="data.attribute.key" :id="data.id">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <el-button size="mini" :style="data.attribute.data.cStyle" :ref="data.attribute.key" :id="data.id"
                    v-model="value" type="primary" @click="onClick()">{{ value }}</el-button>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'
import * as Global from '../../EasyGlobal'
import axios from 'axios'

export default {
    name: "EasySubmit",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
        this.request = axios.create({
            baseURL: process.env.BASE_API,
            timeout: 60 * 1000
        })
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        onClick() {
            // 提交前事件
            if (this.data.attribute.data.events.beforeSubmitEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.beforeSubmitEvent.codeJs))
            }
            // 提交代码
            this.submit()
            // 提交后事件
            if (this.data.attribute.data.events.afterSubmitEvent.enable) {
                eval(Global.getFunCode(this.data.attribute.data.events.afterSubmitEvent.codeJs))
            }
        },
        getValue(key) {
            const loaderData = this.$store.state.easyLoaderData[this.pageId]
            return loaderData[key]
        },
        createId(){
            return EasyUiUlits.createEasyPageId()
        },
        submit() {
            var headers = JSON.parse(JSON.stringify(this.data.attribute.data.actions.headers))
            if (!Object.keys(headers).includes('Authorization')) {
                headers['Authorization'] = localStorage.getItem('token')
            }
            let axiosRequest = {
                url: this.data.attribute.data.actions.url,
                method: this.data.attribute.data.actions.type,
                headers: headers,
            }
            let params = JSON.parse(JSON.stringify(this.data.attribute.data.actions.params))
            let keys = Object.keys(params)
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index]
                const tag = params[key]
                params[key] = this.getValue(tag)
            }
            // 参数处理
            eval(Global.getFunCode(this.data.attribute.data.actions.ajaxcodeJs))
            if (axiosRequest.method === 'get' || axiosRequest.method === 'delete') {
                axiosRequest.params = params
            } else {
                axiosRequest.data = params
            }
            this.request(axiosRequest).then((res) => {
                console.log("请求结果：", res)
                if (this.data.attribute.data.events.successSubmitEvent.enable) {
                    eval(Global.getFunCode(this.data.attribute.data.events.successSubmitEvent.codeJs))
                }
            }).catch(err => {
                console.log(err)
                if (this.data.attribute.data.events.failSubmitEvent.enable) {
                    eval(Global.getFunCode(this.data.attribute.data.events.failSubmitEvent.codeJs))
                }
                this.$message.error('操作失败');
            })
        }
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

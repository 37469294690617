import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/scheduler";
let list_url = "/api/schedulers/";
let stop_job_url = "/api/scheduler/stop/";
let start_job_url = "/api/scheduler/start/";

// get请求
export function getListAPI(pid) {
  let tmpUrl = list_url + pid;
  return http.get(tmpUrl, {});
}

// get请求
export function getAPI(params) {
  return http.get(url, params);
}

// post请求
export function addAPI(params) {
  return http.post(url, params);
}

// put 请求
export function stopJobAPI(id) {
  return http.put(stop_job_url + id, {});
}

// put 请求
export function startJobAPI(id) {
  return http.put(start_job_url + id, {});
}

// delete 请求
export function deleteAPI(params) {
  return http.delete(url, params);
}

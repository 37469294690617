import { createEasyPageId as createId } from "../../../ulits";
import { getEnvCodeTemp } from "../../EasyGlobal";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "下拉框",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "下拉框",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasySelect",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: "选项1",
        // 选择项
        selectList: [
          { label: "选项1", value: "选项1" },
          { label: "选项2", value: "选项2" },
        ],
        // 组件标签
        label: {
          isShow: true,
          value: "Label:",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "4px",
            left: "0px",
            position: "relative",
            width: "60px",
          },
        },
        // 组件样式
        cStyle: {
          height: "auto",
          width: "auto",
          background: "rgba(255, 255, 255, 0)",
          "font-size": "14px",
          color: "#ffffff",
        },
        events: {
          changeEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          visibleChangeEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          removeTagEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          clearEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          blurEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
          focusEvent: {
            enable: false,
            codeJs:
              getEnvCodeTemp(),
          },
        },
      },
      // 容器样式
      cStyle: {
        fontSize: "14px",
        height: "auto",
        width: "auto",
        background: "rgba(255, 255, 255, 0)",
        color: "#ffffff",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

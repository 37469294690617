<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <el-upload :style="data.attribute.data.cStyle" :ref="data.attribute.key" :id="data.id" :action="uploadUrl"
                    :show-file-list="false" :on-success="onUploadSuccess" :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove">
                    <el-button size="mini" type="primary" icon="el-icon-upload">点击上传</el-button>
                    <div style="min-width: 100px;">
                        <div v-for="(file, index) in value" :key="index">
                            <div @click.stop="download(file.url)">{{ file.name }} <i @click.stop="handleRemove(index)"
                                    class="el-icon-delete"></i></div>
                        </div>
                    </div>
                </el-upload>
            </div>
            <component v-for="(item, index) in data.children" :key="index" :is="item.attribute.component" :data="item"
                :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
            </component>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'
import * as fileApi from '@/utils/apis/dev/fileApi'

export default {
    name: "EasyFile",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: [],
            pageId: null,
            uploadUrl: '/api/upload/' + EasyUiUlits.getProjectId() + '?token=' + localStorage.getItem('token'),
            downloadUrl: '/api/download/' + EasyUiUlits.getProjectId() + "/"
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        onUploadSuccess(res) {
            if (res.code === 200) {
                this.value.push(res.data)
                this.$message.success('上传成功!');
            } else {
                this.$message.error('上传失败');
            }
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 10;
            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传文件不能大于 10MB!');
            }
            return isLt2M;
        },
        handleRemove(index) {
            var file = this.value[index]
            fileApi.deleteAPI({ id: file.id }).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg)
                    this.value.splice(index, 1)
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getDownloadUrl(url) {
            return this.downloadUrl + url + '?token=' + localStorage.getItem('token')
        },
        download(url) {
            window.open(this.getDownloadUrl(url))
        }
    }
};
</script>

<style scoped>
::v-deep .el-upload {
    text-align: left;
}
</style>

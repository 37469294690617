import { render, staticRenderFns } from "./modelView.vue?vue&type=template&id=28ba0e5a&scoped=true"
import script from "./modelView.vue?vue&type=script&lang=js"
export * from "./modelView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ba0e5a",
  null
  
)

export default component.exports
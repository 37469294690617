import menuPage from "@/pages/system/menuPage.vue";
import logPage from "@/pages/system/logPage.vue";
import UserPage from "@/pages/system/userPage.vue";
import RolePage from "@/pages/system/rolePage.vue";
import AuthPage from "@/pages/system/authPage.vue";
import SysLogsPage from "@/pages/system/sysLogsPage.vue";

export default [
  {
    path: "menu",
    name: "menu",
    component: menuPage,
  },
  {
    path: "log",
    name: "log",
    component: logPage,
  },
  {
    path: "user",
    name: "user",
    component: UserPage,
  },
  {
    path: "role",
    name: "role",
    component: RolePage,
  },
  {
    path: "auth",
    name: "auth",
    component: AuthPage,
  },
  {
    path: "syslog",
    name: "sysLogsPage",
    component: SysLogsPage,
  },
];

// 布局类
import EasyFlow from "./components/layout/Flow/EasyFlow";
import EasyGrid from "./components/layout/Grid/EasyGrid";
import EasyTabs from "./components/layout/Tabs/EasyTabs";
import EasyFlex from "./components/layout/Flex/EasyFlex";
import EasyCenter from "./components/layout/Center/EasyCenter";
import EasyAbsolute from "./components/layout/Absolute/EasyAbsolute";
import EasyHorizontal from "./components/layout/Horizontal/EasyHorizontal";

// 数据类
import EasyPage from "./components/model/Page/EasyPage";
import EasyAutoCart from "./components/model/AutoCart/EasyAutoCart";

// 表单类
import EasyText from "./components/form/Text/EasyText";
import EasySwitch from "./components/form/Switch/EasySwitch";
import EasyString from "./components/form/String/EasyString";
import EasyStringMd from "./components/form/StringMd/EasyStringMd";
import EasySelect from "./components/form/Select/EasySelect";
import EasyRadio from "./components/form/Radio/EasyRadio";
import EasyPercentage from "./components/form/Percentage/EasyPercentage";
import EasyPassword from "./components/form/Password/EasyPassword";
import EasyNumber from "./components/form/Number/EasyNumber";
import EasyFloat from "./components/form/Float/EasyFloat";
import EasyJson from "./components/form/Json/EasyJson";
import EasyDatetime from "./components/form/Datetime/EasyDatetime";
import EasyCheckBox from "./components/form/CheckBox/EasyCheckBox";
import EasyFile from "./components/form/File/EasyFile";
import EasySubmit from "./components/form/Submit/EasySubmit";

// 信息类
import EasyImage from "./components/info/Image/EasyImage";
import EasyLabel from "./components/info/Label/EasyLabel";
import EasyInfo from "./components/info/Info/EasyInfo";
import EasyMakedown from "./components/info/Makedown/EasyMakedown";
import EasyLink from "./components/info/Link/EasyLink";
import EasyScrollTable from "./components/info/ScrollTable/EasyScrollTable";
import EasyVideo from "./components/info/Video/EasyVideo";
import EasyAudio from "./components/info/Audio/EasyAudio";
import EasyTree from "./components/info/Tree/EasyTree";

// 图表类
import EasyChart from "./components/echarts/Chart/EasyChart";
import EasyLineChart from "./components/echarts/LineChart/EasyLineChart";
import EasyPieChart from "./components/echarts/PieChart/EasyPieChart";
import EasyBarChart from "./components/echarts/BarChart/EasyBarChart";
import EasyScatterChart from "./components/echarts/ScatterChart/EasyScatterChart";
import EasyCandlestickChart from "./components/echarts/CandlestickChart/EasyCandlestickChart";
import EasyRadarChart from "./components/echarts/RadarChart/EasyRadarChart";
import EasyClockChart from "./components/echarts/ClockChart/EasyClockChart";

const EasyUi = {
  install(Vue) {
    // 布局类
    Vue.component("EasyFlow", EasyFlow);
    Vue.component("EasyGrid", EasyGrid);
    Vue.component("EasyTabs", EasyTabs);
    Vue.component("EasyFlex", EasyFlex);
    Vue.component("EasyCenter", EasyCenter);
    Vue.component("EasyAbsolute", EasyAbsolute);
    Vue.component("EasyHorizontal", EasyHorizontal);

    // 数据类
    Vue.component("EasyPage", EasyPage);
    Vue.component("EasyAutoCart", EasyAutoCart);

    // 表单类
    Vue.component("EasyText", EasyText);
    Vue.component("EasySwitch", EasySwitch);
    Vue.component("EasyString", EasyString);
    Vue.component("EasyStringMd", EasyStringMd);
    Vue.component("EasySelect", EasySelect);
    Vue.component("EasyRadio", EasyRadio);
    Vue.component("EasyPercentage", EasyPercentage);
    Vue.component("EasyPassword", EasyPassword);
    Vue.component("EasyNumber", EasyNumber);
    Vue.component("EasyFloat", EasyFloat);
    Vue.component("EasyJson", EasyJson);
    Vue.component("EasyDatetime", EasyDatetime);
    Vue.component("EasyCheckBox", EasyCheckBox);
    Vue.component("EasyFile", EasyFile);
    Vue.component("EasySubmit", EasySubmit);

    // 信息类
    Vue.component("EasyImage", EasyImage);
    Vue.component("EasyLabel", EasyLabel);
    Vue.component("EasyInfo", EasyInfo);
    Vue.component("EasyMakedown", EasyMakedown);
    Vue.component("EasyLink", EasyLink);
    Vue.component("EasyScrollTable", EasyScrollTable);
    Vue.component("EasyVideo", EasyVideo);
    Vue.component("EasyAudio", EasyAudio);
    Vue.component("EasyTree", EasyTree);

    // 图表类
    Vue.component("EasyChart", EasyChart);
    Vue.component("EasyLineChart", EasyLineChart);
    Vue.component("EasyPieChart", EasyPieChart);
    Vue.component("EasyBarChart", EasyBarChart);
    Vue.component("EasyScatterChart", EasyScatterChart);
    Vue.component("EasyCandlestickChart", EasyCandlestickChart);
    Vue.component("EasyRadarChart", EasyRadarChart);
    Vue.component("EasyClockChart", EasyClockChart);
  },
};

export default EasyUi;

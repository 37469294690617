import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let loginUrl = "/api/login";
let logout = "/api/logout";
let userInfourl = "/api/user/info";
let userUpdateurl = "/api/user/update";

let userRegisterurl = "/api/register";
let useRexistsurl = "/api/<username>/exists";

// post请求
export function loginAPI(params) {
  return http.post(loginUrl, params);
}

// post请求
export function logoutAPI(params) {
  return http.post(logout, params);
}

// 用户信息
export function userInfoAPI(params) {
  return http.get(userInfourl, params);
}

// post请求
export function updateAPI(params) {
  return http.post(userUpdateurl, params);
}


// post请求
export function registerAPI(params) {
  return http.post(userRegisterurl, params);
}

export function userExistsAPI(username) {
  var url = useRexistsurl.replaceAll("<username>", username)
  return http.get(url, {});
}
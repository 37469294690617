<template>
  <div class="base-box margin-box">
    <!-- <el-button @click="test">test</el-button> -->
    <!-- {{ flowData }} -->
    <div style="position: relative; width: 100%; margin: 10px;">
      <el-button type="primary" size="mini" @click="saveFlow" style="right: 0px; position: absolute; top: -34px;"
        icon="el-icon-paperclip">保存</el-button>
      <el-button type="primary" size="mini" @click="outputFlow" style="right: 80px; position: absolute; top: -34px;"
        icon="el-icon-download">导出</el-button>
      <el-button type="warning" size="mini" @click="release" style="right: 160px; position: absolute; top: -34px;"
        icon="el-icon-document-checked">发布</el-button>
      <el-button type="success" size="mini" @click="run" style="right: 240px; position: absolute; top: -34px;"
        icon="el-icon-video-play">运行</el-button>
      <!-- <el-button type="primary" size="mini" @click="inputFlow" style="right: 160px; position: absolute; top: -34px;" icon="el-icon-upload2">导入</el-button> -->
    </div>
    <div class="super-flow-demo1">
      <div class="node-container">
        <!-- 基础组件 -->
        <div>
          <el-divider>基础组件</el-divider>
          <div class="node-row">
            <span class="node-item" v-for="(item, index) in nodeItemList" :key="index"
              @mousedown="evt => nodeItemMouseDown(evt, item.value)">
              {{ item.label }}
            </span>
          </div>
        </div>
        <!-- 全局组件 -->
        <div>
          <el-divider>全局组件</el-divider>
          <div v-for="(item, i) in globalComponents" :key="i">
            <div class="node-directory">
              <i class="el-icon-document"></i>
              {{ item.label }}
            </div>
            <div class="node-row">
              <span class="node-item" v-for="(node, index) in item.options" :key="index"
                @mousedown="evt => nodeItemMouseDown(evt, node.value)">
                {{ node.label }}
              </span>
            </div>
          </div>
        </div>
        <!-- 项目组件 -->
        <div>
          <el-divider>项目组件</el-divider>
          <div v-for="(item, i) in projectComponents" :key="i">
            <div class="node-directory">
              <i class="el-icon-document"></i>
              {{ item.label }}
            </div>
            <div class="node-row">
              <span class="node-item" v-for="(node, index) in item.options" :key="index"
                @mousedown="evt => nodeItemMouseDown(evt, node.value)">
                {{ node.label }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flow-container" ref="flowContainer">
        <SuperFlow ref="superFlow" :graph-menu="graphMenu" :node-menu="nodeMenu" :node-list="flow.flow.nodeList"
          :link-list="flow.flow.linkList" :link-menu="linkMenu" :link-base-style="linkBaseStyle" :link-style="linkStyle"
          :link-desc="linkDesc">
          <template v-slot:node="{ meta }">
            <div :class="`flow-node flow-node-${meta.type}`">
              <header class="ellipsis">
                <span>{{ meta.name }}</span>
                <div style="position: absolute; right: 10px; top: 0px;">
                  <i class="el-icon-loading" style="color: black;"
                    v-show="(meta.type === nodeType.shell || meta.type === nodeType.component) && meta.isRun"
                    @click="meta.isRun = false"></i>
                  <i class="el-icon-video-play" style="color: red;"
                    v-show="(meta.type === nodeType.shell || meta.type === nodeType.component) && !meta.isRun"
                    @click="meta.isRun = true"></i>
                </div>
              </header>
              <section>
                {{ meta.desc }}
              </section>
            </div>
          </template>
        </SuperFlow>
      </div>
    </div>

    <el-dialog append-to-body :title="drawerConf.title" :visible.sync="drawerConf.visible" :close-on-click-modal="false"
      width="50%">
      <el-form size="mini" @keyup.native.enter="settingSubmit" @submit.native.prevent
        v-show="drawerConf.type === drawerType.node" ref="nodeSetting" :model="nodeSetting">
        <el-form-item label="节点名称">
          <el-input size="mini" disabled v-model="nodeSetting.name" placeholder="请输入节点名称" maxlength="30">
          </el-input>
        </el-form-item>
        <el-form-item label="节点描述">
          <el-input size="mini" v-model="nodeSetting.desc" placeholder="请输入节点描述" maxlength="30">
          </el-input>
        </el-form-item>
        <el-form-item v-for="(item, index) in nodeSetting.params" :key="index"
          v-show="nodeSetting.type === nodeType.component" :label="item.key">
          <el-input size="mini" type="textarea" :rows="1" v-model="item.value">
          </el-input>
        </el-form-item>
        <el-form-item v-show="nodeSetting.type === nodeType.shell || nodeSetting.type === nodeType.component"
          label="Python代码" prop="desc">
          <el-input size="mini" :disabled="nodeSetting.type === nodeType.component" v-model="nodeSetting.code"
            placeholder="请编写Python代码" type="textarea" :rows="8">
          </el-input>
        </el-form-item>
      </el-form>
      <el-form @keyup.native.enter="settingSubmit" @submit.native.prevent v-show="drawerConf.type === drawerType.link"
        ref="linkSetting" :model="linkSetting">
        <el-form-item label="输出顺序">
          <el-input-number size="mini" v-model="linkSetting.output_no" :min="1">
          </el-input-number>
        </el-form-item>
        <el-form-item label="连线描述" prop="desc">
          <el-input size="mini" v-model="linkSetting.desc" placeholder="请输入连线描述">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="drawerConf.cancel">
          取 消
        </el-button>
        <el-button type="primary" @click="settingSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-drawer title="运行结果" :visible.sync="isShowResult" direction="btt" :modal="false" size="40%">
      <div v-for="value, index in runResult.split('\n')" :key="index" style="margin-left: 10px; margin-right: 10px;">
        <div style="margin-left: 10px;">{{ value }}</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import SuperFlow from 'vue-super-flow'
import 'vue-super-flow/lib/index.css'
import { getUrlArgs } from "@/tools/url"

import * as ComponentApi from "@/utils/apis/dev/viewComponent"
import * as FlowApi from "@/utils/apis/dev/flowApi"

const drawerType = {
  node: 0,
  link: 1
}

export default {
  name: 'flowView',
  components: {
    SuperFlow
  },
  props: ['flow'],
  data() {
    return {
      runResult: '',
      isShowResult: false,
      drawerType,
      drawerConf: {
        title: '',
        visible: false,
        type: null,
        info: null,
        open: (type, info) => {
          const conf = this.drawerConf
          conf.visible = true
          conf.type = type
          conf.info = info
          if (conf.type === drawerType.node) {
            conf.title = '节点'
            if (this.$refs.nodeSetting) this.$refs.nodeSetting.resetFields()
            this.$set(this.nodeSetting, 'name', info.meta.name)
            this.$set(this.nodeSetting, 'desc', info.meta.desc)
            this.$set(this.nodeSetting, 'type', info.meta.type)
            this.$set(this.nodeSetting, 'code', info.meta.code)
            this.$set(this.nodeSetting, 'params', info.meta.params)
          } else {
            conf.title = '连线'
            if (this.$refs.linkSetting) this.$refs.linkSetting.resetFields()
            this.$set(this.linkSetting, 'desc', info.meta ? info.meta.desc : '')
            this.$set(this.linkSetting, 'output_no', info.meta ? info.meta.output_no : 1)
          }
        },
        cancel: () => {
          this.drawerConf.visible = false
          if (this.drawerConf.type === drawerType.node) {
            this.$refs.nodeSetting.clearValidate()
          } else {
            this.$refs.linkSetting.clearValidate()
          }
        }
      },
      linkSetting: {
        desc: '',
        output_no: 1,
      },
      nodeSetting: {
        name: '',
        desc: '',
        code: '',
        functions: '',
        input: [],
      },
      dragConf: {
        isDown: false,
        isMove: false,
        offsetTop: 0,
        offsetLeft: 0,
        clientX: 0,
        clientY: 0,
        ele: null,
        info: null
      },
      nodeItemList: [
        {
          label: '输入',
          value: () => ({
            width: 160,
            height: 80,
            meta: { label: '输入', name: '输入', type: 'start' },
          })
        },
        {
          label: '输出',
          value: () => ({
            width: 80,
            height: 50,
            meta: { label: '输出', name: '输出', type: 'end' },
          })
        },
        // {
        //   label: '路由',
        //   value: () => ({
        //     width: 160,
        //     height: 80,
        //     meta: {label: '路由', name: '路由', type: 'route'},
        //   })
        // },
        {
          label: '脚本',
          value: () => ({
            width: 160,
            height: 80,
            meta: { label: '脚本', name: '脚本', type: 'shell', code: '' },
          })
        },
      ],
      graphMenu: [
        [
          {
            label: '全选',
            selected: graph => {
              graph.selectAll()
            }
          }
        ]
      ],
      nodeMenu: [
        [
          {
            label: '删除',
            selected: node => {
              this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                node.remove()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
              });
            }
          },
          {
            label: '编辑',
            selected: node => {
              this.drawerConf.open(drawerType.node, node)
            }
          }
        ]
      ],
      linkMenu: [
        [
          {
            label: '删除',
            selected: link => {
              this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                link.remove()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
              });
            }
          },
          {
            label: '编辑',
            selected: link => {
              this.drawerConf.open(drawerType.link, link)
            }
          }
        ]
      ],
      linkBaseStyle: {
        color: '#666666',           // line 颜色
        hover: '#FF0000',           // line hover 的颜色
        textColor: '#666666',       // line 描述文字颜色
        textHover: '#FF0000',       // line 描述文字 hover 颜色
        font: '14px Arial',         // line 描述文字 字体设置 参考 canvas font
        dotted: false,              // 是否是虚线
        lineDash: [4, 4],           // 虚线时生效
        background: 'rgba(255,255,255,0.6)'    // 描述文字背景色
      },
      nodeType: {
        shell: 'shell',
        end: 'end',
        start: 'start',
        component: 'component',
        route: 'route'
      },
      projectId: null,
      projectName: null,
      globalComponents: [],
      projectComponents: [],
      flowData: {},
    }
  },
  watch: {
    flow: function (newVal) {
      console.log(newVal)
      // alert(JSON.stringify(newVal))
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getGlobalComponents()
    this.getProjectComponents()

    document.addEventListener('mousemove', this.docMousemove)
    document.addEventListener('mouseup', this.docMouseup)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('mousemove', this.docMousemove)
      document.removeEventListener('mouseup', this.docMouseup)
    })
  },
  methods: {
    getGlobalComponents() {
      ComponentApi.getSelectListAPI('global').then(res => {
        if (res.data.code === 200) {
          res.data.data.forEach(item => {
            let directory = { label: item.label, options: [] }
            item.options.forEach(element => {
              // 处理左边节点
              var node = {
                label: element.label,
                value: () => ({
                  width: 160,
                  height: 80,
                  meta: { label: element.label, name: element.label, type: this.nodeType.component, componentId: element.value },
                })
              }
              directory.options.push(node)
            });
            this.globalComponents.push(directory)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getProjectComponents() {
      ComponentApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          res.data.data.forEach(item => {
            let directory = { label: item.label, options: [] }
            item.options.forEach(element => {
              // 处理左边节点
              var node = {
                label: element.label,
                value: () => ({
                  width: 160,
                  height: 80,
                  meta: { label: element.label, name: element.label, type: this.nodeType.component, componentId: element.value },
                })
              }
              directory.options.push(node)
            });
            this.projectComponents.push(directory)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    flowNodeClick(meta) {
      console.log(this.$refs.superFlow.graph)
      console.log(meta)
    },
    linkStyle(link) {
      if (link.meta && link.meta.output_no === 1) {
        return {
          color: 'red',
          hover: '#FF00FF',
          // dotted: true
        }
      } else {
        return {
          color: 'green',
          hover: '#FF00FF',
          // dotted: true
        }
      }
    },
    linkDesc(link) {
      return link.meta ? link.meta.desc : ''
    },
    settingSubmit() {
      const conf = this.drawerConf
      if (this.drawerConf.type === drawerType.node) {
        if (!conf.info.meta) conf.info.meta = {}
        Object.keys(this.nodeSetting).forEach(key => {
          this.$set(conf.info.meta, key, this.nodeSetting[key])
        })
        this.$refs.nodeSetting.resetFields()
      } else {
        if (!conf.info.meta) conf.info.meta = {}
        Object.keys(this.linkSetting).forEach(key => {
          this.$set(conf.info.meta, key, this.linkSetting[key])
        })
        this.$refs.linkSetting.resetFields()
      }
      conf.visible = false
    },
    nodeMouseUp(evt) {
      evt.preventDefault()
    },
    nodeClick() {
      console.log(arguments)
    },
    docMousemove({ clientX, clientY }) {
      const conf = this.dragConf

      if (conf.isMove) {

        conf.ele.style.top = clientY - conf.offsetTop + 'px'
        conf.ele.style.left = clientX - conf.offsetLeft + 'px'

      } else if (conf.isDown) {

        // 鼠标移动量大于 5 时 移动状态生效
        conf.isMove =
          Math.abs(clientX - conf.clientX) > 5
          || Math.abs(clientY - conf.clientY) > 5

      }
    },
    docMouseup({ clientX, clientY }) {
      const conf = this.dragConf
      conf.isDown = false

      if (conf.isMove) {
        const {
          top,
          right,
          bottom,
          left
        } = this.$refs.flowContainer.getBoundingClientRect()

        // 判断鼠标是否进入 flow container
        if (
          clientX > left
          && clientX < right
          && clientY > top
          && clientY < bottom
        ) {

          // 获取拖动元素左上角相对 super flow 区域原点坐标
          const coordinate = this.$refs.superFlow.getMouseCoordinate(
            clientX - conf.offsetLeft,
            clientY - conf.offsetTop
          )

          // 获取组件详细信息
          if (conf.info.meta.type === this.nodeType.component) {
            ComponentApi.getAPI({ id: conf.info.meta.componentId }).then(res => {
              if (res.data.code === 200) {
                conf.info.meta.desc = res.data.data.description
                conf.info.meta.code = res.data.data.code
                conf.info.meta.functions = res.data.data.functions

                // 组件配置
                conf.info.meta.params = []
                let regexp = /\${[\u4E00-\u9FA5A-Za-z0-9_]+}/g
                let args = res.data.data.code.match(regexp)
                if (args) {
                  args.forEach(element => {
                    conf.info.meta.params.push({ key: element, value: '' })
                  });
                }

                // 添加节点
                this.$refs.superFlow.addNode({
                  coordinate,
                  ...conf.info
                })
              }
            }).catch(err => {
              console.log(err)
            })
          } else {
            // 添加节点
            this.$refs.superFlow.addNode({
              coordinate,
              ...conf.info
            })
          }
        }

        conf.isMove = false
      }

      if (conf.ele) {
        conf.ele.remove()
        conf.ele = null
      }
    },
    nodeItemMouseDown(evt, infoFun) {
      const {
        clientX,
        clientY,
        currentTarget
      } = evt

      const {
        top,
        left
      } = evt.currentTarget.getBoundingClientRect()

      const conf = this.dragConf
      const ele = currentTarget.cloneNode(true)

      Object.assign(this.dragConf, {
        offsetLeft: clientX - left,
        offsetTop: clientY - top,
        clientX: clientX,
        clientY: clientY,
        info: infoFun(),
        ele,
        isDown: true
      })

      ele.style.position = 'fixed'
      ele.style.margin = '0'
      ele.style.top = clientY - conf.offsetTop + 'px'
      ele.style.left = clientX - conf.offsetLeft + 'px'

      this.$el.appendChild(this.dragConf.ele)
    },
    saveFlow() {
      let flow = JSON.stringify(this.flow)
      flow = JSON.parse(flow)
      flow.flow = this.$refs.superFlow.toJSON()
      this.flowData = flow
      FlowApi.addAPI(flow).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    outputFlow() {
      const a = document.createElement('a');
      a.href = 'data:,' + JSON.stringify(this.$refs.superFlow.toJSON(), null, 4)
      a.download = this.flow.name + '.json'
      a.click()
    },
    inputFlow() {

    },
    release() {
      FlowApi.releaseAPI(this.flow.id).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    run() {
      FlowApi.runAPI(this.flow.id).then(res => {
        if (res.data.code === 200) {
          this.runResult = res.data.data
        } else {
          this.runResult = '错误信息: ' + res.data.msg + "\n代码:\n" + res.data.data
        }
        this.isShowResult = true
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style lang="less">
.node-row {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.node-directory {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  color: #FFFFFF;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.super-flow-demo1 {
  margin-top: 0px;
  width: 100%;
  height: calc(100vh - 124px);
  background-color: #1a202d;
  @list-width : 300px;


  >.node-container {
    width: calc(@list-width - 30px);
    float: left;
    height: calc(100vh - 144px);
    text-align: center;
    background-color: #242b38;
    margin: 10px;
    margin-right: 10px;
    overflow-y: scroll;
  }

  >.flow-container {
    width: calc(100% - @list-width);
    float: left;
    height: 100%;
    overflow: hidden;
  }

  .super-flow__node {
    .flow-node {
      >header {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        margin: 0 12px;
        color: #ffffff;
        position: relative;
      }

      >section {
        text-align: center;
        line-height: 20px;
        overflow: hidden;
        margin: 6px 12px;
        word-break: break-all;
      }

      &.flow-node-shell {
        >header {
          background-color: #55abfc;
        }
      }

      &.flow-node-start {
        >header {
          background-color: rgb(180, 180, 133);
        }
      }

      &.flow-node-route {
        >header {
          background-color: rgba(188, 181, 58, 0.76);
        }
      }

      &.flow-node-component {
        >header {
          background-color: #30b95c;
        }
      }

      &.flow-node-end {
        >header {
          height: 50px;
          line-height: 50px;
          background-color: rgb(0, 0, 0);
        }
      }
    }
  }
}

.node-item {
  @node-item-height : 30px;

  font-size: 14px;
  display: inline-block;
  height: @node-item-height;
  width: 120px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #4d4e51;
  color: #FFFFFF;
  border-radius: 5px;
  line-height: @node-item-height;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  text-align: center;
  z-index: 6;

  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
  }
}

.super-flow {
  background-color: #242b38;
  overflow: auto;
}

.el-divider__text {
  background-color: #1a202d;
  color: #FFFFFF;
}
</style>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function getDeepEasyWindowDataByKey(data, key) {
    let com = null
    if (data.attribute.key === key) {
        return data
    } else {
        if (data.children) {
            for (let index = 0; index < data.children.length; index++) {
                var res = getDeepEasyWindowDataByKey(data.children[index], key);
                if(res) {
                    com = res
                }
            }
        }
    }
    return com
}

function setDeepEasyWindowDataByKey(data, key, value) {
    if (data.attribute.key === key) {
        for (let index = 0; index < Object.keys(value).length; index++) {
            const k = Object.keys(value)[index];
            Vue.set(data, k, value[k]);
        }
    } else {
        if (data.children) {
            for (let index = 0; index < data.children.length; index++) {
                setDeepEasyWindowDataByKey(data.children[index], key, value);
            }
        }
    }
}

const store = new Vuex.Store({
    state: {
        easyLoaderData: {}, // loader返回数据
        easyLoaderSetting: {}, // 页面loader
        easyWindowData: {}, // 页面data
    },
    mutations: {
        setLoaderData: function (state, data) {
            if (data.id in state.easyLoaderData) {
                Vue.set(state.easyLoaderData[data.id], data.key, data.value);
            } else {
                Vue.set(state.easyLoaderData, data.id, {});
                Vue.set(state.easyLoaderData[data.id], data.key, data.value);
            }
        },
        setEasyLoaderSetting: function (state, data) {
            if (data.id in state.easyLoaderSetting) {
                Vue.set(state.easyLoaderSetting, data.id, data.value);
            } else {
                Vue.set(state.easyLoaderSetting, data.id, {});
                Vue.set(state.easyLoaderSetting, data.id, data.value);
            }
        },
        setEasyWindowData: function (state, data) {
            if (data.id in state.easyWindowData) {
                Vue.set(state.easyWindowData, data.id, data.value);
            } else {
                Vue.set(state.easyWindowData, data.id, {});
                Vue.set(state.easyWindowData, data.id, data.value);
            }
        },
        setEasyWindowDataByKey: function (state, data) {
            setDeepEasyWindowDataByKey(
                state.easyWindowData[data.pageId],
                data.key,
                data.value
            );
        },
    },
    getters: {
        getEasyWindowDataByKey: (state) => (pageId, key) => {
            return getDeepEasyWindowDataByKey(state.easyWindowData[pageId], key);
        },
    },
});

export default store;

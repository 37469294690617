import { createEasyPageId as createId } from "../../../ulits";
// import { getEnvCodeTemp } from "../../EasyGlobal";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "树组件",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "树组件",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyTree",
      key: createId(),
      // 组件数据
      data: {
        // 是否显示多选
        showCheckbox: true,
        // 数据映射
        defaultProps: {
          children: "children",
          label: "label",
        },
        // 组件值
        value: [
          {
            id: "1",
            label: "一标题",
            children: [
              {
                id: "2",
                label: "二标题",
              },
            ],
          },
        ],
        treeIcons: [
          {
            isShow: true,
            icon: "el-icon-delete",
            title: "删除",
            iconStyle: { color: "#000000", fontSize: "14px" },
            titleStyle: { color: "#000000", fontSize: "14px" },
            event: {
              enable: true,
              codeJs: "// 传入参数：icon, node\n" + "\nalert('点击了删除')",
            },
          },
        ],
        // 组件标签
        label: {
          isShow: false,
          value: "Label:",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "4px",
            left: "0px",
            position: "relative",
            width: "60px",
          },
        },
        // 组件样式
        cStyle: {
          height: "auto",
          width: "auto",
        },
        events: {
          titleEvent: {
            enable: true,
            codeJs: "// 传入参数：node\n" + "alert('标题')",
          },
          checkChangeEvent: {
            enable: true,
            codeJs: "// 传入参数：node\n" + "alert('多选框')",
          },
        },
      },
      // 容器样式
      cStyle: {
        fontSize: "14px",
        height: "auto",
        width: "auto",
        background: "rgba(255, 255, 255, 0)",
        color: "#ffffff",
      },
      // 行样式
      rowStyle: { color: "#000000", fontSize: "14px", width: "200px" },
      // 标题样式
      titleStyle: { color: "#000000", fontSize: "14px", width: "200px" },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

<template>
    <div :style="boxStyle">
        <div class="start-box"
            v-for="(item, index) in Object.entries(attribute).sort(function (a) { if (typeof a[1] === 'object') { return 1 } else { return -1 } })"
            :key="index" style="margin-top: 8px;width: 100%;">
            <!-- 标签 -->
            <div v-show="!noShowKeys.includes(item[0]) && !item[0].endsWith('Style')"
                style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explian(item[0]) }}
            </div>
            <!-- 内容 -->
            <div style="width: 100%;">
                <!-- 树图标 -->
                <div v-if="item[0].endsWith('treeIcons')">
                    <div v-for="(icon, index) in attribute[item[0]]" style=" width: 100%;" :key="index">
                        <div class="c-editer-label-style"> {{ icon.title }} </div>
                        <DataEditer :pKey="pKey + '.' + item[0] + '.' + index"
                            :updateAttributeData="updateAttributeData" :data="icon" :noShowKeys="noShowKeys"
                            :boxStyle="{ minHeight: '40px', width: '100%', overflow: 'auto'}">
                        </DataEditer>
                    </div>
                </div>
                <!-- 动作 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'actions'">
                    <div class="c-editer-label-style">{{ explian(item[0]) }}</div>
                    <ActionsEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </ActionsEditer>
                </div>
                <!-- 样式 -->
                <div style=" width: 100%;" v-else-if="item[0].endsWith('Style')">
                    <div class="c-editer-label-style">{{ explian(item[0], "组件样式") }}</div>
                    <StyleEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </StyleEditer>
                </div>
                <!-- 标签 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'label'">
                    <div class="c-editer-label-style">组件标签属性</div>
                    <LabelEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </LabelEditer>
                </div>
                <!-- Echart -->
                <div style=" width: 100%;" v-else-if="item[0].endsWith('echart')">
                    <div class="c-editer-label-style">图表属性 </div>
                    <EchartsEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </EchartsEditer>
                </div>
                <!-- 事件 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'events'">
                    <!-- <div v-if="item[0] === 'events'" class="c-editer-label-style">组件事件</div> -->
                    <EventEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </EventEditer>
                </div>
                <!-- 其他 -->
                <InputEditer v-else :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                    v-show="!noShowKeys.includes(item[0]) && !item[0].endsWith('Style')"
                    :data="{ value: attribute[item[0]] }" :boxStyle="{}"></InputEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import DataEditer from './DataEditer';
import EventEditer from './EventEditer';
import StyleEditer from './StyleEditer';
import LabelEditer from './LabelEditer';
import InputEditer from './InputEditer';
import EchartsEditer from './EchartsEditer';
import ActionsEditer from '../components/form/Submit/ActionsEditer';

export default {
    name: 'DataEditer',
    props: { data: Object, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
        EventEditer, StyleEditer, LabelEditer, InputEditer, ActionsEditer, EchartsEditer, DataEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {},
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        explian(key, defaultValue) {
            var str = attributeMap[key]
            if (str) {
                return attributeMap[key]
            } else {
                if (defaultValue) {
                    return defaultValue
                }
                return defaultValue
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

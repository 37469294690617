<template>
  <div class="base-box margin-box page-right">
    <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">URL编码</div>
    <el-input size="mini" type="textarea" :rows="5" placeholder="请输入编码后的URL" v-model="unDecode"
      @blur="handleUnDecodeBlur">
    </el-input>
    <span>解码结果:</span>
    <el-input size="mini" type="textarea" :rows="10" placeholder="请输入解编后的URL" v-model="decode" @blur="handleDecodeBlur">
    </el-input>
  </div>
</template>

<script>

export default {
  name: 'urlPage',
  data() {
    return {
      decode: '',
      unDecode: '',
    }
  },
  methods: {
    handleUnDecodeBlur() {
      this.decode = decodeURI(this.unDecode)
    },
    handleDecodeBlur() {
      this.unDecode = encodeURI(this.decode)
    }
  },
}
</script>

<style></style>
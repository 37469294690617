import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "通用Echart",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "通用Echart",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyChart",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: "line",
            },
          ],
        },
        type: "info",
        // 组件标签
        label: {
          isShow: false,
          value: "标签",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "9px",
            left: "0px",
            position: "relative",
          },
        },
        // 组件样式
        cStyle: {
          width: "100%",
          height: "320px",
          color: "rgba(255, 255, 255, 0)",
          minHeight: "50px",
          fontSize: "14px",
          background: "rgba(255, 255, 255, 0)",
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(255, 255, 255, 0)",
        width: "100%",
        fontSize: "14px",
        height: "auto",
        background: "rgba(255, 255, 255, 0)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

const cssMap = {
  "align-content": {
    selectList: [
      { value: "stretch", label: "元素被拉伸以适应容器" },
      { value: "center", label: "元素位于容器的中心" },
      { value: "flex-start", label: "元素位于容器的开头" },
      { value: "flex-end", label: "元素位于容器的结尾" },
      { value: "space-between", label: "平均分布" },
      { value: "space-around", label: "平均排例" },
      { value: "initial", label: "默认值" },
      { value: "inherit", label: "继承父元素" },
    ],
    label: "内容对齐",
    valueType: "list",
    unit: "",
  },
  "align-items": {
    selectList: [],
    label: "项目对齐",
    valueType: "string",
    unit: "",
  },
  "align-self": {
    selectList: [],
    label: "自身对齐",
    valueType: "string",
    unit: "",
  },
  animation: { selectList: [], label: "动画", valueType: "string", unit: "" },
  "animation-delay": {
    selectList: [],
    label: "动画延迟",
    valueType: "string",
    unit: "",
  },
  "animation-direction": {
    selectList: [],
    label: "动画方向",
    valueType: "string",
    unit: "",
  },
  "animation-duration": {
    selectList: [],
    label: "动画持续时间",
    valueType: "string",
    unit: "",
  },
  "animation-fill-mode": {
    selectList: [],
    label: "动画填充模式",
    valueType: "string",
    unit: "",
  },
  "animation-iteration-count": {
    selectList: [],
    label: "动画迭代次数",
    valueType: "string",
    unit: "",
  },
  "animation-name": {
    selectList: [],
    label: "动画名称",
    valueType: "string",
    unit: "",
  },
  "animation-play-state": {
    selectList: [],
    label: "动画播放状态",
    valueType: "string",
    unit: "",
  },
  "animation-timing-function": {
    selectList: [],
    label: "动画时间函数",
    valueType: "string",
    unit: "",
  },
  "backface-visibility": {
    selectList: [],
    label: "背面可见性",
    valueType: "string",
    unit: "",
  },
  background: { selectList: [], label: "背景", valueType: "color", unit: "" },
  "background-attachment": {
    selectList: [],
    label: "背景附着",
    valueType: "string",
    unit: "",
  },
  "background-blur": {
    selectList: [],
    label: "背景模糊",
    valueType: "string",
    unit: "",
  },
  "background-clip": {
    selectList: [],
    label: "背景裁剪",
    valueType: "string",
    unit: "",
  },
  "background-color": {
    selectList: [],
    label: "背景颜色",
    valueType: "color",
    unit: "",
  },
  "background-image": {
    selectList: [],
    label: "背景图片",
    valueType: "image",
    unit: "",
  },
  "background-origin": {
    selectList: [],
    label: "背景起点",
    valueType: "string",
    unit: "",
  },
  "background-position": {
    selectList: [
      { value: "left top", label: "左上" },
      { value: "left center", label: "左中" },
      { value: "left bottom", label: "左下" },
      { value: "right top", label: "右上" },
      { value: "right center", label: "右中" },
      { value: "right bottom", label: "右下" },
      { value: "center", label: "居中" },
      { value: "center bottom", label: "中下" },
    ],
    label: "背景位置",
    valueType: "list",
    unit: "",
  },
  "background-repeat": {
    selectList: [
      { value: "repeat", label: "重复" },
      { value: "repeat-x", label: "水平重复" },
      { value: "repeat-y", label: "垂直重复" },
      { value: "no-repeat", label: "不重复" },
      { value: "inherit", label: "继承父元素" },
    ],
    label: "背景重复",
    valueType: "list",
    unit: "",
  },
  "background-size": {
    selectList: [
      { value: "cover", label: "全覆盖背景" },
      { value: "contain", label: "适合背景" },
      { value: "10%", label: "宽度10%" },
      { value: "20%", label: "宽度20%" },
      { value: "30%", label: "宽度30%" },
      { value: "40%", label: "宽度40%" },
      { value: "50%", label: "宽度50%" },
      { value: "60%", label: "宽度60%" },
      { value: "70%", label: "宽度70%" },
      { value: "80%", label: "宽度80%" },
      { value: "90%", label: "宽度90%" },
      { value: "100%", label: "宽度100%" },
    ],
    label: "背景大小",
    valueType: "list",
    unit: "",
  },
  border: { selectList: [], label: "边框", valueType: "string", unit: "" },
  "border-bottom": {
    selectList: [],
    label: "下边框",
    valueType: "string",
    unit: "",
  },
  "border-bottom-color": {
    selectList: [],
    label: "下边框颜色",
    valueType: "color",
    unit: "",
  },
  "border-bottom-left-radius": {
    selectList: [],
    label: "左下边框圆角",
    valueType: "string",
    unit: "",
  },
  "border-bottom-right-radius": {
    selectList: [],
    label: "右下边框圆角",
    valueType: "string",
    unit: "",
  },
  "border-bottom-style": {
    selectList: [],
    label: "下边框样式",
    valueType: "string",
    unit: "",
  },
  "border-bottom-width": {
    selectList: [],
    label: "下边框宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-collapse": {
    selectList: [],
    label: "边框合并",
    valueType: "string",
    unit: "",
  },
  "border-color": {
    selectList: [],
    label: "边框颜色",
    valueType: "color",
    unit: "",
  },
  "border-left": {
    selectList: [],
    label: "左边框",
    valueType: "string",
    unit: "",
  },
  "border-left-color": {
    selectList: [],
    label: "左边框颜色",
    valueType: "color",
    unit: "",
  },
  "border-left-style": {
    selectList: [],
    label: "左边框样式",
    valueType: "string",
    unit: "",
  },
  "border-left-width": {
    selectList: [],
    label: "左边框宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-radius": {
    selectList: [],
    label: "边框圆角",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-right": {
    selectList: [],
    label: "右边框",
    valueType: "string",
    unit: "",
  },
  "border-right-color": {
    selectList: [],
    label: "右边框颜色",
    valueType: "color",
    unit: "",
  },
  "border-right-style": {
    selectList: [],
    label: "右边框样式",
    valueType: "string",
    unit: "",
  },
  "border-right-width": {
    selectList: [],
    label: "右边框宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-spacing": {
    selectList: [],
    label: "边框间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-style": {
    selectList: [],
    label: "边框样式",
    valueType: "string",
    unit: "",
  },
  "border-top": {
    selectList: [],
    label: "上边框",
    valueType: "string",
    unit: "",
  },
  "border-top-color": {
    selectList: [],
    label: "上边框颜色",
    valueType: "color",
    unit: "",
  },
  "border-top-left-radius": {
    selectList: [],
    label: "左上边框圆角",
    valueType: "string",
    unit: "",
  },
  "border-top-right-radius": {
    selectList: [],
    label: "右上边框圆角",
    valueType: "string",
    unit: "",
  },
  "border-top-style": {
    selectList: [],
    label: "上边框样式",
    valueType: "string",
    unit: "",
  },
  "border-top-width": {
    selectList: [],
    label: "上边框宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "border-width": {
    selectList: [],
    label: "边框宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  bottom: {
    selectList: [],
    label: "底部",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "box-shadow": {
    selectList: [],
    label: "盒子阴影",
    valueType: "string",
    unit: "",
  },
  "box-sizing": {
    selectList: [],
    label: "盒模型",
    valueType: "string",
    unit: "",
  },
  "caption-side": {
    selectList: [],
    label: "表格标题位置",
    valueType: "string",
    unit: "",
  },
  clear: { selectList: [], label: "清除浮动", valueType: "string", unit: "" },
  clip: { selectList: [], label: "裁剪", valueType: "string", unit: "" },
  color: { selectList: [], label: "颜色", valueType: "color", unit: "" },
  "column-count": {
    selectList: [],
    label: "列数",
    valueType: "string",
    unit: "",
  },
  "column-fill": {
    selectList: [],
    label: "列填充",
    valueType: "string",
    unit: "",
  },
  "column-gap": {
    selectList: [],
    label: "列间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "column-rule": {
    selectList: [],
    label: "列规则",
    valueType: "string",
    unit: "",
  },
  "column-rule-color": {
    selectList: [],
    label: "列规则颜色",
    valueType: "color",
    unit: "",
  },
  "column-rule-style": {
    selectList: [],
    label: "列规则样式",
    valueType: "string",
    unit: "",
  },
  "column-rule-width": {
    selectList: [],
    label: "列规则宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "column-span": {
    selectList: [],
    label: "列跨度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "column-width": {
    selectList: [],
    label: "列宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  columns: { selectList: [], label: "多列布局", valueType: "string", unit: "" },
  content: { selectList: [], label: "内容", valueType: "string", unit: "" },
  "counter-increment": {
    selectList: [],
    label: "计数器递增",
    valueType: "string",
    unit: "",
  },
  "counter-reset": {
    selectList: [],
    label: "计数器重置",
    valueType: "string",
    unit: "",
  },
  cursor: { selectList: [], label: "鼠标指针", valueType: "string", unit: "" },
  direction: {
    selectList: [],
    label: "文本方向",
    valueType: "string",
    unit: "",
  },
  display: {
    selectList: [
      { value: "none", label: "不会被显示" },
      { value: "block", label: "显示为块级元素" },
      { value: "inline", label: "默认值" },
      { value: "flex", label: "灵活布局" },
      { value: "grid", label: "网络布局" },
    ],
    label: "显示",
    valueType: "list",
    unit: "",
  },
  "empty-cells": {
    selectList: [],
    label: "空单元格",
    valueType: "string",
    unit: "",
  },
  filter: { selectList: [], label: "滤镜", valueType: "string", unit: "" },
  flex: { selectList: [], label: "弹性布局", valueType: "string", unit: "" },
  "flex-basis": {
    selectList: [],
    label: "弹性基准",
    valueType: "string",
    unit: "",
  },
  "flex-direction": {
    selectList: [],
    label: "弹性方向",
    valueType: "string",
    unit: "",
  },
  "flex-flow": {
    selectList: [],
    label: "弹性流",
    valueType: "string",
    unit: "",
  },
  "flex-grow": {
    selectList: [],
    label: "弹性增长",
    valueType: "string",
    unit: "",
  },
  "flex-shrink": {
    selectList: [],
    label: "弹性收缩",
    valueType: "string",
    unit: "",
  },
  "flex-wrap": {
    selectList: [],
    label: "弹性换行",
    valueType: "string",
    unit: "",
  },
  float: { selectList: [], label: "浮动", valueType: "string", unit: "" },
  font: { selectList: [], label: "字体", valueType: "string", unit: "" },
  "font-family": {
    selectList: [],
    label: "字体系列",
    valueType: "string",
    unit: "",
  },
  "font-size": {
    selectList: [],
    label: "字体大小",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "font-style": {
    selectList: [],
    label: "字体样式",
    valueType: "string",
    unit: "",
  },
  "font-variant": {
    selectList: [],
    label: "字体变体",
    valueType: "string",
    unit: "",
  },
  "font-weight": {
    selectList: [],
    label: "字体粗细",
    valueType: "string",
    unit: "",
  },
  gap: { selectList: [], label: "间隙", valueType: "string", unit: "" },
  grid: { selectList: [], label: "网格布局", valueType: "string", unit: "" },
  "grid-area": {
    selectList: [],
    label: "网格区域",
    valueType: "string",
    unit: "",
  },
  "grid-auto-columns": {
    selectList: [],
    label: "自动网格列",
    valueType: "string",
    unit: "",
  },
  "grid-auto-flow": {
    selectList: [],
    label: "自动网格流",
    valueType: "string",
    unit: "",
  },
  "grid-auto-rows": {
    selectList: [],
    label: "自动网格行",
    valueType: "string",
    unit: "",
  },
  "grid-column": {
    selectList: [],
    label: "网格列",
    valueType: "string",
    unit: "",
  },
  "grid-column-end": {
    selectList: [],
    label: "网格列结束",
    valueType: "string",
    unit: "",
  },
  "grid-column-gap": {
    selectList: [],
    label: "网格列间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "grid-column-start": {
    selectList: [],
    label: "网格列开始",
    valueType: "string",
    unit: "",
  },
  "grid-gap": {
    selectList: [],
    label: "网格间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "grid-row": {
    selectList: [],
    label: "网格行",
    valueType: "string",
    unit: "",
  },
  "grid-row-end": {
    selectList: [],
    label: "网格行结束",
    valueType: "string",
    unit: "",
  },
  "grid-row-gap": {
    selectList: [],
    label: "网格行间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "grid-row-start": {
    selectList: [],
    label: "网格行开始",
    valueType: "string",
    unit: "",
  },
  "grid-template": {
    selectList: [],
    label: "网格模板",
    valueType: "string",
    unit: "",
  },
  "grid-template-areas": {
    selectList: [],
    label: "网格模板区域",
    valueType: "string",
    unit: "",
  },
  "grid-template-columns": {
    selectList: [],
    label: "网格模板列",
    valueType: "string",
    unit: "",
  },
  "grid-template-rows": {
    selectList: [],
    label: "网格模板行",
    valueType: "string",
    unit: "",
  },
  height: {
    selectList: [],
    label: "高度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "justify-content": {
    selectList: [],
    label: "主轴对齐",
    valueType: "string",
    unit: "",
  },
  left: {
    selectList: [],
    label: "左侧",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "letter-spacing": {
    selectList: [],
    label: "字间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "line-height": {
    selectList: [],
    label: "行高",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "list-style": {
    selectList: [],
    label: "列表样式",
    valueType: "string",
    unit: "",
  },
  "list-style-image": {
    selectList: [],
    label: "列表样式图片",
    valueType: "string",
    unit: "",
  },
  "list-style-position": {
    selectList: [],
    label: "列表样式位置",
    valueType: "string",
    unit: "",
  },
  "list-style-type": {
    selectList: [],
    label: "列表样式类型",
    valueType: "string",
    unit: "",
  },
  margin: { selectList: [], label: "外边距", valueType: "number", unit: "" },
  "margin-bottom": {
    selectList: [],
    label: "下外边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "margin-left": {
    selectList: [],
    label: "左外边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "margin-right": {
    selectList: [],
    label: "右外边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "margin-top": {
    selectList: [],
    label: "上外边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "max-height": {
    selectList: [],
    label: "最大高度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "max-width": {
    selectList: [],
    label: "最大宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "min-height": {
    selectList: [],
    label: "最小高度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "min-width": {
    selectList: [],
    label: "最小宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "object-fit": {
    selectList: [],
    label: "对象适应",
    valueType: "string",
    unit: "",
  },
  "object-position": {
    selectList: [],
    label: "对象位置",
    valueType: "string",
    unit: "",
  },
  opacity: { selectList: [], label: "透明度", valueType: "string", unit: "" },
  order: { selectList: [], label: "排序", valueType: "string", unit: "" },
  outline: { selectList: [], label: "轮廓", valueType: "string", unit: "" },
  "outline-color": {
    selectList: [],
    label: "轮廓颜色",
    valueType: "color",
    unit: "",
  },
  "outline-offset": {
    selectList: [],
    label: "轮廓偏移",
    valueType: "string",
    unit: "",
  },
  "outline-style": {
    selectList: [],
    label: "轮廓样式",
    valueType: "string",
    unit: "",
  },
  "outline-width": {
    selectList: [],
    label: "轮廓宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  overflow: { selectList: [], label: "溢出", valueType: "string", unit: "" },
  "overflow-x": {
    selectList: [],
    label: "水平溢出",
    valueType: "string",
    unit: "",
  },
  "overflow-y": {
    selectList: [],
    label: "垂直溢出",
    valueType: "string",
    unit: "",
  },
  padding: { selectList: [], label: "内边距", valueType: "number", unit: "" },
  "padding-bottom": {
    selectList: [],
    label: "下内边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "padding-left": {
    selectList: [],
    label: "左内边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "padding-right": {
    selectList: [],
    label: "右内边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "padding-top": {
    selectList: [],
    label: "上内边距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "page-break-after": {
    selectList: [],
    label: "分页后",
    valueType: "string",
    unit: "",
  },
  "page-break-before": {
    selectList: [],
    label: "分页前",
    valueType: "string",
    unit: "",
  },
  "page-break-inside": {
    selectList: [],
    label: "分页内",
    valueType: "string",
    unit: "",
  },
  perspective: { selectList: [], label: "透视", valueType: "string", unit: "" },
  "perspective-origin": {
    selectList: [],
    label: "透视原点",
    valueType: "string",
    unit: "",
  },
  position: {
    selectList: [
      { value: "static", label: "静态定位" },
      { value: "relative", label: "相对定位" },
      { value: "absolute", label: "绝对定位" },
      { value: "fixed", label: "固定定位" },
    ],
    label: "定位",
    valueType: "list",
    unit: "",
  },
  quotes: { selectList: [], label: "引号", valueType: "string", unit: "" },
  resize: {
    selectList: [],
    label: "调整大小",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  right: {
    selectList: [],
    label: "右侧",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "row-gap": { selectList: [], label: "行间距", valueType: "number", unit: "" },
  "scroll-behavior": {
    selectList: [],
    label: "滚动行为",
    valueType: "string",
    unit: "",
  },
  "tab-size": {
    selectList: [],
    label: "制表符大小",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "table-layout": {
    selectList: [],
    label: "表格布局",
    valueType: "string",
    unit: "",
  },
  "text-align": {
    selectList: [],
    label: "文本对齐",
    valueType: "string",
    unit: "",
  },
  "text-decoration": {
    selectList: [],
    label: "文本装饰",
    valueType: "string",
    unit: "",
  },
  "text-indent": {
    selectList: [],
    label: "文本缩进",
    valueType: "string",
    unit: "",
  },
  "text-justify": {
    selectList: [],
    label: "文本对齐方式",
    valueType: "string",
    unit: "",
  },
  "text-overflow": {
    selectList: [],
    label: "文本溢出",
    valueType: "string",
    unit: "",
  },
  "text-shadow": {
    selectList: [],
    label: "文本阴影",
    valueType: "string",
    unit: "",
  },
  "text-transform": {
    selectList: [],
    label: "文本转换",
    valueType: "string",
    unit: "",
  },
  top: {
    selectList: [],
    label: "顶部",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  transform: { selectList: [], label: "变换", valueType: "string", unit: "" },
  "transform-origin": {
    selectList: [],
    label: "变换原点",
    valueType: "string",
    unit: "",
  },
  "transform-style": {
    selectList: [],
    label: "变换样式",
    valueType: "string",
    unit: "",
  },
  transition: { selectList: [], label: "过渡", valueType: "string", unit: "" },
  "transition-delay": {
    selectList: [],
    label: "过渡延迟",
    valueType: "string",
    unit: "",
  },
  "transition-duration": {
    selectList: [],
    label: "过渡持续时间",
    valueType: "string",
    unit: "",
  },
  "transition-property": {
    selectList: [],
    label: "过渡属性",
    valueType: "string",
    unit: "",
  },
  "transition-timing-function": {
    selectList: [],
    label: "过渡时间函数",
    valueType: "string",
    unit: "",
  },
  "unicode-bidi": {
    selectList: [],
    label: "Unicode双向",
    valueType: "string",
    unit: "",
  },
  "user-select": {
    selectList: [],
    label: "用户选择",
    valueType: "string",
    unit: "",
  },
  "vertical-align": {
    selectList: [],
    label: "垂直对齐",
    valueType: "string",
    unit: "",
  },
  visibility: {
    selectList: [],
    label: "可见性",
    valueType: "string",
    unit: "",
  },
  "white-space": {
    selectList: [],
    label: "空白处理",
    valueType: "string",
    unit: "",
  },
  width: {
    selectList: [],
    label: "宽度",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "word-break": {
    selectList: [],
    label: "单词换行",
    valueType: "string",
    unit: "",
  },
  "word-spacing": {
    selectList: [],
    label: "单词间距",
    valueType: "number",
    unit: ["px", "%", "auto"],
  },
  "word-wrap": {
    selectList: [],
    label: "单词换行",
    valueType: "string",
    unit: "",
  },
  "writing-mode": {
    selectList: [],
    label: "书写模式",
    valueType: "string",
    unit: "",
  },
  "z-index": { selectList: [], label: "层级", valueType: "number", unit: "" },
};

export default cssMap;

<template>
    <div :style="data.designStyle" @mouseover.stop="evt => onComponentSelect(data.id, evt)"
        @mouseout.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)">
            <div class="start-box">
                <div v-show="data.attribute.data.label.isShow" :style="data.attribute.data.label.cStyle">{{
                    data.attribute.data.label.value }}</div>
                <!-- 自增组件主要内容 -->
                <div v-if="isDesign" :ref="data.attribute.key" :id="data.id" :style="data.attribute.data.cStyle">
                    <component v-for="(item, index) in this.data.children" :key="index" :is="item.attribute.component"
                        :data="item" :onComponentSelect="onComponentSelect" :onComponentMouseDown="onComponentMouseDown">
                    </component>
                </div>
                <div v-else :ref="data.attribute.key" :id="data.id">
                    <div v-for="(components, index) in childrens" :key="index" :style="data.attribute.data.cStyle">
                        <component v-for="(item, index) in components" :key="index" :is="item.attribute.component"
                            :data="item" :onComponentSelect="onComponentSelect"
                            :onComponentMouseDown="onComponentMouseDown">
                        </component>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDesign" style="height: 60px;" class="center-box"
            @mouseover.stop="evt => onComponentSelect(data.id, evt)">
            <div style="color: rgb(106, 115, 122); font-size: 14px; margin-top: 18px;">拖动组件到 [自增组件]</div>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'

export default {
    name: "EasyFloat",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            value: "",
            pageId: null,
            childrens: [],
            isDesign: true,
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
                this.setChildrenValue()
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.pageId = EasyUiUlits.getEasyPageId()
        this.isDesign = EasyUiUlits.isDesign()
        this.value = this.data.attribute.data.value
        this.setChildrenValue()
    },
    methods: {
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        },
        setChildrenValue() {
            this.childrens = []
            for (let index = 0; index < this.value.length; index++) {
                var data = this.value[index]
                var TempChildrens = JSON.parse(JSON.stringify(this.data.children))
                for (let childrenIndex = 0; childrenIndex < TempChildrens.length; childrenIndex++) {
                    this.setValue(TempChildrens[childrenIndex], data, index)
                }
                this.childrens.push(TempChildrens)
            }
        },
        setValue(component, data, index) {
            var oldKey = component.attribute.key
            component.id = EasyUiUlits.createEasyPageId()
            component.attribute.key = index + "." + oldKey
            if (Object.keys(this.value[index]).includes(oldKey)) {
                component.attribute.data.value = data[oldKey]
            }
            if (component.children) {
                for (let childrenIndex = 0; childrenIndex < component.children.length; childrenIndex++) {
                    this.setValue(component.children[childrenIndex], data, index)
                }
            }
        },
    }
};
</script>

<style scoped>
.test {
    font-size: 14px;
    position: relative;
}
</style>

<template>
    <div :style="boxStyle">
        <el-dialog title="添加样式" :visible.sync="addVisible" width="30%">
            <!-- <el-input size="mini" placeholder="请输入内容" v-model="newStyle" clearable></el-input> -->
            <el-select size="mini" v-model="newStyle" filterable placeholder="请选择" style="width: 100%;">
                <el-option v-for="item in cssOptions" :key="item.key" :label="item.label" :value="item.key">
                </el-option>
            </el-select>
            <div class="center-box" style="margin-top: 16px;">
                <el-button type="primary" size="mini" @click="addStyle()">确定</el-button>
            </div>
        </el-dialog>
        <!-- 标题 -->
        <div class="start-box" v-for="(key, index) in Object.keys(attribute)" :key="index"
            style="margin-top: 8px; width: 100%;">
            <!-- 标签 -->
            <div v-if="!noShowKeys.includes(key)"
                style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explianCss(key) }}
            </div>
            <!-- 内容 -->
            <div>
                <CssEditer :pKey="pKey + '.' + key" :updateAttributeData="updateAttributeData"
                    v-if="!noShowKeys.includes(key)" :data="{ value: attribute[key] }" :boxStyle="{}" :css="getCss(key)">
                </CssEditer>
            </div>
            <div style="margin-left: 8px;">
                <el-button type="text" size="mini" @click="deleteStyle(key)">删除</el-button>
            </div>
        </div>
        <div class="center-box">
            <el-button type="text" size="mini" @click="addVisible = !addVisible">添加</el-button>
        </div>
    </div>
</template>

<script>
import cssMap from '../cssMap.js';
import attributeMap from '../attributeMap';
import CssEditer from './CssEditer';


export default {
    name: 'StyleEditer',
    props: { data: Object, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
        CssEditer
    },
    data() {
        return {
            attribute: {},
            newStyle: '',
            addVisible: false,
            cssOptions: []
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
        this.initCssOptions()
    },
    watch: {
        "data": {
            handler: function (val) {
                // console.log(val, 'val')
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    methods: {
        initCssOptions() {
            Object.keys(cssMap).forEach(key => {
                this.cssOptions.push({ key: key, label: cssMap[key].label })
            });
        },
        explian(key) {
            var str = attributeMap[key]
            if(str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
        explianCss(key) {
            key = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
            return cssMap[key].label
        },
        getCss(key) {
            key = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
            return cssMap[key]
        },
        addStyle() {
            this.updateAttributeData(this.pKey + '.' + this.newStyle, '')
            this.addVisible = !this.addVisible
        },
        deleteStyle(key) {
            this.updateAttributeData(this.pKey + '.' + key, '', true)
        }
    }
}
</script>

<style scoped></style>
<template>
  <div class="base-box margin-box page-right">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div class="center-box">
        <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="onUploadSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="tmpImgUrl" :src="tmpImgUrl" class="avatar">
          <img v-else src="@/assets/images/system/project-icon.png" class="avatar">
        </el-upload>
      </div>
      <el-input style="margin-top: 24px;" size="mini" placeholder="请输入项目名" v-model="project.name">
      </el-input>
      <el-input style="margin-top: 24px;" size="mini" placeholder="请输入项目说明" v-model="project.description">
      </el-input>
      <div class="center-box" style="margin-top: 24px;">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addProject">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="成员管理" :visible.sync="userDialogVisible" width="80%" center>
      <div class="center-box">
        <el-transfer v-model="selectUsers" :data="users" :props="{ key: 'id', label: 'username' }"
          :titles="['未选中', '选中']">
          <span class="between-box" slot-scope="{ option }">
            <span>
              {{ option.username }}
            </span>
            <el-date-picker v-model="option.validityDate" type="datetime" size="mini" style="width: 124px;"
              format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="有效期" align="right"
              :picker-options="pickerOptions">
            </el-date-picker>
            <el-select size="mini" style="width: 80px;" v-model="option.status" placeholder="状态">
              <el-option v-for="item in statusType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </span>
        </el-transfer>
      </div>
      <div class="center-box">
        <el-button type="primary" @click="updateProjectUser" style="width: 140px; margin-top: 24px;">更新</el-button>
      </div>
    </el-dialog>

    <!-- 项目工具栏 -->
    <div class="project-header-box">
      <div class="between-box">
        <div>
          <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </div>
        <div>
          <input id="file" type="file" @change="onFileChange" v-show="false" />
          <el-button type="primary" size="mini" @click="onInputProject">导入项目</el-button>
          <el-button type="primary" size="mini" @click="onAddPriject">添加项目</el-button>
        </div>
      </div>
    </div>

    <!-- 项目列表 -->
    <div class="base-box content-box">
      <div class="base-box start-wrap-box">
        <div v-for="(item, index) in projects" :key="index">
          <div class="project-box" v-if="filterText === '' || item.name.indexOf(filterText) !== -1">
            <div style="background: #409EFF; height: 80px; border-radius: 5px; color: aliceblue;">
              <div>
                <img :src="getImgUrl(item.img)" alt="" @click="jump(item, 'client')"
                  style="border-radius: 5px; width: 50px; height: 50px; position: absolute; top: 20px; left: 16px;">
                <div style="border: 1px solid red; 
                          width: 16px; 
                          height: 16px; 
                          border-radius: 50%; 
                          background: red;
                          position: absolute;
                          top: 12px;
                          left: 56px;
                          " @click="jump(item, 'scheduler')">
                  <div style="position: absolute; left: 3px; top: -3px;">{{ item.schedulerNumber }}</div>
                </div>
              </div>
              <div style="position: absolute; top: 14px; left: 80px; font-size: 20px; max-width: 100px;"
                @click="jump(item, 'client')" class="ellipsis">{{
                  item.name }}</div>
              <div style="position: absolute; top: 42px; left: 80px; font-size: 10px;" @click="jump(item, 'client')">
                创建用户: {{ item.createUserName }}
              </div>
              <div style="position: absolute; top: 58px; left: 80px; font-size: 10px;" @click="jump(item, 'client')">
                创建时间: {{ item.updateTime }}
              </div>
              <i style="position: absolute; top: 22px; left: 320px; color: red; font-size: 22px;" class="el-icon-close"
                @click="onDelProject(item)"></i>
              <i style="position: absolute; top: 22px; left: 290px; font-size: 20px;" class="el-icon-download"
                @click="onExport(item)"></i>
              <i style="position: absolute; top: 22px; left: 260px; font-size: 18px;" class="el-icon-edit"
                @click="onEdit(item)"></i>
              <i style="position: absolute; top: 22px; left: 230px; font-size: 18px;" class="el-icon-user"
                @click="getUserList(item)"></i>
              <i style="position: absolute; top: 22px; left: 200px; font-size: 18px;" class="el-icon-document"
                @click="test(item.id)"></i>
            </div>
            <div class="between-wrap-box" style="position: absolute; top: 90px;">
              <div class="item-box" @click="jump(item, 'client')">
                <div class="center-box">
                  <div style="font-size: 16px;">接口</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.apiNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'aop')">
                <div class="center-box">
                  <div style="font-size: 16px;">拦截器</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.aopNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'res')">
                <div class="center-box">
                  <div style="font-size: 16px;">修饰器</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.responseNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'source')">
                <div class="center-box">
                  <div style="font-size: 16px;">数据源</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.sourceNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'config')">
                <div class="center-box">
                  <div style="font-size: 16px;">配置项</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.configNumber }}</div>
                </div>
              </div>
              <!-- <div class="item-box" @click="jump(item, 'policy')">
                <div class="center-box">
                  <div style="font-size: 16px;">策略</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.policyNumber }}</div>
                </div>
              </div> -->
              <div class="item-box" @click="jump(item, 'comp')">
                <div class="center-box">
                  <div style="font-size: 16px;">组件</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.componentNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'func')">
                <div class="center-box">
                  <div style="font-size: 16px;">函数</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.functionNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'flow')">
                <div class="center-box">
                  <div style="font-size: 16px;">流程</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.flowNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'model')">
                <div class="center-box">
                  <div style="font-size: 16px;">模型</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.modelNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'map')">
                <div class="center-box">
                  <div style="font-size: 16px;">字典</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.mapNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'file')">
                <div class="center-box">
                  <div style="font-size: 16px;">文件</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.fileNumber }}</div>
                </div>
              </div>
              <div class="item-box" @click="jump(item, 'easypage')">
                <div class="center-box">
                  <div style="font-size: 16px;">页面</div>
                </div>
                <div class="center-box">
                  <div style="font-size: 16px; margin-top: 8px;">{{ item.easyPageNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getListAPI,
  addAPI,
  deleteAPI,
  exportAPI,
  inputAPI
} from "@/utils/apis/dev/projectApi"
import * as projectUserApi from "@/utils/apis/dev/projectUserApi"
import * as userApi from "@/utils/apis/user/userApi"
import { downloadData } from '@/tools/file'

export default {
  name: 'projectPage',
  components: {
  },
  data() {
    return {
      projects: [],
      project: {
        id: null,
        name: '',
        img: '',
        description: '',
        createTime: '',
        updateTiem: '',
        createUser: '',
      },
      filterText: '',
      dialogVisible: false,
      uploadUrl: '/api/upload?token=' + localStorage.getItem('token'),
      tmpImgUrl: '',
      userDialogVisible: false,
      selectUsers: [],
      users: [],
      statusType: [
        {
          value: 'enable',
          label: '启用'
        }, {
          value: 'disable',
          label: '禁用'
        },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '一周',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + (3600 * 1000 * 24 * 7));
            picker.$emit('pick', date);
          }
        }, {
          text: '一月',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + (3600 * 1000 * 24 * 30));
            picker.$emit('pick', date);
          }
        }, {
          text: '一年',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + (3600 * 1000 * 24 * 30 * 12));
            picker.$emit('pick', date);
          }
        }, {
          text: '一百年',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + (3600 * 1000 * 24 * 30 * 12 * 100));
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterProject() {

    },
    getList() {
      getListAPI().then(res => {
        if (res.data.code === 200) {
          this.projects = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onAddPriject() {
      this.dialogVisible = true
    },
    addProject() {
      console.log(this.project)
      addAPI(this.project).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDelProject(data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onUploadSuccess(res) {
      if (res.code === 200) {
        this.project.img = res.data
        this.tmpImgUrl = this.getImgUrl(res.data)
      } else {
        this.$message.error('上传失败');
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getImgUrl(url) {
      return url + '?token=' + localStorage.getItem('token')
    },
    jump(project, tag) {
      this.$router.push({ path: '/dev/api/' + tag, query: { projectId: project.id, projectName: project.name } })
    },
    test(projectId) {
      let url = '/#/dev/doc?projectId=' + projectId
      window.open(url)
    },
    onExport(project) {
      exportAPI(project.id).then(res => {
        if (res.data.code === 200) {
          downloadData(res.data.data, project.id + '.json')
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onInputProject() {
      this.$el.querySelector("#file").click()
    },
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      var that = this
      reader.onload = () => {
        var data = JSON.parse(reader.result)
        console.log(data)
        inputAPI(data).then(res => {
          if (res.data.code === 200) {
            that.$message.success(res.data.msg);
          } else {
            that.$message.error(res.data.msg);
          }
          that.getList()
        }).catch(err => {
          console.log(err)
        })
      };
      reader.readAsText(file);
    },
    onEdit(project) {
      this.project = project
      this.tmpImgUrl = this.getImgUrl(project.img)
      this.dialogVisible = true
    },
    openProjectRole(project) {
      this.project = project
      this.selectUsers = []
      this.userDialogVisible = true
      projectUserApi.getListAPI({ id: project.id }).then(res => {
        if (res.data.code === 200) {
          res.data.data.forEach(element => {
            this.selectUsers.push(element.id)
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUserList(project) {
      userApi.getProjectTransferListAPI(project.id).then(res => {
        if (res.data.code === 200) {
          this.users = res.data.data.filter(obj => obj.id !== project.createUser)
          this.openProjectRole(project)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    updateProjectUser() {
      let projectUsers = []
      this.selectUsers.forEach(userId => {
        this.users.forEach(user => {
          if (user.id === userId) {
            var u = {
              userId: user.id,
              projectId: this.project.id,
              status: user.status,
              validityDate: user.validityDate
            }
            projectUsers.push(u)
          }
        });
      });
      projectUserApi.addAPI({ users: projectUsers, project: this.project }).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style scoped>
.content-box {
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

.project-box {
  width: 360px;
  height: 340px;
  /* border: 1px solid #000000; */
  background-color: rgb(198, 226, 255);
  border-radius: 5px;
  margin: 0px 24px 24px 0px;
  position: relative;
}

.item-box {
  position: relative;
  width: 120px;
  height: 60px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-transfer-panel {
  width: 400px;
  padding: 4px;
}

::v-deep .el-checkbox {
  margin-right: 0px;
}
</style>
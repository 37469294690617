import Vue from "vue";

Vue.directive("isEmpty", {
  update: function (el, binding, vnode) {
    if (vnode.componentInstance.value == "") {
      el.classList.add("is-required");
    } else if (
      vnode.componentInstance.value ||
      vnode.componentInstance.value == 0
    ) {
      el.classList.remove("is-required");
    }
  },
});

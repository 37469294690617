import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/user";
let list_url = "/api/users";
let transfer_url = "/api/users/transfer/";
let token_url = "/api/user/token/";
let token_project_url = "/api/user/token/project/";
let projectTransfer_url = "/api/users/project/";

// get请求
export function getListAPI(params) {
  return http.get(list_url, params);
}

// get请求
export function getTransferListAPI(id) {
  return http.get(transfer_url + id, {});
}

// get请求
export function getProjectTransferListAPI(id) {
  return http.get(projectTransfer_url + id, {});
}

// get请求
export function getAPI(params) {
  return http.get(url, params);
}

// get请求
export function getToeknAPI(id) {
  return http.get(token_url + id, {});
}

// get请求
export function getToeknProjectAPI(hours) {
  return http.get(token_project_url + hours, {});
}

// post请求
export function addAPI(params) {
  return http.post(url, params);
}

// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// delete 请求
export function deleteAPI(params) {
  return http.delete(url, params);
}

<template>
    <div :style="boxStyle">
        <!-- JSON编辑器 -->
        <el-dialog title="JSON编辑器" :visible.sync="visibleJson" width="70%">
            <editor @init="editorInitJson" lang="json" theme="dracula" width="100%" height="calc(100vh - 350px)"
                v-model="jsonData">
            </editor>
            <div class="center-box" style="margin-top: 16px;">
                <el-button @click="saveJsonData" type="primary" size="mini" style="width: 110px;">保存</el-button>
            </div>
        </el-dialog>
        <!-- JS编辑器 -->
        <el-dialog title="JS编辑器" :visible.sync="visibleJs" width="70%">
            <editor @init="editorInitJs" lang="javascript" theme="dracula" width="100%" height="calc(100vh - 350px)"
                v-model="value">
            </editor>
            <div class="center-box" style="margin-top: 16px;">
                <el-button @click="saveJsData" type="primary" size="mini" style="width: 110px;">保存</el-button>
            </div>
        </el-dialog>
        <!-- 根据字段类型选用表单组件 -->
        <div v-if="pKey.endsWith('.sql')" class="start-box">
            <SqlEditer :pKey="pKey" :updateAttributeData="updateAttributeData" :data="data.value"
                :boxStyle="{ minHeight: '40px', width: '100%' }">
            </SqlEditer>
        </div>
        <div v-else-if="pKey.endsWith('.url')" class="start-box input-max-width">
            <ApiSelectEditer :pKey="pKey" :updateAttributeData="updateAttributeData" :data="data.value" :showRun="true"
                :boxStyle="{ minHeight: '40px', width: '100%' }">
            </ApiSelectEditer>
        </div>
        <div v-else-if="pKey.endsWith('.sourceId')" class="start-box input-max-width">
            <el-select size="mini" style="width: 100%;" v-model="value" @change="update" clearable placeholder="请选择源据源">
                <el-option-group v-for="group in sources" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px; max-width: 100px;" class="ellipsis">{{
                            item.value }}</span>
                    </el-option>
                </el-option-group>
            </el-select>
            <i class="el-icon-refresh-right" style=" font-size: 20px;margin-top: 4px; margin-left: 4px;"
                @click="getSources()"></i>
        </div>
        <div v-else-if="isJs(pKey)">
            <el-button size="mini" @click="onJsClick" type="text">编辑</el-button>
        </div>
        <el-input-number size="mini" v-else-if="isInt(value)" v-model="value" @input="update"></el-input-number>
        <el-input-number size="mini" v-else-if="isFloat(value)" v-model="value" :precision="2" :step="0.1"
            @input="update"></el-input-number>
        <el-switch size="mini" v-else-if="type === 'boolean'" v-model="value" active-color="#13ce66"
            inactive-color="#ff4949" @change="update">
        </el-switch>
        <div v-else-if="type === 'object'">
            <el-button size="mini" @click="onJsonClick" type="text">编辑</el-button>
        </div>
        <el-input class="input-max-width" v-isEmpty size="mini" v-else v-model="value" clearable @blur="update"></el-input>
    </div>
</template>

<script>
import { getProjectId } from "../ulits";
import * as SourceApi from "@/utils/apis/dev/sourceApi"
import SqlEditer from './SqlEditer';
import ApiSelectEditer from './ApiSelectEditer';

export default {
    name: 'InputEditer',
    props: { data: Object, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: { editor: require("vue2-ace-editor"), SqlEditer, ApiSelectEditer },
    watch: {
        "data": {
            handler: function (data) {
                this.setType(data.value)
                this.value = data.value
                this.jsonData = JSON.stringify(this.value, null, 4)
                this.showTextJson = JSON.stringify(this.value, null, 4)
                if (this.isJs(this.pKey)) {
                    this.showTextJs = this.value
                }
            },
            deep: true
        }
    },
    data() {
        return {
            value: '',
            type: 'string',
            visibleJson: false,
            visibleJs: false,
            showTextJson: '',
            showTextJs: '',
            jsonData: {},
            editorOptions: {
                tabSize: 4, // tab默认大小
                showPrintMargin: false, // 去除编辑器里的竖线
                fontSize: 14, // 字体大小
                highlightActiveLine: true, // 高亮配置
                enableSnippets: true,
                enableLiveAutocompletion: true,
                enableBasicAutocompletion: true
            },
            sources: []
        }
    },
    beforeMount() {
        this.setType(this.data.value)
        this.value = this.data.value
        this.jsonData = this.value
        this.showTextJson = JSON.stringify(this.value, null, 4)
        if (this.isJs(this.pKey)) {
            this.showTextJs = this.value
        }
    },
    methods: {
        getSources() {
            SourceApi.getSelectListAPI(getProjectId()).then(res => {
                if (res.data.code === 200) {
                    this.sources = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        setType(value) {
            this.type = typeof value
        },
        isInt(data) {
            return (typeof data === 'number') && (data % 1 === 0)
        },
        isFloat(value) {
            // 检查值是否为Number类型
            if (typeof value !== 'number') return false;

            // 检查值是否为无限大或NaN
            if (!isFinite(value)) return false;

            // 将值转换为字符串并检查其格式
            const valueStr = value.toString();
            return valueStr.indexOf('.') >= 0 && valueStr.match(/\.\d+/);
        },
        isJs(value) {
            return value.endsWith('codeJs')
        },
        onJsonClick() {
            if (typeof this.jsonData !== 'string') {
                this.jsonData = JSON.stringify(this.jsonData, null, 4)
            }
            this.visibleJson = !this.visibleJson
        },
        onJsClick() {
            this.visibleJs = !this.visibleJs
        },
        saveJsonData() {
            this.value = JSON.parse(this.jsonData)
            this.update()
            this.showTextJson = this.jsonData
            this.visibleJson = !this.visibleJson
        },
        saveJsData() {
            this.update()
            this.visibleJs = !this.visibleJs
        },
        editorInitJson: function (editor) {
            require("brace/ext/language_tools"); //language extension prerequsite...
            require("brace/mode/json"); //language
            require("brace/theme/dracula");
            require("brace/snippets/json"); //snippet
            require("brace/snippets/text");
            this.editor = editor
            editor.setOptions(this.editorOptions)
        }, editorInitJs: function (editor) {
            require("brace/ext/language_tools"); //language extension prerequsite...
            require("brace/mode/javascript"); //language
            require("brace/theme/dracula");
            require("brace/snippets/javascript"); //snippet
            require("brace/snippets/text");
            this.editor = editor
            editor.setOptions(this.editorOptions)
        },
        update() {
            this.updateAttributeData(this.pKey, this.value)
        },
    }
}
</script>

<style scoped>

.input-max-width {
    max-width: 280px;
}
</style>

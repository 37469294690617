import Chart from "./Chart/config";
import LineChart from "./LineChart/config";
import PieChart from "./PieChart/config";
import BarChart from "./BarChart/config";
import ScatterChart from "./ScatterChart/config";
import CandlestickChart from "./CandlestickChart/config";
import RadarChart from "./RadarChart/config";
import ClockChart from "./ClockChart/config";

const components = [
  Chart,
  LineChart,
  PieChart,
  BarChart,
  ScatterChart,
  CandlestickChart,
  RadarChart,
  ClockChart,
];

export default components;

<template>
    <div :style="data.designStyle" @mouseout.stop="evt => onComponentSelect(data.id, evt)"
        @mouseover.stop="evt => onComponentSelect(data.id, evt)" @click.stop="evt => onComponentSelect(data.id, evt)">
        <div :style="data.attribute.cStyle" @mousedown.stop="evt => onComponentMouseDown(evt, data)" :ref="data.id"
            :id="data.id">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(tab, index) in data.children" :key="index" :label="tab.attribute.tab">
                    <div :style="tab.designStyle" @click.stop="evt => onComponentSelect(tab.id, evt)"
                        @mouseout.stop="evt => onComponentSelect(tab.id, evt)"
                        @mouseover.stop="evt => onComponentSelect(tab.id, evt)">
                        <div :name="tab.name" :style="tab.attribute.cStyle" :ref="tab.attribute.key" :id="tab.id">
                            <component v-for="(children, index) in tab.children" :key="index"
                                :is="children.attribute.component" :data="children" :onComponentSelect="onComponentSelect"
                                :onComponentMouseDown="onComponentMouseDown">
                            </component>
                        </div>
                    </div>
                    <div v-if="isDesign" style="height: 60px;" class="center-box"
                        @mouseover.stop="evt => onComponentSelect(data.id, evt)">
                        <div style="color: rgb(106, 115, 122); font-size: 14px; margin-top: 18px;">拖动组件到这 [标签页]</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'

export default {
    name: "EasyTabs",
    props: { data: Object, onComponentSelect: Function, onComponentMouseDown: Function },
    data() {
        return {
            activeName: null,
            value: '',
            pageId: null
        };
    },
    watch: {
        "value": {
            handler: function (val) {
                this.comnitValue(val)
            },
            deep: true
        },
        "data": {
            handler: function (data) {
                this.value = data.attribute.data.value
            },
            deep: true
        },
        '$store.state.easyLoaderData': {
            handler: function (data) {
                if (this.value !== data[this.pageId][this.data.attribute.key]) {
                    this.value = data[this.pageId][this.data.attribute.key]
                }
            },
            deep: true
        },
    },
    beforeMount() {
        this.isDesign = EasyUiUlits.isDesign()
        this.pageId = EasyUiUlits.getEasyPageId()
        this.value = this.data.attribute.data.value
    },
    methods: {
        handleClick() { },
        comnitValue(val) {
            // this.$store.state.easyRenderData
            this.$store.commit('setLoaderData', { id: this.pageId, key: this.data.attribute.key, value: val });
        }
    },
};
</script>

<style lang="sass" scoped></style>

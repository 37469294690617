import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/easypage";
let list_url = "/api/easypages/";

let release_url = "/api/easypage/release/";

// get请求
export function getListAPI(pid) {
  let tmpUrl = list_url + pid;
  return http.get(tmpUrl, {});
}

// get请求
export function getAPI(params) {
  return http.get(url, params);
}

// post请求
export function addAPI(params) {
  return http.post(url, params);
}

// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// delete 请求
export function deleteAPI(params) {
  return http.delete(url, params);
}

export function releaseAPI(id) {
  let tmpUrl = release_url + id;
  return http.get(tmpUrl, {});
}

<template>
  <div class="base-box start-box">
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" node-key="id" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
              }}</i>
            <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
              @change="data.isEdit = false; onDirectoryRename()"></el-input>
          </span>
          <span>
            <i class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box ">
      <el-table v-show="isShowEdit" size="mini"
        :data="easypages.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        @row-click="onClickObj" style="width: 100%">
        <el-table-column type="index" width="80">
          <template slot="header" slot-scope="scope">
            <el-button type="primary" size="mini" @click="addRow">添加</el-button>
            {{ scope.row }}
          </template>
        </el-table-column>
        <el-table-column label="页面名称">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="页面" width="120">
          <template slot-scope="scope">
            <el-button v-if="scope.row.id" type="text" style="margin-left: 4px;" size="mini"
              @click="openPage(scope.row.id)">预览</el-button>
            <el-button v-if="scope.row.id" type="text" style="margin-left: 4px;" size="mini"
              @click="openAPP(scope.row.id)">APP</el-button>
            <el-button v-if="scope.row.id" type="text" style="margin-left: 4px;" size="mini"
              @click="editPage(scope.row.id)">设计</el-button>
          </template>
        </el-table-column>
        <el-table-column align="right" width="250">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
            {{ scope.row }}
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-check" @click="add">提交</el-button>
            <el-button v-if="scope.row.id" size="mini" type="primary" icon="el-icon-check"
              @click="release(scope.row)">发布</el-button>
            <el-button v-if="scope.row.id" size="mini" type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI, releaseAPI } from "@/utils/apis/dev/easyPageApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi";
import { getUrlArgs } from "@/tools/url";
import { createEasyPageId as createId } from "@/pages/easy_ui/ulits";

export default {
  name: 'easyPageView',
  components: {},
  data() {
    return {
      treedatas: [],
      directory: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      easypage: {
        id: null,
        name: '',
        data: [],
        description: ''
      },
      easypages: [],
      search: '',
      projectId: null,
      projectName: null,
      isShowEdit: false,
      loderTemp: {
        enable: false,
        refreshTime: 5,
        database: {
          enable: true,
          sourceId: '',
          sql: 'select * from test'
        },
        api: {
          enable: true,
          url: 'https://test.com',
          method: 'GET',
          body: {},
          params: {},
          headers: {
            'Content-Type': 'application/json'
          },
        },
        expression: {
          enable: false,
          value: '',
          filtercodeJs: '// data变量值为目标数据\n// console.log(data)\n'
        }
      },
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList() {
      getListAPI(this.directory.id).then(res => {
        if (res.data.code === 200) {
          this.easypages = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'easypage').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.easypage).then(res => {
        if (res.data.code === 200) {
          this.easypage = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data) {
      this.directory = data
      this.getList()
      this.isShowEdit = true
    },
    onClickObj(data) {
      this.easypage = data
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(index, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addRow() {
      var key = createId()
      var loader = {}
      loader[key] = this.loderTemp
      this.easypages.push({
        pid: this.directory.id,
        projectId: this.projectId,
        data: {
          data: {
            id: createId(),
            name: "app",
            attribute: {
              key: key,
              data: { value: '' },
              cStyle: {
                height: "100%",
                background: "#FFFFFF"
              },
            },
            children: [],
            // 设计器中的样式
            designStyle: {
              height: "99999px",
            },
          },
          loader: loader
        },
      })
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'easypage',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },

    editPage(id) {
      let url = "/#/easyui/edit?status=design&easyPageId=" + id + "&projectId=" + this.projectId
      window.open(url)
    },
    openPage(id) {
      let url = "/#/easyui/window?status=preview&easyPageId=" + id + "&projectId=" + this.projectId
      window.open(url)
    },
    openAPP(id) {
      let url = "/#/easyui/app?status=preview&easyPageId=" + id + "&projectId=" + this.projectId
      window.open(url)
    },
    release(data) {
      releaseAPI(data.id).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>



</style>
<template>
    <div class="base-box margin-box page-right">
        <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">用户管理</div>
        <el-dialog title="提示" :visible.sync="infoDialogVisible" width="50%" center>
            <el-descriptions :title="user.username" size="mini" :column="2" style="color: white;">
                <el-descriptions-item label="类型">
                    <el-select size="mini" v-model="user.user_type" placeholder="用户类型" style="width: 80%;" disabled>
                        <el-option v-for="item in userType" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="电话">{{ user.phone }}</el-descriptions-item>
                <el-descriptions-item label="邮箱">{{ user.email }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ user.createTime }}</el-descriptions-item>
                <el-descriptions-item label="修改时间">{{ user.updateTime }}</el-descriptions-item>
                <el-descriptions-item label="创建人">{{ user.createUser }}</el-descriptions-item>
                <el-descriptions-item label="修改人">{{ user.updateUser }}</el-descriptions-item>
            </el-descriptions>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="tokenDialogVisible" width="50%" center>
            <span style="color: white;">Token: {{ token }}</span>
        </el-dialog>
        <div class="base-box content-box">
            <el-table
                :data="tableData.filter(data => !search || data.username.toLowerCase().includes(search.toLowerCase()))"
                size="mini">
                <el-table-column type="index" label="序号" width="80">
                    <template slot="header" slot-scope="scope">
                        <el-button type="primary" size="mini" @click="addRow">添加</el-button>
                        {{ scope.row }}
                    </template>
                </el-table-column>

                <el-table-column min-width="200" label="用户名">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.username"
                            :disabled="!scope.row.isEdit" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" label="用户类型">
                    <template slot-scope="scope">
                        <el-select size="mini" v-model="scope.row.user_type" placeholder="用户类型" style="width: 100%;">
                            <el-option v-for="item in userType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column width="250" label="密码">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.password" show-password clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="250" label="电话">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.phone" clearable>
                        </el-input>
                    </template>
                </el-table-column>


                <el-table-column width="300" label="E-mail">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" v-model="scope.row.email" clearable>
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column width="150" prop="createTime" label="创建时间">
                </el-table-column>

                <el-table-column width="150" prop="updateTime" label="更新时间">
                </el-table-column>

                <el-table-column width="300" fixed="right" label="操作">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
                        {{ scope.row }}
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="add(scope.row)">提交
                        </el-button>
                        <el-button v-if="scope.row.id" v-show="scope.row.username != 'admin'" size="mini" type="danger"
                            @click="handleDelete(scope.row)">删除
                        </el-button>
                        <el-button v-if="scope.row.id" @click="handleTokenClick(scope.row)" type="warning"
                            size="mini">Token
                        </el-button>
                        <el-button v-if="scope.row.id" @click="handleClick(scope.row)" type="info" size="mini">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI, getToeknAPI } from "@/utils/apis/user/userApi"

export default {
    name: 'userPage',
    data() {
        return {
            tableData: [],
            search: '',
            user: {},
            userType: [
                {
                    value: 'Admin',
                    label: '管理员'
                }, {
                    value: 'User',
                    label: '普通用户'
                }, {
                    value: 'Dev',
                    label: '开发者'
                }, {
                    value: 'Ban',
                    label: '冻结'
                },
            ],
            infoDialogVisible: false,
            tokenDialogVisible: false,
            token: ''
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleClick(row) {
            this.user = row
            this.infoDialogVisible = true
        },
        handleTokenClick(row) {
            getToeknAPI(row.id).then(res => {
                if (res.data.code === 200) {
                    this.token = res.data.data
                    this.tokenDialogVisible = true
                }
            }).catch(err => {
                console.log(err)
            })
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteAPI(row).then(res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.getList()
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        addRow() {
            this.tableData.push({
                id: null,
                username: null,
                user_type: null,
                password: null,
                phone: null,
                email: null,
                createTime: null,
                updateTime: null,
                createUser: null,
                updateUser: null,
                isEdit: true
            })
        },
        add(row) {
            addAPI(row).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
                this.getList()
            }).catch(err => {
                console.log(err)
            })
        },
        getList() {
            getListAPI().then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data
                }
            }).catch(err => {
                console.log(err)
            })
        },

    }
}
</script>

<style scoped>
.content-box {
    height: calc(100vh - 100px);
    overflow-y: auto;
}
</style>
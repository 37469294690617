import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "标签页",
  img: "",
  temp: {
    id: createId(),
    name: " 标签页",
    attribute: {
      component: "EasyTabs",
      key: createId(),
      data: {
        value: "",
      },
      // 容器样式
      cStyle: {
        width: "100%",
        minHeight: "50px",
        fontSize: "14px",
        height: "auto",
        background: "rgba(255, 255, 255, 0)",
        color: "#ffffff",
      },
    },
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

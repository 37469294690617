<template>
  <div class="base-box margin-box page-right">
    <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">URL调试</div>
    <div class="base-box between-box">
      <el-select size="mini" v-model="requests.method" placeholder="请选择方法" style="margin-right: 10px;">
        <el-option size="mini" v-for="item in methods" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>

      <el-input size="mini" placeholder="请输入URL" v-model="requests.url" style="margin-right: 10px;">
      </el-input>

      <el-button type="primary" size="mini" @click="save">暂 存</el-button>
      <el-button type="primary" size="mini" @click="send">发 送</el-button>
    </div>

    <div class="base-box">
      <el-divider content-position="left" @click="isHeaderShow = !isHeaderShow">
        请求头
        <i class="el-icon-caret-bottom"></i>
      </el-divider>
      <div class="base-box" style="height: 120px; overflow-y: auto;" v-show="isHeaderShow">
        <div style="padding-left: 8px; padding-right: 8px;">
          <div v-for="(item, key) in requests.headers" :key="key">
            <div class="between-box" style="margin-bottom: 14px;">
              <div style="width: 60px; color: #FFFFFF;">{{ key + 1 }}</div>
              <el-input size="mini" placeholder="KEY" v-model="item.key" style="margin-right: 10px;">
              </el-input>
              <el-input size="mini" placeholder="VALUE" v-model="item.value" style="margin-right: 10px;">
              </el-input>
              <div>
                <i class="el-icon-delete" style="font-size: 24px; color: #FFFFFF;"
                  @click="requests.headers.splice(key, 1)"></i>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-left: 8px; padding-right: 8px;">
          <el-button size="mini" type="primary" style="width: 100%;"
            @click="requests.headers.push({ key: '', value: '' })">添加</el-button>
        </div>
      </div>

      <el-divider content-position="left" @click="isBodyShow = !isBodyShow">
        请求体
        <i class="el-icon-caret-bottom"></i>
      </el-divider>
      <el-tabs type="border-card" size="mini" style="height: 220px;" v-show="isBodyShow" v-model="requests.bodyType">
        <el-tab-pane label="JSON">
          <el-input size="mini" type="textarea" :rows="8" v-model="requests.body.json">
          </el-input>
        </el-tab-pane>
        <el-tab-pane label="Multipart form data">
          <div style="height: 150px; overflow-y: auto;">
            <div v-for="(item, key) in requests.body.mForm" :key="key">
              <div class="between-box" style="margin-bottom: 14px;">
                <div style="width: 60px; color: #FFFFFF;">{{ key + 1 }}</div>
                <el-input size="mini" placeholder="KEY" v-model="item.key" style="margin-right: 10px;">
                </el-input>
                <el-input size="mini" placeholder="VALUE" v-model="item.value" style="margin-right: 10px;">
                </el-input>
                <div>
                  <i class="el-icon-delete" style="font-size: 24px; color: #FFFFFF;"
                    @click="requests.body.mForm.splice(key, 1)"></i>
                </div>
              </div>
            </div>
            <div style="padding-left: 8px; padding-right: 8px;">
              <el-button size="mini" type="primary" style="width: 100%;"
                @click="requests.body.mForm.push({ key: '', value: '' })">添加</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="URLencoded form data">
          <div style="height: 150px; overflow-y: auto;">
            <div v-for="(item, key) in requests.body.uForm" :key="key">
              <div class="between-box" style="margin-bottom: 14px;">
                <div style="width: 60px; color: #FFFFFF;">{{ key + 1 }}</div>
                <el-input size="mini" placeholder="KEY" v-model="item.key" style="margin-right: 10px;">
                </el-input>
                <el-input size="mini" placeholder="VALUE" v-model="key.value" style="margin-right: 10px;">
                </el-input>
                <div>
                  <i class="el-icon-delete" style="font-size: 24px; color: #FFFFFF;"
                    @click="requests.body.uForm.splice(key, 1)"></i>
                </div>
              </div>
            </div>
            <div style="padding-left: 8px; padding-right: 8px;">
              <el-button size="mini" type="primary" style="width: 100%;"
                @click="requests.body.uForm.push({ key: '', value: '' })">添加</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="自定义">
          <el-input size="mini" type="textarea" :rows="8" v-model="requests.body.custom">
          </el-input>
        </el-tab-pane>
      </el-tabs>

      <el-divider content-position="left" @click="isResponseShow = !isResponseShow">
        响应结果
        <i class="el-icon-caret-bottom"></i>
      </el-divider>
      <div class="between-box" style="padding-left: 8px; padding-right: 8px;" v-show="isResponseShow">
        <div style="margin-right: 10px; width: 100%;">
          <vue-json-viewer :value="requests.axiosRequest" :expand-depth=50 copyable boxed
            style="min-height: calc(100vh - 600px); max-height: calc(100vh - 600px); width: 100%; overflow: auto;"
            theme="my-awesome-json-theme" sort>
          </vue-json-viewer>
        </div>
        <div style="margin-right: 10px; width: 100%;">
          <vue-json-viewer :value="requests.response" :expand-depth=50 copyable boxed
            style="min-height: calc(100vh - 600px); max-height: calc(100vh - 600px);  width: 100%;  overflow: auto;"
            theme="my-awesome-json-theme" sort>
          </vue-json-viewer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonViewer from 'vue-json-viewer'
import { getUrlArgs } from "@/tools/url"
import axios from 'axios'

export default {
  name: 'urlTestPage',
  components: {
    VueJsonViewer
  },
  data() {
    return {
      methods: [
        { value: 'GET', label: 'GET' },
        { value: 'POST', label: 'POST' },
        { value: 'DELETE', label: 'DELETE' },
        { value: 'PUT', label: 'PUT' },
      ],
      requests: {
        url: '',
        method: 'GET',
        headers: [],
        body: {
          json: '',
          mForm: [],
          uForm: [],
          custom: ''
        },
        bodyType: '',
        response: {},
        axiosRequest: {}
      },
      isHeaderShow: true,
      isBodyShow: true,
      isResponseShow: true,
      service: null,
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.url) {
      this.requests.url = decodeURIComponent(urlParams.url)
    }
    if (urlParams.method) {
      this.requests.method = urlParams.method
    }
    this.requests.headers.push({ key: 'Content-Type', value: 'application/json' })
    this.requests.headers.push({ key: 'Authorization', value: localStorage.getItem('token') })
    this.request = axios.create({
      baseURL: process.env.BASE_API,
      timeout: 60 * 1000
    })
    let localStorageRequests = localStorage.getItem(this.requests.url)
    if (localStorageRequests) {
      this.requests = JSON.parse(localStorageRequests)
    }
  },
  methods: {
    dealForm(arr) {
      let headers = {}
      arr.forEach(element => {
        headers[element.key] = element.value
      });
      return headers
    },
    dealData() {
      if (this.requests.bodyType === '0') {
        return JSON.parse(this.requests.body.json)
      }
      if (this.requests.bodyType === '1') {
        return this.requests.body.mForm
      }
      if (this.requests.bodyType === '2') {
        return this.requests.body.uForm
      }
      if (this.requests.bodyType === '3') {
        return this.requests.body.custom
      }
    },
    send() {
      if (this.requests.method === 'GET') {
        this.get()
      }
      if (this.requests.method === 'POST') {
        this.post()
      }
      if (this.requests.method === 'PUT') {
        this.put()
      }
      if (this.requests.method === 'DELETE') {
        this.delete()
      }
    },
    save() {
      // alert(JSON.stringify(this.requests, null, 4))
      localStorage.setItem(this.requests.url, JSON.stringify(this.requests, null, 4))
    },
    get() {
      this.requests.axiosRequest = {
        method: this.requests.method,
        url: this.requests.url,
        headers: this.dealForm(this.requests.headers)
      }
      this.request(this.requests.axiosRequest).then(res => {
        this.requests.response = res.data
        this.save()
      }).catch(err => {
        this.requests.response = err.message
        this.save()
      })
    },
    post() {
      this.requests.axiosRequest = {
        method: this.requests.method,
        url: this.requests.url,
        headers: this.dealForm(this.requests.headers),
        data: this.dealData(),
      }
      this.save()
      this.request(this.requests.axiosRequest).then(res => {
        this.requests.response = res.data
        this.save()
      }).catch(err => {
        this.requests.response = err.message
        this.save()
      })
    },
    put() {
      this.requests.axiosRequest = {
        method: this.requests.method,
        url: this.requests.url,
        headers: this.dealForm(this.requests.headers),
        data: this.dealData(),
      }
      this.save()
      this.request(this.requests.axiosRequest).then(res => {
        this.requests.response = res.data
        this.save()
      }).catch(err => {
        this.requests.response = err.message
        this.save()
      })
    },
    delete() {
      this.requests.axiosRequest = {
        method: this.requests.method,
        url: this.requests.url,
        headers: this.dealForm(this.requests.headers),
        data: this.dealData(),
      }
      this.save()
      this.request(this.requests.axiosRequest).then(res => {
        this.requests.response = res.data
        this.save()
      }).catch(err => {
        this.requests.response = err.message
        this.save()
      })
    },
  },
}
</script>

<style>
.jv-container .jv-more {
  display: none;
}
</style>
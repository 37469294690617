import CheckBox from "./CheckBox/config";
import Datetime from "./Datetime/config";
import File from "./File/config";
import Float from "./Float/config";
import Json from "./Json/config";
import Number from "./Number/config";
import Password from "./Password/config";
import Percentage from "./Percentage/config";
import Radio from "./Radio/config";
import Select from "./Select/config";
import String from "./String/config";
import StringMd from "./StringMd/config";
import Submit from "./Submit/config";
import Switch from "./Switch/config";
import Text from "./Text/config";

const components = [
  CheckBox,
  Datetime,
  File,
  Float,
  Json,
  Number,
  Password,
  Percentage,
  Radio,
  Select,
  String,
  Submit,
  Switch,
  Text,
  StringMd,
];

export default components;

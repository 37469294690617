<template>
    <div style="width: 100%;">
        <div class="start-box"
            v-for="(item, index) in Object.entries(attribute).sort(function (a) { if (typeof a[1] === 'object') { return 1 } else { return -1 } })"
            :key="index" style="margin-top: 8px; width: 100%;">
            <!-- 标签 -->
            <div style="font-size: 14px; min-width: 80px; position: relative; top: 4px;"
                v-show="!noShowKeys.includes(item[0]) && !item[0].endsWith('Style')">
                {{ explian(item[0]) }}
            </div>
            <!-- 内容 -->
            <div style="width: 100%">
                <!-- 数据 -->
                <div style=" width: 100%;" v-if="item[0] === 'data' && !Array.isArray(attribute[item[0]])">
                    <div class="c-editer-label-style">组件属性 </div>
                    <DataEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </DataEditer>
                </div>

                <!-- 样式 -->
                <div style=" width: 100%;" v-else-if="item[0].endsWith('Style')">
                    <div class="c-editer-label-style">{{ explian(item[0], "容器样式") }}</div>
                    <StyleEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </StyleEditer>
                </div>

                <!-- 高级 -->
                <div style=" width: 100%;" v-else-if="item[0] === 'options'">
                    <OptionsEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys"
                        :boxStyle="{ minHeight: '40px', width: '100%' }">
                    </OptionsEditer>
                </div>
                <!-- 其他 -->
                <InputEditer v-else :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                    :data="{ value: attribute[item[0]] }" :boxStyle="{}"
                    v-show="!noShowKeys.includes(item[0]) && !item[0].endsWith('Style')"></InputEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import StyleEditer from './StyleEditer';
import DataEditer from './DataEditer';
import InputEditer from './InputEditer';
import OptionsEditer from './OptionsEditer';

export default {
    name: 'AttributeEditer',
    props: { data: Object, noShowKeys: Array, updateAttributeData: Function, pKey: String },
    components: {
        StyleEditer, DataEditer, InputEditer, OptionsEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {}
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        explian(key, defaultValue) {
            var str = attributeMap[key]
            if(str) {
                return attributeMap[key]
            } else {
                if(defaultValue){
                    return defaultValue
                }
                return defaultValue
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

<template>
  <div class="base-box margin-box page-right">
    <!-- 路径 -->
    <div class="base-box start-wrap-box  margin-box-bottom border-bottom-box" style="font-weight: bold;">
      <div class="center-box" style="width: 100%;">
        <i class="el-icon-d-arrow-left tag-box-icon" style="font-weight: bold;"></i>
        <div class="tag-box-name" style="width: 130px;" @click="back_to_project_page">返回项目列表</div>
        <div class="tag-box-name" style="width: 100%;">当前项目：{{ projectName }}</div>
      </div>
    </div>
    <!-- 开发导航栏 -->
    <div class="base-box start-wrap-box">
      <div v-for="(item, index) in tags" :key="index" class="tag-box"
        @click="to(item.url + '?projectId=' + projectId + '&projectName=' + projectName)" :style="item.style">
        <div class="center-box">
          <i :class="item.icon" class="tag-box-icon"></i>
          <div class="tag-box-name">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- 开发页面内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
import { getAPI } from "@/utils/apis/dev/projectApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'apiPage',
  components: {
  },
  data() {
    return {
      projectId: '',
      tags: [
        {
          url: '/dev/api/client',
          name: '接口开发',
          icon: 'el-icon-link',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/flow',
          name: '业务编排',
          icon: 'el-icon-share',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/comp',
          name: '组件开发',
          icon: 'el-icon-star-off',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/func',
          name: '函数开发',
          icon: 'el-icon-cpu',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/scheduler',
          name: '任务中心',
          icon: 'el-icon-stopwatch',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/model',
          name: '数据模型',
          icon: 'el-icon-coin',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/easypage',
          name: '页面设计',
          icon: 'el-icon-monitor',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/map',
          name: '数据字典',
          icon: 'el-icon-notebook-2',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/source',
          name: '数据源',
          icon: 'el-icon-pie-chart',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/config',
          name: '配置项',
          icon: 'el-icon-setting',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/response',
          name: '响应器',
          icon: 'el-icon-magic-stick',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/aop',
          name: '拦截器',
          icon: 'el-icon-user',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/policy',
          name: '访问策略',
          icon: 'el-icon-sort',
          style: { background: '#FFFFFF00' }
        },
        {
          url: '/dev/api/file',
          name: '资源文件',
          icon: 'el-icon-receiving',
          style: { background: '#FFFFFF00' }
        },
      ]
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (!urlParams.projectId) {
      this.back_to_project_page()
    }
    this.projectId = urlParams.projectId
    this.projectName = decodeURIComponent(urlParams.projectName)
    getAPI({ id: urlParams.projectId }).then(res => {
      if (res.data.code === 200) {
        this.project = res.data.data
      }
    }).catch(err => {
      console.log(err)
    })
    this.setAvite(location.hash)
  },
  methods: {
    back_to_project_page() {
      this.$router.push("/dev/project")
    },
    to(path) {
      this.$router.push(path)
      this.setAvite(path)
    },
    setAvite(path) {
      var thePath = path.slice(path.indexOf('#') + 1, path.indexOf('?'))
      for (let index = 0; index < this.tags.length; index++) {
        this.tags[index].style = { background: '#FFFFFF00' }
        if (this.tags[index].url === thePath) {
          this.tags[index].style = { background: '#409eff' }
        }
      }
    }
  },
}
</script>

<style scoped>
.tag-box {
  border-radius: 5px;
  margin: 10px 10px 0px 10px;
  padding: 4px;
  min-width: 100px;
}

.tag-box-icon {
  font-size: 22px;
}

@media screen and (max-width: 100%) {
  .tag-box {
    border-radius: 5px;
    margin: 10px 10px 0px 10px;
    padding: 4px;
  }

  .tag-box-name {
    font-size: 12px;
  }

  .tag-box-icon {
    font-size: 14px;
  }
}
</style>
<template>
  <div class="base-box margin-box page-right">
    <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">AI助手</div>
    <div
      style="height: calc(100vh - 240px); border: solid #a4a5a7 1px; border-radius: 5px; overflow: auto; padding: 10px;">
      <div v-for="(message, index) in messages" :key="index"
        style="border: solid #a4a5a7 1px; padding: 8px; margin-bottom: 10px;">
        <div class="between-box"
          style="width: 100%; margin-bottom: 8px; padding-bottom: 4px; border-bottom: solid #a4a5a7 1px;">
          <el-avatar size="small" :src="message.avatar"></el-avatar>
          {{ message.time.toLocaleString('zh-CN') }}
          <i class="el-icon-copy-document" @click="copyToClipboard(message.msg)"></i>
        </div>
        <div v-html="message.html"> </div>
      </div>
    </div>

    <div style="margin-top: 8px; margin-bottom: 8px;">
      <el-button size="mini" type="primary" @click="sendMessageToAI" style="width: 100%;">发送</el-button>
    </div>

    <el-input size="mini" type="textarea" :rows="5" @keyup.enter.native="sendMessageToAI" v-model="message">
    </el-input>
  </div>
</template>

<script>
import io from 'socket.io-client';
import md from 'markdown-it';

const aiAvatar = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
const userAvatar = "@/assets/images/loginPage/header.jpeg"

export default {
  name: 'aiPage',
  components: {

  },
  data() {
    return {
      message: '',
      messages: [{ role: "ai", html: "有什么可以帮到您？", msg: "有什么可以帮到您？", avatar: aiAvatar, time: new Date() }],
      socket: null,
    };
  },
  mounted() {
    const socket = io('/ai');
    this.socket = socket;
    var that = this

    // 连接建立后
    socket.on('connect', function () {
      // that.messages.push("有什么可以帮到您？")
    });

    // 接收来自服务器的响应消息
    socket.on('response', function (responseData) {
      const converter = new md();
      var html = converter.render(responseData);
      that.messages.push({ role: "ai", html: html, msg: responseData, avatar: aiAvatar, time: new Date() })
    });
  },
  methods: {
    sendMessageToAI() {
      this.messages.push({ role: "user", html: this.message, msg: this.message, avatar: userAvatar, time: new Date() });
      const token = localStorage.getItem('token')
      var data = JSON.stringify({ token: token, message: this.message })
      this.socket.emit('message', data);
      this.message = ""
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
          .then(() => {
            this.$message.success('复制成功');
          })
          .catch(() => {
            this.$message.error('无法复制到剪贴板：');
          });
      } else {
        this.$message.error('当前浏览器不支持Clipboard API');
      }
    }
  },
  beforeDestroy() {
    // 确保在组件销毁前关闭WebSocket连接
    if (this.socket) {
      this.socket.close();
    }
  },
};
</script>

<style scoped>
/* 可选：添加样式 */
</style>
import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "自增组件",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "自增组件",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyAutoCart",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: [{}],
        // 组件标签
        label: {
          isShow: false,
          value: "Label:",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "4px",
            left: "0px",
            position: "relative",
            width: "60px",
          },
        },
        // 组件样式
        cStyle: {
          height: "auto",
          width: "100%",
          background: "rgba(255, 255, 255, 0)",
          "font-size": "14px",
          color: "#ffffff",
        }
      },
      // 容器样式
      cStyle: {
        fontSize: "14px",
        height: "auto",
        width: "auto",
        background: "rgba(255, 255, 255, 0)",
        color: "#ffffff",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

<template>
  <div class="base-box margin-box page-right">
    <div class="base-box tag-box-name border-bottom-box" style="width: 100%;">JSON格式化</div>
    <el-input size="mini" type="textarea" :rows="5" :placeholder="jsonStrPlaceholder" v-model="jsonStr"
      @blur="handleInputBlur">
    </el-input>
    <span>格式化结果:</span>
    <vue-json-viewer :value="jsonData" :expand-depth=50 copyable boxed theme="my-awesome-json-theme" sort>
    </vue-json-viewer>
  </div>
</template>

<script>
import VueJsonViewer from 'vue-json-viewer'

export default {
  name: 'jsonPage',
  components: {
    VueJsonViewer
  },
  data() {
    return {
      jsonData: {
        text: "hello word!"
      },
      jsonStr: '',
      jsonStrPlaceholder: '',
    }
  },
  methods: {
    handleInputBlur() {
      this.jsonData = JSON.parse(this.jsonStr)
    }
  },
  mounted() {
    this.jsonStrPlaceholder = JSON.stringify(this.jsonData)
  },
}
</script>

<style></style>
import JsonPage from "@/pages/tool/jsonPage.vue";
import urlPage from "@/pages/tool/urlPage.vue";
import jsPage from "@/pages/tool/jsPage.vue";
import urlTestPage from "@/pages/tool/urlTestPage.vue";
import AIPage from "@/pages/tool/AIPage.vue";

export default [
    {
        path: 'json',
        name: 'json',
        component: JsonPage
    },           
    {
        path: 'url',
        name: 'url',
        component: urlPage
    },
    {
        path: 'js',
        name: 'js',
        component: jsPage
    },
    {
        path: 'urlTest',
        name: 'urlTest',
        component: urlTestPage
    },
    {
        path: 'ai',
        name: 'ai',
        component: AIPage
    },
]
import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "查询页面",
  img: "",
  temp: {
    id: createId(),
    name: "test",
    attribute: {
      component: "EasyPage",
      key: createId(),
      data: [],
      cStyle: {
        color: "#FFFFFF",
      },
      options: {
        api: {
          pageUrl: "",
          addUrl: "",
          deleteUrl: "",
          updateUrl: "",
        },
        model: [
          {
            column: "id",
            isRequired: true,
            isSearch: true,
            label: "ID",
            logical: "AND",
            operator: "=",
            type: "key",
            value: null,
            width: 200,
          },
          {
            column: "name",
            isRequired: false,
            isSearch: true,
            label: "名字",
            logical: "AND",
            operator: "=",
            type: "string",
            value: null,
            width: 230,
          },
          {
            column: "percentage",
            isRequired: false,
            isSearch: true,
            label: "百分比",
            logical: "AND",
            operator: "=",
            type: "percentage",
            value: null,
            width: 120,
          },
          {
            column: "testdate",
            isRequired: false,
            isSearch: true,
            label: "日期时间",
            logical: "AND",
            operator: "=",
            type: "datetime",
            value: null,
            width: 230,
          },
          {
            column: "number",
            isRequired: false,
            isSearch: true,
            label: "数字",
            logical: "AND",
            operator: "=",
            type: "number",
            value: null,
            width: 140,
          },
          {
            column: "text",
            isRequired: false,
            isSearch: true,
            label: "文本",
            logical: "AND",
            operator: "=",
            type: "text",
            value: null,
            width: 0,
          },
          {
            column: "testjson",
            isRequired: false,
            isSearch: false,
            label: "JOSN",
            logical: "AND",
            operator: "=",
            type: "json",
            value: null,
            width: 0,
          },
          {
            column: "isopen",
            isRequired: false,
            isSearch: true,
            label: "开关",
            logical: "AND",
            operator: "=",
            type: "bool",
            value: null,
            width: 80,
          },
          {
            column: "testfloat",
            isRequired: false,
            isSearch: true,
            label: "小数点",
            logical: "AND",
            operator: "=",
            type: "float",
            value: null,
            width: 140,
          },
        ],
        pageParams: {
          page: 1,
          count: 10,
          order_by: [],
          search: [],
        },
        display: {
          readonly: false,
          showSearch: true,
          showOperate: {
            add: true,
            update: true,
            delete: true,
            info: true,
            allDelete: true
          },
        }
      },
    },
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "折线图",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "折线图",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyLineChart",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: {
          data: {
            水果: [23, 45, 21, 56, 78, 12, 54, 21, 52],
            电子: [12, 76, 21, 23, 56, 98, 23, 78, 35],
          },
          type: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
        },
        type: "line",
        // 组件标签
        label: {
          isShow: false,
          value: "标签",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "9px",
            left: "0px",
            position: "relative",
          },
        },
        // 组件样式
        cStyle: {
          width: "100%",
          height: "320px",
          color: "rgba(255, 255, 255, 0)",
          minHeight: "50px",
          fontSize: "14px",
          background: "rgba(255, 255, 255, 0)",
        },
        // Echart样式
        echart: {
          color: [
            "#5470c6",
            "#91cc75",
            "#fac858",
            "#ee6666",
            "#73c0de",
            "#3ba272",
            "#fc8452",
            "#9a60b4",
            "#ea7ccc",
          ],
          title: {
            top: "0px",
            left: "left",
            link: "https://baidu.com",
            show: true,
            text: "拆线图",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 16,
            },
            subtextStyle: {
              fontSize: 20,
            },
          },
          xAxis: {
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            name: "星期",
            show: true,
            type: "category",
            offset: 0,
            position: "bottom",
            nameLocation: "end",
            nameTextStyle: {
              color: "#FFFFFF",
              fontSize: 14,
            },
          },
          yAxis: {
            name: "数量",
            show: true,
            type: "value",
            offset: 0,
            position: "left",
            nameLocation: "end",
            nameTextStyle: {
              color: "#FFFFFF",
              fontSize: 14,
            },
          },
          legend: {
            top: "auto",
            icon: "circle",
            left: "auto",
            show: true,
            type: "plain",
            right: 0,
            bottom: "auto",
            orient: "horizontal",
            padding: 5,
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14,
            },
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              name: "水果",
              step: false,
              type: "line",
              label: {
                show: true,
                color: "auto",
                offset: 0,
                rotate: 0,
                distance: 5,
                fontSize: 12,
                position: "top",
              },
              smooth: true,
            },
            {
              data: [23, 45, 9, 934, 678, 545, 1685],
              name: "电子",
              type: "line",
              smooth: true,
            },
          ],
          toolbox: {
            top: "auto",
            left: "auto",
            show: false,
            right: "auto",
            width: "auto",
            bottom: "auto",
            height: "auto",
            feature: {
              restore: {},
              dataView: {
                readOnly: false,
              },
              dataZoom: {
                yAxisIndex: "none",
              },
              magicType: {
                type: ["line", "bar"],
              },
              saveAsImage: {},
            },
            itemSize: 16,
            iconStyle: {
              color: "#FFFFFFF",
            },
            showTitle: true,
          },
          animation: true,
          backgroundColor: "",
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(255, 255, 255, 0)",
        width: "100%",
        fontSize: "14px",
        height: "auto",
        background: "rgba(255, 255, 255, 0)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

<template>
  <div class="base-box start-box">
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" node-key="id" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
              }}</i>
            <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
              @change="data.isEdit = false; onDirectoryRename()"></el-input>
          </span>
          <span>
            <i class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box ">
      <el-table height="calc(100vh - 130px)" v-show="isShowEdit" size="mini"
        :data="schedulers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        @row-click="onClickObj" style="width: 100%">
        <el-table-column type="index" width="80">
          <template slot="header" slot-scope="scope">
            <el-button type="primary" size="mini" @click="addRow">添加</el-button>
            {{ scope.row }}
          </template>
        </el-table-column>
        <el-table-column label="任务名">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="业务">
          <template slot-scope="scope">
            <el-select size="mini" v-model="scope.row.flowId" placeholder="请选择方法">
              <el-option-group v-for="group in flows" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                  <span style="float: left">{{ item.label }}</span>
                  <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                </el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="下次运行时间" width="140">
          <template slot-scope="scope">
            {{ scope.row.next_run_time }}
          </template>
        </el-table-column>
        <el-table-column label="设置" width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.id">
              <i class="el-icon-setting" style="font-size: 18px; margin-right: 10px;"
                @click="scope.row.isShowSetting = true"></i>
              <i v-show="scope.row.next_run_time" class="el-icon-video-pause" style="font-size: 18px;"
                @click="onStopJob(scope.row)"></i>
              <i v-show="!scope.row.next_run_time" class="el-icon-video-play" style="font-size: 18px;"
                @click="onStartJob(scope.row)"></i>
            </div>
            <div class="job-parameter" tabindex="-1" hidefocus="true" v-show="scope.row.isShowSetting"
              @blur="onSettingBlur(scope.row)">
              <div class="between-box">
                <div style="width: 100px;">
                  任务类型：
                </div>
                <el-select size="mini" v-model="scope.row.jobParameter.trigger" placeholder="请选择方法">
                  <el-option v-for="item in methods" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="between-box job-parameter-row">
                <div style="width: 100px;">
                  运行时间：
                </div>
                <div v-if="scope.row.jobParameter.trigger === 'date'">
                  <el-date-picker size="mini" v-model="scope.row.jobParameter.run_date" type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </div>
                <div v-if="scope.row.jobParameter.trigger === 'interval'">
                  <el-input-number size="mini" v-model="scope.row.jobParameter.seconds" :min="1">
                  </el-input-number>
                </div>

              </div>
              <div class="between-box job-parameter-row">
                <div style="width: 100px;">
                  重启恢复：
                </div>
                <el-switch v-model="scope.row.jobParameter.coalesce" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </div>
              <div class="between-box job-parameter-row">
                <div style="width: 100px;">
                  是否覆盖：
                </div>
                <el-switch v-model="scope.row.jobParameter.replace_existing" active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="right" width="180">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
            {{ scope.row }}
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-check" @click="add">提交</el-button>
            <el-button v-if="scope.row.id" size="mini" type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI, stopJobAPI, startJobAPI } from "@/utils/apis/dev/schedulerApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import * as FlowApi from "@/utils/apis/dev/flowApi"
import { getUrlArgs } from "@/tools/url"

export default {
  name: 'schedulerView',
  components: {
  },
  data() {
    return {
      treedatas: [],
      directory: {},
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      scheduler: {
        id: null,
      },
      schedulers: [],
      search: '',
      projectId: null,
      projectName: null,
      isShowEdit: false,
      flows: [],
      methods: [{
        value: 'interval',
        label: '循环执行'
      }, {
        value: 'date',
        label: '定时执行'
      }],
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
    this.getFlowSelectList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList() {
      getListAPI(this.directory.id).then(res => {
        if (res.data.code === 200) {
          this.schedulers = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'scheduler').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.scheduler).then(res => {
        if (res.data.code === 200) {
          this.scheduler = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data) {
      this.directory = data
      this.getList()
      this.isShowEdit = true
    },
    onClickObj(data) {
      this.scheduler = data
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(index, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addRow() {
      this.schedulers.push(
        {
          pid: this.directory.id,
          projectId: this.projectId,
          jobParameter: { trigger: 'interval', seconds: 1, coalesce: true, replace_existing: true, run_date: null },
          isShowSetting: false,
        })
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'scheduler',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },

    getFlowSelectList() {
      FlowApi.getSelectListAPI(this.projectId).then(res => {
        if (res.data.code === 200) {
          this.flows = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onSettingBlur(data) {
      data.isShowSetting = false
    },
    onStopJob(data) {
      stopJobAPI(data.jobId).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    onStartJob(data) {
      startJobAPI(data.jobId).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>




.job-parameter {
  position: absolute;
  left: -100px;
  border: 1px solid #1a202c;
  border-radius: 5px;
  z-index: 10;
  margin: 10px;
  background: #1a202c;
}

.job-parameter-row {
  margin-top: 10px;
}
</style>
import http from "@/utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let url = "/api/role/user";

// get请求
export function getListAPI(params) {
  return http.get(url, params);
}

// post请求
export function addAPI(params) {
  return http.post(url, params);
}

// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// delete 请求
export function deleteAPI(params) {
  return http.delete(url, params);
}

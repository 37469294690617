import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "滚动表格",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "滚动表格",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyScrollTable",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: [
          { id: "1", name: "小明" },
          { id: "2", name: "小张" },
          { id: "3", name: "小李" },
          { id: "4", name: "小冼" },
          { id: "5", name: "小王" },
          { id: "6", name: "张三" },
          { id: "7", name: "李四" },
          { id: "8", name: "刘痧" },
          { id: "9", name: "地王" },
        ],
        // 列
        columns: [
          { column: "id", label: "ID", width: null, type: "key" },
          { column: "name", label: "名字", width: null, type: "key" },
        ],
        maxHeight: 200,
        // 组件样式
        cStyle: {
          color: "rgba(0, 0, 0, 1)",
          fontSize: "14px",
          overflow: "auto",
        },
        cellStyle: {
          background: "#242b38",
          borderBottom: "1px solid #EBEEF534",
        },
        headerCellStyle: { background: "#242b38" },
      },
      // 容器样式
      cStyle: {
        color: "rgba(0, 0, 0, 1)",
        height: "200px",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

import http from "@/utils/http";

// page请求
export function pageAPI(url, params) {
  return http.get(url, params);
}

// page请求
export function deleteAPI(url, params) {
  return http.delete(url, params);
}

// page请求
export function updateAPI(url, data) {
  return http.put(url, data);
}

// page请求
export function addAPI(url, data) {
  return http.post(url, data);
}


export default {
  mounted(el, binding) {
    let startX = 0;
    let startY = 0;
    // let startWidth = 0;
    // let startHeight = 0;

    function handleMousedown(event) {
      if(event.toElement.id !== 'move') {return}
      startX = event.clientX - el.offsetLeft;
      startY = event.clientY - el.offsetTop;
      // startWidth = el.offsetWidth;
      // startHeight = el.offsetHeight;
      document.addEventListener("mousemove", handleMousemove);
      document.addEventListener("mouseup", handleMouseup);
    }

    function handleMousemove(event) {
      const newX = event.clientX - startX;
      const newY = event.clientY - startY;
      // const newWidth = Math.max(50, startWidth + (event.clientX - startX));
      // const newHeight = Math.max(50, startHeight + (event.clientY - startY));

      el.style.left = `${newX}px`;
      el.style.top = `${newY}px`;
      // el.style.width = `${newWidth}px`;
      // el.style.height = `${newHeight}px`;
    }

    function handleMouseup(event) {
      if(event.toElement.id !== 'move') {return}
      document.removeEventListener("mousemove", handleMousemove);
      document.removeEventListener("mouseup", handleMouseup);
    }

    el.addEventListener("mousedown", handleMousedown);

    if (binding.instance) {
      // 在组件卸载时移除事件监听器
      binding.instance.$once("hook:beforeDestroy", () => {
        el.removeEventListener("mousedown", handleMousedown);
      });
    }
  },
};

import { createEasyPageId as createId } from "../../../ulits";
let designStyle = {
  border: "1px solid rgba(255, 255, 255, 1)",
  padding: "0px",
};

const config = {
  name: "雷达图",
  img: "",
  temp: {
    id: createId(),
    // 组件列表的名称
    name: "雷达图",
    // 组件属性
    attribute: {
      // 组件标签
      component: "EasyRadarChart",
      key: createId(),
      // 组件数据
      data: {
        // 组件值
        value: {
          data: {
            "Allocated Budget": [4200, 3000, 20000, 35000, 50000, 18000],
            "Actual Spending": [5000, 14000, 28000, 26000, 42000, 21000],
          },
          type: [
            "Sales",
            "Administration",
            "Information Technology",
            "Customer Support",
            "Development",
            "Marketing",
          ],
        },
        type: "radar",
        // 组件标签
        label: {
          isShow: false,
          value: "标签",
          cStyle: {
            color: "rgba(0, 0, 0, 1)",
            fontSize: "14px",
            top: "9px",
            left: "0px",
            position: "relative",
          },
        },
        // 组件样式
        cStyle: {
          width: "100%",
          height: "320px",
          color: "rgba(255, 255, 255, 0)",
          minHeight: "50px",
          fontSize: "14px",
          background: "rgba(255, 255, 255, 0)",
        },
        // Echart样式
        echart: {
          title: {
            text: "雷达图",
            subtext: "",
            left: "left",
            top: "0px",
            link: "https://baidu.com",
            show: true,
            textStyle: {
              fontSize: 16,
              color: "#FFFFFF",
            },
            subtextStyle: {
              fontSize: 20,
            },
          },
          backgroundColor: "",
          animation: true,
          toolbox: {
            show: false,
            left: "auto",
            top: "auto",
            right: "auto",
            bottom: "auto",
            width: "auto",
            height: "auto",
            itemSize: 15,
            showTitle: true,
            iconStyle: {
              color: "#FFFFFFF",
            },
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: {
                readOnly: false,
              },
              magicType: {
                type: ["line", "bar"],
              },
              restore: {},
              saveAsImage: {},
            },
          },
          color: [
            "#5470c6",
            "#91cc75",
            "#fac858",
            "#ee6666",
            "#73c0de",
            "#3ba272",
            "#fc8452",
            "#9a60b4",
            "#ea7ccc",
          ],
          legend: {
            type: "plain",
            show: true,
            left: "auto",
            top: "auto",
            right: 0,
            bottom: "auto",
            orient: "vertical",
            padding: 5,
            icon: "circle",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14,
            },
          },
          radar: {
            indicator: [
              {
                name: "Sales",
              },
              {
                name: "Administration",
              },
              {
                name: "Information Technology",
              },
              {
                name: "Customer Support",
              },
              {
                name: "Development",
              },
              {
                name: "Marketing",
              },
            ],
          },
          series: [
            {
              name: "水果",
              type: "radar",
              data: [
                {
                  value: [4200, 3000, 20000, 35000, 50000, 18000],
                  name: "Allocated Budget",
                },
                {
                  value: [5000, 14000, 28000, 26000, 42000, 21000],
                  name: "Actual Spending",
                },
              ],
              step: false,
              label: {
                show: true,
                position: "top",
                distance: 5,
                rotate: 0,
                offset: 0,
                color: "auto",
                fontSize: 12,
              },
            },
          ],
        },
      },
      // 容器样式
      cStyle: {
        color: "rgba(255, 255, 255, 0)",
        width: "100%",
        fontSize: "14px",
        height: "auto",
        background: "rgba(255, 255, 255, 0)",
      },
    },
    // 子组件
    children: [],
    // 设计器中的样式
    designStyle: designStyle,
  },
};

export default config;

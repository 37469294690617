<template>
    <div :style="boxStyle">
        <el-table :data="tableData"  size="mini"
            style="width: 100%" :border="true">
            <el-table-column label="字段">
                <template slot="header" slot-scope="scope">
                    <el-button type="text" size="mini" @click="addRow">添加字段</el-button>
                    {{ scope.row }}
                </template>
                <template slot-scope="scope">
                    <el-input size="mini" placeholder="请输入内容" v-model="scope.row.key" clearable @input="update">
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="值">
                <template slot-scope="scope">
                    <el-select size="mini" style="width: 100%;" v-model="scope.row.value" @change="update" clearable
                        placeholder="请选择请组件" @focus="getSelectList">
                        <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
                            <span style="float: left">{{ item.label }}</span>
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as EasyUiUlits from '../../../ulits'

export default {
    name: 'DictEditer',
    props: { data: Object, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {},
    watch: {
        "data": {
            handler: function (data) {
                this.value = data.value
                this.tableData = []
                for (let index = 0; index < Object.keys(this.value).length; index++) {
                    const key = Object.keys(this.value)[index];
                    this.tableData.push({ key: key, value: this.value[key] })
                }
            },
            deep: true
        }
    },
    data() {
        return {
            value: {},
            tableData: [],
            pageId: '',
            selectList: []
        }
    },
    beforeMount() {
        this.value = this.data.value
        for (let index = 0; index < Object.keys(this.value).length; index++) {
            const key = Object.keys(this.value)[index];
            this.tableData.push({ key: key, value: this.value[key] })
        }
        this.pageId = EasyUiUlits.getEasyPageId()
        this.getSelectList()
    },
    methods: {
        update() {
            this.value = {}
            for (let index = 0; index < this.tableData.length; index++) {
                const element = this.tableData[index];
                if (element) {
                    if (element.value) {
                        this.value[element.key] = element.value
                    } else {
                        this.value[element.key] = null
                    }
                }
            }
            this.updateAttributeData(this.pKey, this.value)
        },
        addRow() {
            this.tableData.push({})
        },
        cellStyle() {
            return { background: '#242b38', borderBottom: '1px solid #EBEEF534' }
        },
        headerCellStyle() {
            return { background: '#242b38' }
        },
        getSelectList() {
            this.selectList = []
            const vars = this.$store.state.easyLoaderData[this.pageId]
            if (vars) {
                var keys = Object.keys(vars)
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    this.selectList.push({label: key, value: key})
                }
            }
        }
    }
}
</script>

<style scoped></style>

export const getUrlArgs = function () {
  let url = window.location.href;
  // 获取参数部分
  let params = url.split("?")[1];
  // 将参数部分转换为对象
  let paramsObj = {};
  if (params) {
    let paramsArr = params.split("&");
    for (let i = 0; i < paramsArr.length; i++) {
      let param = paramsArr[i].split("=");
      paramsObj[param[0]] = param[1];
    }
  }
  return paramsObj;
};

<template>
  <div class="base-box start-box">
    <div class="base-box dev-left-box ">
      <el-row :gutter="14">
        <el-col :span="20">
          <el-input size="mini" style="margin-bottom: 16px;" placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="4">
          <i class="el-icon-folder-add" style="font-size: 24px; color: #a4a5a7;" @click="addDirectory()"></i>
        </el-col>
      </el-row>
      <el-tree size="mini" class="filter-tree" node-key="id" :data="treedatas" :props="defaultProps" default-expand-all
        :filter-node-method="filterNode" @node-click="onTreeNode" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i class="el-icon-folder ellipsis dev-tree-max-width" v-show="!data.isEdit">&nbsp;{{ node.label
              }}</i>
            <el-input v-show="data.isEdit" size="mini" placeholder="请输入名称" v-model="data.name"
              @change="data.isEdit = false; onDirectoryRename()"></el-input>
          </span>
          <span>
            <i class="el-icon-edit-outline" @click.stop="data.isEdit = true">&nbsp;</i>
            <i class="el-icon-delete" @click.stop="removeDirectory(node, data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <div class="base-box margin-box dev-right-box ">
      <el-table v-show="isShowEdit" size="mini"
        :data="flows.filter(data => !search || data.key.toLowerCase().includes(search.toLowerCase()))"
        @row-click="onClickObj" style="width: 100%">
        <el-table-column type="index" width="80">
          <template slot="header" slot-scope="scope">
            <el-button type="primary" size="mini" @click="addRow">添加</el-button>
            {{ scope.row }}
          </template>
        </el-table-column>
        <el-table-column label="业务流程" width="200px">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.name" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="业务说明">
          <template slot-scope="scope">
            <el-input size="mini" placeholder="请输入内容" v-model="scope.row.description" clearable>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column align="right" width="260px">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
            {{ scope.row }}
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-check" @click="add">提交</el-button>
            <el-button v-if="scope.row.id" size="mini" type="primary" icon="el-icon-share"
              @click="dialogVisible = true">设计</el-button>
            <el-button v-if="scope.row.id" size="mini" type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :title="'业务设计：' + flow.name" :visible.sync="dialogVisible" fullscreen>
      <FlowSetting :flow="flow"></FlowSetting>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, addAPI, deleteAPI } from "@/utils/apis/dev/flowApi"
import {
  getDirectoryListAPI,
  // getDirectoryAPI, 
  addDirectoryAPI,
  deleteDirectoryAPI
} from "@/utils/apis/dev/directoryApi"
import { getUrlArgs } from "@/tools/url"

import FlowSetting from "./flowSettingView.vue";

export default {
  name: 'flowView',
  components: {
    FlowSetting
  },
  data() {
    return {
      treedatas: [],
      directory: {},
      flows: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      flow: {
        id: null,
        name: '',
        flow: {},
        description: ''
      },
      search: '',
      projectId: null,
      projectName: null,
      isShowEdit: false,
      dialogVisible: false,
    }
  },
  mounted() {
    let urlParams = getUrlArgs()
    if (urlParams.projectId) {
      this.projectId = urlParams.projectId
      this.projectName = decodeURIComponent(urlParams.projectName)
    }
    this.getDirectoryList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getList() {
      getListAPI(this.directory.id).then(res => {
        if (res.data.code === 200) {
          this.flows = res.data.data.map(function (item) {
            item.status = item.status ? true : false
            return item;
          });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getDirectoryList() {
      getDirectoryListAPI(this.projectId, 'flow').then(res => {
        if (res.data.code === 200) {
          this.treedatas = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    add() {
      addAPI(this.flow).then(res => {
        if (res.data.code === 200) {
          this.flow = res.data.data
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    remove(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.getList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    removeDirectory(node, data) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDirectoryAPI({ 'id': data.id }).then(res => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.getDirectoryList()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    onTreeNode(data) {
      this.directory = data
      this.getList()
      this.isShowEdit = true
    },
    onClickObj(data) {
      this.flow = data
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(index, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addRow() {
      this.flows.push({
        pid: this.directory.id,
        projectId: this.projectId,
        flow: { origin: [0, 0], linkList: [], nodeList: [] }
      })
    },
    addDirectory(directory) {
      if (typeof (directory) == "undefined") {
        directory = {
          name: '未命名目录',
          type: 'flow',
          projectId: this.projectId,
          description: '未命名目录'
        }
      }
      addDirectoryAPI(directory).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDirectoryList()
      }).catch(err => {
        console.log(err)
      })
    },
    onDirectoryRename() {
      this.addDirectory(this.directory)
    },

  },
}
</script>

<style scoped>



</style>
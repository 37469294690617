<template>
    <div :style="boxStyle">
        <div class="start-box"
            v-for="(item, index) in Object.entries(attribute).sort(function (a) { if (typeof a[1] === 'object') { return 1 } else { return -1 } })"
            :key="index" style="margin-top: 8px;width: 100%;">
            <!-- 标签 -->
            <div v-show="!noShowKeys.includes(item[0]) && !item[0].endsWith('Event')"
                style="font-size: 14px; min-width: 80px; position: relative; top: 4px;">
                {{ explian(item[0]) }}
            </div>
            <!-- 内容 -->
            <div style="width: 100%;">
                <!-- JS -->
                <div style=" width: 100%;" v-if="item[0].endsWith('Event')">
                    <div class="c-editer-label-style">{{ explian(item[0]) }}</div>
                    <ObjectEditer :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                        :data="attribute[item[0]]" :noShowKeys="noShowKeys" :boxStyle="{}">
                    </ObjectEditer>
                </div>
                <!-- 其他 -->
                <InputEditer v-else :pKey="pKey + '.' + item[0]" :updateAttributeData="updateAttributeData"
                    v-show="!noShowKeys.includes(item[0])" :data="{ value: attribute[item[0]] }" :boxStyle="{}">
                </InputEditer>
            </div>
        </div>
    </div>
</template>

<script>
import attributeMap from '../attributeMap';
import InputEditer from './InputEditer';
import ObjectEditer from './ObjectEditer';

export default {
    name: 'EventEditer',
    props: { data: Object, noShowKeys: Array, boxStyle: Object, updateAttributeData: Function, pKey: String },
    components: {
        ObjectEditer, InputEditer
    },
    watch: {
        "data": {
            handler: function (val) {
                this.attribute = JSON.parse(JSON.stringify(val))
            },
            deep: true
        }
    },
    data() {
        return {
            attribute: {},
        }
    },
    beforeMount() {
        this.attribute = JSON.parse(JSON.stringify(this.data))
    },
    methods: {
        explian(key) {
            var str = attributeMap[key]
            if(str) {
                return attributeMap[key]
            } else {
                return key
            }
        },
    }
}
</script>

<style scoped>
.c-editer-label-style {
    margin-top: 8px;
    border-bottom: 1px solid black;
    font-weight: bold;
    width: 100%;
}
</style>

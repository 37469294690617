<template>
  <div :style="login_back">
    <el-dialog :title="registerTitle" :visible.sync="infoDialogVisible" width="50%" center>
      <el-input v-isEmpty placeholder="请输入用户名" suffix-icon="el-icon-user-solid" clearable v-model="user.username"
        @blur="checkUsernameExists(user.username)">
      </el-input>
      <el-input v-isEmpty type="password" style="margin: 24px 0px 0px 0px;" placeholder="请输入密码"
        suffix-icon="el-icon-s-cooperation" clearable v-model="user.password">
      </el-input>
      <el-input v-isEmpty type="password" style="margin: 24px 0px 0px 0px;" placeholder="请确认密码"
        suffix-icon="el-icon-s-cooperation" clearable v-model="user.password1">
      </el-input>
      <el-input v-isEmpty style="margin: 24px 0px 0px 0px;" placeholder="请输入电话" suffix-icon="el-icon-mobile-phone"
        clearable v-model="user.phone">
      </el-input>
      <el-input v-isEmpty style="margin: 24px 0px 0px 0px;" placeholder="请输入邮箱" suffix-icon="el-icon-eleme" clearable
        v-model="user.email">
      </el-input>
      <el-button
        style="margin: 24px 00px 0px 00px; width: 100%; background-color: rgb(92, 94, 98); border: none; color: aliceblue;"
        @click="register">注册</el-button>
    </el-dialog>
    <div class="login-box">
      <div class="center-box">
        <div style="font-size: 30px; color: rgb(197, 197, 197); margin-bottom: 18px;">智能低代码能力开发平台</div>
      </div>
      <div class="center-box">
        <div style="font-size: 18px; color: rgb(197, 197, 197); margin-bottom: 24px;">一个能自动生成代码的开发方式，你喜欢吗？</div>
      </div>
      <div class="input-box">
        <div class="center-box">
          <el-input style="margin: 24px 30px 0px 30px;" placeholder="请输入用户名" suffix-icon="el-icon-user-solid" clearable
            v-model="user.username">
          </el-input>
        </div>
        <div class="center-box">
          <el-input type="password" style="margin: 24px 30px 0px 30px;" placeholder="请输入密码" @keyup.native.enter="login"
            suffix-icon="el-icon-s-cooperation" clearable v-model="user.password">
          </el-input>
        </div>
        <div class="center-box">
          <el-button
            style="margin: 24px 30px 0px 30px; width: 100%; background-color: rgb(92, 94, 98); border: none; color: aliceblue; z-index: 99;"
            @click="login">登录</el-button>
        </div>
        <div class="between-box" style="margin: 24px 0px 24px 0px;">
          <div style="margin-left: 30px; color: cornflowerblue;" @click="showRegister('用户注册', 'User')">用户注册</div>
          <div style="margin-right: 30px; color: cornflowerblue;" @click="showRegister('开发者注册', 'Dev')">开发者注册</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginAPI, userInfoAPI, registerAPI, userExistsAPI } from "@/utils/apis/user/loginApi"

export default {
  data() {
    return {
      login_back: {
        backgroundImage: 'url(' + require('@/assets/images/loginPage/login_back.jpg') + ')',
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      user: {
        username: '',
        password: '',
      },
      infoDialogVisible: false,
      registerTitle: ''
    }
  },
  methods: {
    login() {
      loginAPI(this.user).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('token', res.data.token)
          this.getUserInfo()
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUserInfo() {
      userInfoAPI({}).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
          this.$router.push('/dev/project')
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkUsernameExists(username) {
      userExistsAPI(username).then(res => {
        if (res.data.code === 200) {
          if (res.data.data === 1) {
            this.$message.error(res.data.msg)
            this.user.username = ''
          }
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    showRegister(typeText, userType) {
      this.user.user_type = userType
      this.registerTitle = typeText
      this.infoDialogVisible = true
    },
    register() {
      if (!this.user.username) {
        this.$message.error("用户名不能为空")
      }
      else if (!this.user.password) {
        this.$message.error("密码不能为空")
      }
      else if (!this.user.phone) {
        this.$message.error("电话不能为空")
      }
      else if (!this.user.email) {
        this.$message.error("邮箱不能为空")
      }
      if (!this.user.password === this.user.password1) {
        this.$message.error("两次密码不一致")
      }
      registerAPI(this.user).then(res => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg)
          this.infoDialogVisible = false
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$router.push('/dev/project')
    }
  }
}
</script>

<style scoped>
.login-box {
  position: relative;
  width: 400px;
  height: 320px;
  top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-box {
  position: relative;
  border-radius: 5px;
  width: 400px;
  height: 350px;
  background-color: #242b38B0;
}
</style>
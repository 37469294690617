import Image from "./Image/config";
import Info from "./Info/config";
import Makedown from "./Makedown/config";
import Label from "./Label/config";
import Link from "./Link/config";
import ScrollTable from "./ScrollTable/config";
import Video from "./Video/config";
import Audio from "./Audio/config";
import Tree from "./Tree/config";

const components = [Image, Info, Label, Link, Tree, ScrollTable, Video, Audio, Makedown];

export default components;
